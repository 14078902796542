import React, { useEffect, useState } from "react";
import "./storeDetails.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import "react-alice-carousel/lib/alice-carousel.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Image } from "primereact/image";
import storeimg from "../../../assets/images/img2.png";
import { GetStorebyID } from "../../../api/pendingTable.api";
import StoreImages from "../../../components/StoreImages/storeImages.component";
import ServiceOptions from "../../../components/StoreServiceoptions/serviceOptions.component";
import StoreTimings from "../../../components/StoreTimings/storeTimings.component";
import { sas } from "../../../config/constants"
import Loader from "../../../components/ui/loader/loader.components";
import { Dialog } from "primereact/dialog";
import EditStore from "../EditStores/EditStore";
import StoreInfo from "../../../components/StoreInfo/storeInfo.component";

const StoreDetails = () => {
  const [visible, setVisible] = useState(false);
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [lvisible, setLvisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false)

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const loadLazyData = () => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      setIsadding(true);
      const storeData = await GetStorebyID(id as string);

      console.log("storeData", storeData.data.data);

      setStoretObj(storeData.data.data);

      setIsadding(false);
    }, 4000);
  };
  useEffect(() => {
    setIsadding(true);
    loadLazyData();
  }, []);

  const [storeObj, setStoretObj] = useState({
    _id: "",
    address: "",
    storename: "",
    phoneno: "",
    email: "",
    agentname: "",
    approvalstatus: "",
    city: "",
    country: "",
    comment: "",
    createdAt: "",
    dateadded: "",
    locality: "",
    district: "",
    location: { coordinates: [0.0, 0.0] },
    pincode: "",
    serviceoptions: "",
    servicetype: [],
    state: "",
    storeimages: [],
    storesize: [],
    storetype: [],
    thumbnails: [],
    timings: [],
    updatedAt: "",
    website: "",
    __v: "",
    facebookurl: "",
    instaurl: "",
    youtubeurl: "",
    twitterurl: "",
  });

  const isRejectedStore = location.pathname.includes("/rejected");
  const isApprovedStore = location.pathname.includes("/approved");



  return (
    <main>
      {!isAdding ? "" : <Loader />}
      <div className="">
        <Navbar />
        <section className="page-content">
          <div className="d-flex  justify-content-between ">
            <div className="p-2 d-flex">
              &nbsp;
              <span
                className="back_rest_btn d-flex gap-2 align-items-center"
                onClick={() => {
                  const currentPath = window.location.pathname;
                  if (currentPath.includes("/pendingstore")) {
                    navigate(`/organicstores/pendingstore`);
                  } else if (currentPath.includes("/approvedstore")) {
                    navigate(`/organicstores/approvedstore`);
                  } else if (currentPath.includes("/rejectedstore")) {
                    navigate(`/organicstores/rejectedstore`);
                  }
                }}
              >
                <i className="pi pi-arrow-left"></i>
                Organic Store Details{" "}
              </span>
            </div>
            <div className="">
              {!isApprovedStore && (
                <Button
                  label="Edit Organic Store"
                  icon="pi pi-pencil"
                  className="p-button-rounded p-button-edit "
                  onClick={() => {
                    const currentPath = window.location.pathname;
                    let storeType = 'pendingstore';
                    if (currentPath.includes("/rejected")) {
                      storeType = 'rejectedstore';
                    }

                    navigate(`/organicstores/${storeType}/editorganicstore/${id}`);
                  }}
                  autoFocus
                />
              )}
            </div>
          </div>
          <hr className="" />

          <div className="ym-content-table pl-3  mb-0 d-flex">
            <div className="col-6 p-0">
              <div className=" gap-3 d-flex align-items-center">
                <div className="row mb-1 mr-3 view-gallery-cols" style={{ height: "160px", width: "160px" }}>
                  <div className="col pt-0 pl-0">
                    {/* @ts-ignore       */}
                    <Image src={storeObj.thumbnails[0] + "?" + sas} alt="Image" preview className="details-img" />
                  </div>
                </div>

                <div className="col d-flex align-items-left mb-4 justify-content-center flex-column ml-1 gap-3 rest-details">
                  <div className="page-title">
                    {!lvisible ? (
                      <h1 className="ym-portal-heading">
                        {storeObj.storename}
                      </h1>
                    ) : (
                      <Skeleton width="10rem" className="mb-2"></Skeleton>
                    )}
                  </div>
                  <div className="address-latlong gap-3 d-flex">
                    <div className="address-div col-lg-6">
                      {!lvisible ? (
                        <div>

                          <p className="area-data mb-0">
                            {storeObj.address +
                              "," +
                              storeObj.locality +
                              "," +
                              storeObj.city +
                              "," +
                              " " +
                              storeObj.state +
                              "-" +
                              storeObj.pincode +
                              "."}
                          </p>
                        </div>
                      ) : (
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                      )}
                    </div>

                  </div>

                </div>
              </div>
            </div>
            {isRejectedStore && (
              <div className='col-6 p-0'>
                <div className="">
                  <div className="ambience">
                    <h6 className="mb-2 rr-head">Rejection Reason</h6>
                    <div className='reason-disc-box'>
                      <span className="span-data">
                        {storeObj.comment ? storeObj.comment : "--"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <TabView>
              <TabPanel header="Organic Store Info">
                <StoreInfo
                  phone={storeObj.phoneno}
                  email={storeObj.email}
                  website={storeObj.website}
                  facebookurl={storeObj.facebookurl}
                  instaurl={storeObj.instaurl}
                  youtubeurl={storeObj.youtubeurl}
                  twitterurl={storeObj.twitterurl}
                  latitude={storeObj.location.coordinates[1]}
                  longitude={storeObj.location.coordinates[0]}
                />
              </TabPanel>
              <TabPanel header="Service Options">
                <ServiceOptions
                  storetype={storeObj.storetype}
                  servicetype={storeObj.servicetype}
                />
              </TabPanel>
              <TabPanel header="Timings">
                <StoreTimings timings={storeObj.timings} />
              </TabPanel>
              <TabPanel header={`Photos (${(storeObj.storeimages?.length || 0) + (storeObj.thumbnails?.length || 0)})`}>
                <StoreImages
                  storeimages={storeObj.storeimages}
                  thumbnail={storeObj.thumbnails}
                />
              </TabPanel>
            </TabView>
            <Dialog
              header="Edit OrganicStore Details"
              visible={editVisible}
              style={{ width: "45vw", height: "100vh" }}
              onHide={() => {
                setEditVisible(false);
              }}

            >
              <EditStore
                storedataobj={storeObj}
                setEditVisible={setEditVisible}
              />
            </Dialog>
          </div>
        </section>
      </div>
    </main>
  );
};

export default StoreDetails;
