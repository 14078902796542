import React from 'react';
import EditsRestaurant from '../../../components/EditRestaurantCustomStepper/editRestaurantCustomStepper';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import restDetails from "../../../assets/icons/restDetails.svg";
import foodpref from "../../../assets/icons/foodpref.svg";
import timings from "../../../assets/icons/timings.svg";
import uploadimages from "../../../assets/icons/uploadimages.svg";
import additional from "../../../assets/icons/additional.svg";
const EditingRestaurants = () => {
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];
  const stepdesc = [
    "Restaurant Details",
    "Food Preference & Dining Details",
    "Timings",
    "Upload Images",
    "Additional Information",
  ];

  const images = [restDetails, foodpref, timings, uploadimages, additional];
  return (
    <>
      <main>
        <div>
          <Navbar />
          <section
            className="page-content"
            style={{ height: "100vh", background: "#F5F5F5" }}
          >
            <div className="d-flex flex-column">
              <div className='d-flex justify-content-center'>
                <EditsRestaurant
                  steps={steps}
                  images={images}
                  stepdesc={stepdesc}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default EditingRestaurants;
