import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DropFileInput from '../../pages/restaurants/drop-file-input/drop-file-input';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import Loader from '../ui/loader/loader.components';
import { GetDietitiandetailsbyID } from '../../api/dietitians.api';
import { EditDietitianSubmit } from '../../api/dietitians.api';
import { useNavigate, useParams } from 'react-router-dom';
import { sas } from '../../config/constants';

interface dietitianStepper {
  steps: string[];
  images: string[];
  stepdesc: string[];
}

type resObj = {
  _id: string;
  dietitiantitle: string;
  dietitianname: string;
  gender: string;
  speciality: string[];
  profession: string[];
  degrees: { degree: string; college: string; yearOfCompletion: string }[];
  certification: string;
  experience: string;
  languages: string[];
  about: string[];
  services: string[];
  awards: string[];
  membership: string[];
  clinicname: string;
  latitude: string;
  longitude: string;
  clinicaddress: string;
  locality: string;
  city: string;
  district: string;
  state: string;
  country: string;
  location: string;
  pincode: string;
  phoneno: string;
  mode: string[];
  registereddietitian: string;
  idamembershipnumber: string;
  email: string;
  fees: string;
  timings: any[];
  dietitianwebsite: string;
  dietitianfacebookurl: string;
  dietitianinstaurl: string;
  dietitianyoutubeurl: string;
  dietitiantwitterurl: string;
  clinicemail: string;
  clinicphoneno: string;
  clinicwebsite: string;
  clinicfacebookurl: string;
  clinicinstaurl: string;
  clinicyoutubeurl: string;
  clinictwitterurl: string;
  socailmedia: string;
  dietitianimages: any[];
  clinicimages: any[];
  certificationimages: any[];

}

const initialState = {
  _id: "",
  dietitiantitle: "",
  dietitianname: "",
  gender: "",
  speciality: [],
  profession: [],
  degrees: [{ degree: "", college: "", yearOfCompletion: "" }],
  certification: "",
  experience: "",
  languages: [],
  about: [],
  services: [],
  awards: [],
  membership: [],
  clinicname: "",
  clinicaddress: "",
  latitude: "",
  longitude: "",
  locality: "",
  city: "",
  district: "",
  state: "",
  country: "",
  location: "",
  pincode: "",
  phoneno: "",
  mode: [],
  registereddietitian: "",
  idamembershipnumber: "",
  email: "",
  fees: "",
  timings: [],
  dietitianwebsite: "",
  dietitianfacebookurl: "",
  dietitianinstaurl: "",
  dietitianyoutubeurl: "",
  dietitiantwitterurl: "",
  clinicemail: "",
  clinicphoneno: "",
  clinicwebsite: "",
  clinicfacebookurl: "",
  clinicinstaurl: "",
  clinicyoutubeurl: "",
  clinictwitterurl: "",
  socailmedia: "",
  dietitianimages: [],
  clinicimages: [],
  certificationimages: [],

}

const EditDietitianCustomStepper: React.FC<dietitianStepper> = ({
  steps, images, stepdesc
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(Array(steps.length).fill(false));
  const [stepColors, setStepColors] = useState(Array(steps.length).fill(""));
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();
  const [resObj, setResObj] = useState<resObj>(initialState);
  const [isAdding, setIsadding] = useState(false);
  const toast = useRef<Toast>(null);
  const { id } = useParams();
  const [medicalregistrationproof, setMedicalregistrationproof] = useState<any[]>([]);
  const [dietitianimage, setDietitianimage] = useState<any[]>([]);
  const [clinicimages, setClinicimages] = useState<any[]>([]);
  const [title, setTitle] = useState('');
  const [Gender, setGender] = useState('');
  const [specialities, setSpecialities] = useState<string[]>([]);
  const [profession, setProfession] = useState<any[]>([]);
  const [moc, setMoc] = useState<any[]>([]);
  const [aboutdietitian, setaboutdietitian] = useState<any>(null);
  const [localLanguages, setLocalLanguages] = useState<string>("");
  const [localServices, setLocalServices] = useState<string>("");
  const [memberships, setMemberships] = useState<string>("");
  const [awardsandrec, setAwardsandrec] = useState<string>("");
  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<Record<string, string[]>>(initialSelectedTimings);
  const navigate = useNavigate();


  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });


  const defaultValues = {
    dietitianname: "",
    experience: "",
    fees: "",
    clinicname: "",
    latitude: "",
    longitude: "",
    address: "",

  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    // getValues,
    reset,
  } = useForm({ defaultValues });
  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const fetchAddress = async (lat: any, long: any) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
      );
      const data = await response.json();
      console.log(data);

      let address = '';
      let district = '';
      let city = '';
      let state = '';
      let pincode = '';
      let countryName = '';
      let locality = '';

      data.results.forEach((result: any) => {
        result.address_components.forEach((component: any) => {
          if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
            address += component.long_name + ', ';
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (component.types.includes('administrative_area_level_3')) {
            district = component.long_name;
          } else if (component.types.includes('postal_code')) {
            pincode = component.long_name;
          } else if (component.types.includes('country')) {
            countryName = component.long_name;
          }

          else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('neighborhood')) {
            locality = component.long_name;
          }
        });
      });


      locality = locality || address.slice(0, -2);

      setResObj({
        ...resObj,
        locality: locality,
        district: district,
        city: city,
        state: state,
        pincode: pincode,
        country: countryName,
      });

    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };

  const [stepData, setStepData] = useState([
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false }
  ]);

  const validateFields = (): boolean => {
    const show = (message: any, severity: any, summary: any) => {
      if (toast.current) {
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      }
    };

    let updatedStepData = [...stepData];

    if (activeStep === 0) {
      if (
        resObj.dietitianname.length === 0 ||
        resObj.dietitiantitle.length === 0 ||
        resObj.gender.length === 0 ||
        resObj.experience.length === 0 ||
        resObj.speciality.length === 0 ||
        resObj.profession.length === 0 ||
        resObj.languages.length === 0 ||
        resObj.services.length === 0 ||
        resObj.fees.length === 0 ||
        resObj.mode.length === 0 ||
        (!resObj.about || resObj.about.length === 0 || resObj.about[0].trim() === '') ||
        resObj.phoneno.length === 0

      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    } else if (activeStep === 1) {
      if (resObj.degrees.length === 0) {
        show("Please add at least one degree", "warn", "Warn");
        return false;
      }

      for (let i = 0; i < resObj.degrees.length; i++) {
        const degree = resObj.degrees[i];
        if (
          degree.degree.length === 0

        ) {
          show("Please add at least one degree", "warn", "Warn");
          return false;
        }
      }
      updatedStepData[activeStep].completed = true;
    } else if (activeStep === 2) {
      if (
        resObj.clinicname.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.clinicaddress.length === 0 ||
        resObj.locality.length === 0 ||
        resObj.city.length === 0 ||
        resObj.district.length === 0 ||
        resObj.state.length === 0 ||
        resObj.country.length === 0 ||
        resObj.pincode.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    } else if (activeStep === 4) {
      if (
        resObj.dietitianimages.length === 0 ||
        resObj.clinicimages.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    }
    setStepData(updatedStepData);
    return true;
  };


  const handleImageClick = (index: any) => {
    setActiveStep(index);
    setCompletedSteps(prevCompletedSteps => {
      const updatedSteps = [...prevCompletedSteps];
      updatedSteps[index] = true;
      return updatedSteps;
    });
  };
  const handleNext = () => {

    if (!validateFields()) {
      return;
    }
    const updatedCompletedSteps = [...completedSteps];
    updatedCompletedSteps[activeStep] = true;
    setCompletedSteps(updatedCompletedSteps);
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    updateStepColors();
  };
  const updateStepColors = () => {
    const newStepColors = [...stepColors];
    newStepColors[activeStep] = "lightblue"; // Change this to the desired color
    setStepColors(newStepColors);
  };

  const titles = [
    { label: 'Dr.', value: 'Dr.' },
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Dt.', value: 'Dt.' }
  ];
  const gender = ["Male", "Female"];
  const registercheck = ["Yes", "No"];

  const onTitleChange = (e: any) => {
    setTitle(e.value);
    setResObj({ ...resObj, dietitiantitle: e.value });
  };
  const genderselection = (e: any) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
    setResObj({ ...resObj, gender: selectedGender });
  }

  const redistereddoctor = (e: any) => {
    const selectedregister = e.target.value;
    setResObj({
      ...resObj,
      registereddietitian: selectedregister,
      idamembershipnumber: selectedregister === "No" ? "" : resObj.idamembershipnumber,
    });
  }

  const handleProfession = (e: any) => {
    let _prof = [...profession];

    if (e.checked) _prof.push(e.value);
    else _prof.splice(_prof.indexOf(e.value), 1);

    setProfession(_prof);
    setResObj({ ...resObj, profession: _prof });
  };


  const modofconsult = (e: any) => {
    let _modeof = [...moc];
    if (e.checked) _modeof.push(e.value);
    else _modeof.splice(_modeof.indexOf(e.value), 1);
    setMoc(_modeof);
    setResObj({ ...resObj, mode: _modeof });
  };

  const About = (e: any) => {
    const dietitianabout = e.target.value;
    const about = dietitianabout.split(".");
    setaboutdietitian(about);
    setResObj({ ...resObj, about: about });
  };

  const splitaray = (val: string): string[] => {
    return val.split(',').map(item => item.trim()).filter(item => item.length > 0);
  };


  const handleservicechange = (e: any) => {
    const value = e.target.value.trimStart();
    setLocalServices(value);
    setResObj({
      ...resObj,
      services: splitaray(value)
    })
  }

  const handleLanguageChange = (e: any) => {
    const value = e.target.value.trimStart();
    setLocalLanguages(value);
    setResObj({
      ...resObj,
      languages: splitaray(value)
    });
  };
  const handlemembership = (e: any) => {
    const value = e.target.value.trimStart();
    setMemberships(value);
    setResObj({
      ...resObj,
      membership: splitaray(value)
    });
  }

  const awardsandrecog = (e: any) => {
    const value = e.target.value.trimStart();
    setAwardsandrec(value);
    setResObj({
      ...resObj,
      awards: splitaray(value)
    });
  }


  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };
  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };

  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter((day) => checkedDays[day]);

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);

        // Directly set the updatedTimings without wrapping it in an array
        //setResObj({ ...resObj, timings: updatedTimings });

        return updatedTimings;
      });
    }
  };

  const [Specialties, setIsSpecialties] = useState<any[]>([]);
  const specialtieslist = [
    { name: "Clinical Dietitian" },
    { name: "Sports Dietitian" },
    { name: "Pediatric Dietitian" },
    { name: "Gerontological Dietitian" },
    { name: "General Dietitian" }
  ];
  const handleSpecialities = (e: any) => {
    let _specialties = [...Specialties];

    if (e.checked) _specialties.push(e.value);
    else _specialties.splice(_specialties.indexOf(e.value), 1);
    setIsSpecialties(_specialties);
    setResObj({ ...resObj, speciality: _specialties });
  };




  const handleInputChange = (index: number, field: string, value: string) => {
    const updatedDegrees = [...resObj.degrees];
    updatedDegrees[index] = { ...updatedDegrees[index], [field]: value };
    setResObj({ ...resObj, degrees: updatedDegrees });
  };

  const addDegree = () => {
    setResObj((prevResObj) => ({
      ...prevResObj,
      degrees: [...prevResObj.degrees, { degree: '', college: '', yearOfCompletion: '' }],
    }));
  };

  const deleteDegree = (index: number) => {
    if (resObj.degrees.length > 1) {
      const updatedDegrees = resObj.degrees.filter((_, i) => i !== index);
      setResObj({ ...resObj, degrees: updatedDegrees });
    }
  };

  const handleDeleteClick = (index: any, purpose: any) => {
    const updatedRestObj = { ...resObj };
    console.log("updatedRestObj", updatedRestObj);
    if (purpose === "dietitianimages") {
      console.log("diet img remove click");
      const updatedThumbnails = [...resObj.dietitianimages];
      updatedThumbnails.splice(index, 1);
      updatedRestObj.dietitianimages = updatedThumbnails;
    } else if (purpose === "clinicimages") {
      console.log("clinic img remove click");
      const updatedclinicImages = [...resObj.clinicimages];
      updatedclinicImages.splice(index, 1);
      updatedRestObj.clinicimages = updatedclinicImages;
    }

    setResObj(updatedRestObj);
  };

  const loadLazyData = async () => {
    try {
      setIsadding(true);

      const resData = await GetDietitiandetailsbyID(id as string);

      console.log("DietitianData for Edit", resData.data.data);

      if (resData.data.data) {
        const dietitianData = resData.data.data;

        setLocalLanguages(dietitianData.languages.join(","));
        setLocalServices(dietitianData.services.join(","));
        setMemberships(dietitianData.membership.join(","));
        setAwardsandrec(dietitianData.awards.join(","));

        setResObj({
          ...dietitianData,
          latitude: dietitianData.location?.coordinates[1]?.toString() || "",
          longitude: dietitianData.location?.coordinates[0]?.toString() || ""
        });
      }

      setIsadding(false);
    } catch (error) {
      console.error("Error loading dietitian data:", error);
      setIsadding(false);
    }
  };


  useEffect(() => {
    setIsadding(true);
    loadLazyData();
  }, [id]);

  const handleEditDietitianObj = async () => {

    if (dietitianimage.length > 5) {
      show(
        "You can upload up to 5 dietitian images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (clinicimages.length > 25) {
      show(
        "You can upload up to 25 clinic images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (medicalregistrationproof.length > 5) {
      show(
        "You can upload up to 5 certificate images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }

    setIsadding(true);

    if (
      !resObj.dietitianname ||
      !resObj.dietitiantitle ||
      !resObj.gender ||
      !resObj.experience ||
      !resObj.profession ||
      !resObj.languages ||
      !resObj.mode ||
      !resObj.speciality ||
      !resObj.services ||
      !resObj.fees ||
      (!resObj.about || resObj.about.length === 0 || resObj.about[0].trim() === '') ||
      !resObj.phoneno ||
      resObj.degrees.length === 0 ||
      !resObj.clinicname ||
      !resObj.latitude ||
      !resObj.longitude ||
      !resObj.clinicaddress ||
      !resObj.locality ||
      !resObj.city ||
      !resObj.state ||
      !resObj.district ||
      !resObj.pincode ||
      !resObj.country ||
      resObj.dietitianimages.length === 0 ||
      resObj.clinicimages.length === 0
    ) {
      show("Please check all Mandatory fields", "warn", "Warn");
      setIsadding(false);
      return;
    }
    if (resObj) {
      const updatedResObj = {
        ...resObj,
        location: {
          coordinates: [
            parseFloat(resObj.longitude) || 0,  // Updated longitude
            parseFloat(resObj.latitude) || 0,   // Updated latitude
          ],
        },
      };
      const formData = new FormData();

      // Append basic details
      formData.append("dietitianname", updatedResObj.dietitianname);
      formData.append("dietitiantitle", updatedResObj.dietitiantitle);
      formData.append("gender", updatedResObj.gender);
      formData.append("certification", updatedResObj.certification);
      formData.append("experience", updatedResObj.experience);
      formData.append("clinicname", updatedResObj.clinicname);
      formData.append("latitude", updatedResObj.latitude);
      formData.append("longitude", updatedResObj.longitude);
      formData.append("clinicaddress", updatedResObj.clinicaddress);
      formData.append("locality", updatedResObj.locality);
      formData.append("city", updatedResObj.city);
      formData.append("district", updatedResObj.district);
      formData.append("state", updatedResObj.state);
      formData.append("country", updatedResObj.country);
      formData.append("pincode", updatedResObj.pincode);
      formData.append("phoneno", updatedResObj.phoneno);
      formData.append("registereddietitian", updatedResObj.registereddietitian);
      formData.append("idamembershipnumber", updatedResObj.idamembershipnumber);
      formData.append("email", updatedResObj.email);
      formData.append("fees", updatedResObj.fees);
      formData.append("clinicphoneno", updatedResObj.clinicphoneno);
      formData.append("clinicemail", updatedResObj.clinicemail)

      // Append arrays: Speciality, Profession, Languages, Services, Awards, Memberships, etc.
      updatedResObj.speciality.forEach((spec: any, index: number) => {
        formData.append(`speciality[${index}]`, spec);
      });

      updatedResObj.profession.forEach((prof: any, index: number) => {
        formData.append(`profession[${index}]`, prof);
      });

      updatedResObj.languages.forEach((lang: any, index: number) => {
        formData.append(`languages[${index}]`, lang);
      });
      updatedResObj.mode.forEach((mode: any, index: number) => {
        formData.append(`mode[${index}]`, mode)
      });

      updatedResObj.services.forEach((service: any, index: number) => {
        formData.append(`services[${index}]`, service);
      });

      updatedResObj.awards.forEach((award: any, index: number) => {
        formData.append(`awards[${index}]`, award);
      });

      updatedResObj.membership.forEach((membership: any, index: number) => {
        formData.append(`membership[${index}]`, membership);
      });
      updatedResObj.about.forEach((about: any, index: number) => {
        formData.append(`about[${index}]`, about)
      })

      // Append degrees
      updatedResObj.degrees.forEach((degree, index) => {
        formData.append(`degrees[${index}][degree]`, degree.degree);
        formData.append(`degrees[${index}][college]`, degree.college);
        formData.append(`degrees[${index}][yearOfCompletion]`, degree.yearOfCompletion);
      });

      // Timings - Only append if they've changed
      if (JSON.stringify(selectedTimings) !== JSON.stringify(initialSelectedTimings)) {
        formData.append("timings", JSON.stringify(selectedTimings));
      }

      // Append social media URLs
      formData.append("dietitianwebsite", updatedResObj.dietitianwebsite);
      formData.append("dietitianfacebookurl", updatedResObj.dietitianfacebookurl);
      formData.append("dietitianinstaurl", updatedResObj.dietitianinstaurl);
      formData.append("dietitianyoutubeurl", updatedResObj.dietitianyoutubeurl);
      formData.append("dietitiantwitterurl", updatedResObj.dietitiantwitterurl);

      // Append clinic social media URLs
      formData.append("clinicwebsite", updatedResObj.clinicwebsite);
      formData.append("clinicfacebookurl", updatedResObj.clinicfacebookurl);
      formData.append("clinicinstaurl", updatedResObj.clinicinstaurl);
      formData.append("clinicyoutubeurl", updatedResObj.clinicyoutubeurl);
      formData.append("clinictwitterurl", updatedResObj.clinictwitterurl);

      updatedResObj.dietitianimages.forEach((dietimage: File) => {
        if (typeof dietimage === 'string') {
          formData.append(`existingDietitianImageURLs`, dietimage);
        } else {
          formData.append(`dietitianimages`, dietimage);
        }
      });
      updatedResObj.clinicimages.forEach((clinicimage: File) => {
        if (typeof clinicimage === 'string') {
          formData.append(`existingClinicImageURLs`, clinicimage);
        } else {
          formData.append(`clinicimages`, clinicimage);
        }
      });
      updatedResObj.certificationimages.forEach((certificateimage: File) => {
        if (typeof certificateimage === 'string') {
          formData.append(`existingCertificationImagesURLs`, certificateimage);

        } else {
          formData.append(`certificationimages`, certificateimage);
        }
      });

      try {
        const response = await EditDietitianSubmit(updatedResObj._id, formData);

        const currentPath = window.location.pathname;
        const productId = updatedResObj._id;
        if (response.status === 200) {
          show("Dietitian Updated Successfully", "success", "Success");
          let targetUrl = '';
          setTimeout(async () => {
            if (currentPath.includes("/pending")) {
              targetUrl = `/dietitian/pendingdietitian/details/${productId}`;
            } else if (currentPath.includes("/approved")) {
              targetUrl = `/dietitian/approveddietitian/details/${productId}`;
            } else if (currentPath.includes("/rejected")) {
              targetUrl = `/dietitian/rejecteddietitian/details/${productId}`;
            }

            if (targetUrl) {
              navigate(targetUrl);
              setIsadding(false);

            }
          }, 2000);
        } else {
          setIsadding(false);
          show("Invalid Access", "error", "Error");
        }
      } catch (error) {
        console.error("Error updating dietitian:", error);
      }
    }
  };


  console.log("updating the Dietitianresobj", resObj);
  return (
    <>
      {!isAdding ? "" : <Loader />}
      <div className="d-flex justify-content-center align-items-center">

        <div className="col d-flex justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "85vh" }}
          >
            <ol className="c-stepper">
              {steps.map((label, index) => (
                <li key={label} className={`c-stepper__item`}>
                  <img
                    className="c-stepper__item__img"
                    src={images[index]}
                    style={{ fill: "#00ff00" }}
                    onClick={() => handleImageClick(index)}
                  />
                  <div className="c-stepper__content">
                    <h3 className="c-stepper__title">Step {index + 1}</h3>
                    <p className="c-stepper__disc">{stepdesc[index]}</p>
                    <p
                      className="fs-12"
                      style={{
                        color: index === activeStep ? "#8856BB" : "green",
                      }}
                    >
                      {index === activeStep ? "In progress" : "Completed"}
                    </p>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>

        <div className="col">
          <div className="card details-card" style={{ width: "calc(100vw - 60vw)", height: "92vh", borderRadius: "12px", }} >
            <div className="next-card card-body">
              <Toast ref={toast}></Toast>

              {activeStep === 0 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Dietitian Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className="row">
                              <div className="flex flex-column">

                                <Controller
                                  name="dietitianname"
                                  control={control}
                                  rules={{
                                    required: "Dietitian name  is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Dietitian Name <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container col-12 p-0">
                                        <InputText
                                          placeholder="Enter Dietitian Name"
                                          value={resObj.dietitianname}
                                          className={`col-10 name-area ${fieldState.error ? "p-invalid" : ""}`}
                                          style={{ borderRight: "none" }}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              dietitianname: e.target.value,
                                            });
                                          }}
                                        />
                                        <Dropdown
                                          optionLabel="label"
                                          optionValue="value"
                                          value={resObj.dietitiantitle}
                                          options={titles}
                                          onChange={onTitleChange}
                                          placeholder="Title"
                                          className="tilte-area col-2 p-0"


                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Gender <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className='d-flex gap-3'>
                                  {gender.map((e: any, index: any) => (
                                    <div key={index} className="submenu-item">
                                      <label className="d-flex gap-2">
                                        <input
                                          className="check-wrapper ml-1"
                                          type="radio"
                                          id={`custom-checkbox-${index}`}
                                          name="gender"
                                          value={e}
                                          checked={resObj.gender === e}
                                          onChange={genderselection}
                                        />
                                        <span className="gender-text-style">{e}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>



                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="experience"
                                  control={control}
                                  rules={{
                                    required: "Experience is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Experience <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Years of Experience (between 0 to 70 Years)"
                                          className={`col-12 ${fieldState.error ? "p-invalid" : ""}`}
                                          keyfilter={/^[0-9+\-\s]*$/}
                                          maxLength={2}
                                          value={resObj.experience}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              experience: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <label className="ym-input-label mt-2">
                                Profession <b style={{ color: 'red' }}>*</b>
                              </label>
                              <div className="row">
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="profession1"
                                      name="professions"
                                      value="Dietitian"
                                      onChange={handleProfession}
                                      checked={(resObj.profession as string[]).includes("Dietitian")}
                                    />
                                    <label htmlFor="preference1" className="pref-label">
                                      Dietitian
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="profession2"
                                      name="professions"
                                      value="Nutritionist"
                                      onChange={handleProfession}
                                      checked={(resObj.profession as string[]).includes("Nutritionist")}
                                    />
                                    <label htmlFor="preference2" className="pref-label">
                                      Nutritionist
                                    </label>
                                  </div>
                                </div>

                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Languages <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Add Languages (comma separated)"
                                    className="col-12"
                                    value={localLanguages}
                                    onChange={handleLanguageChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <label className="ym-input-label mt-2">
                                Mode of Consultation <b style={{ color: 'red' }}>*</b>
                              </label>
                              <div className="row">
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="consult1"
                                      name="consultation"
                                      value="Online"
                                      onChange={modofconsult}
                                      checked={resObj.mode.includes("Online")}
                                    />
                                    <label htmlFor="consult1" className="pref-label">
                                      Online
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="consult2"
                                      name="consultation"
                                      value="Offline"
                                      onChange={modofconsult}
                                      checked={resObj.mode.includes("Offline")}
                                    />
                                    <label htmlFor="consult2" className="pref-label">
                                      Offline
                                    </label>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className='row mt-4'>
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Specialities <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="row">
                                  {specialtieslist.map((specialty, index) => (
                                    <div className="col-4" key={index}>
                                      <div className="flex align-items-center gap-2">
                                        <Checkbox
                                          inputId={`specialty${index}`}
                                          name="specialties"
                                          value={specialty.name}
                                          onChange={handleSpecialities}
                                          checked={resObj.speciality?.includes(specialty.name)}
                                        />
                                        <label htmlFor={`specialty${index}`} className="pref-label">
                                          {specialty.name}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Services <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter any 5 Services (comma separated)"
                                    className="col-12"
                                    value={localServices}
                                    onChange={handleservicechange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Are you a Registered Dietitian (RD)?
                                </label>
                                <div className='d-flex gap-3'>
                                  {registercheck.map((e: any, index: any) => (
                                    <div key={index} className="filter-item">
                                      <label className="d-flex gap-2">
                                        <input
                                          className="check-wrapper ml-1"
                                          type="radio"
                                          id={`custom-checkbox-${index}`}
                                          name="registeredcheck"
                                          value={e}
                                          checked={resObj.registereddietitian === e}
                                          onChange={redistereddoctor}
                                        />
                                        <span className="gender-text-style">{e}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {resObj.registereddietitian === "Yes" && (
                              <div className="row mt-4">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">IDA Membership Number</label>
                                  <InputText
                                    className="col-12"
                                    placeholder="Add Membership Id"
                                    aria-describedby="membership-help"
                                    value={resObj.idamembershipnumber}
                                    onChange={(e) => {
                                      let value = e.target.value.trimStart();
                                      setResObj({
                                        ...resObj,
                                        idamembershipnumber: value.length > 0 ? value : '',
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            )}


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="fees"
                                  control={control}
                                  rules={{
                                    required: "Fees amount is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Fees <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Fees"
                                          className="col-12 "
                                          value={resObj.fees}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              fees: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  About Dietitian <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputTextarea
                                    placeholder="Enter Dietitian Description"
                                    rows={3}
                                    value={resObj.about.join('')}
                                    className='w-full'
                                    onChange={(e) => { About(e); }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Phone Number <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Phone Number"
                                      keyfilter={/^[0-9+\-\s]*$/}
                                      maxLength={14}
                                      value={resObj.phoneno}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            phoneno: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            phoneno: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Email
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Email"
                                      className="col-12"
                                      value={resObj.email}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            email: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            email: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Website
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Website URL"
                                    className="col-12 "
                                    value={resObj.dietitianwebsite}
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        dietitianwebsite: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Facebook
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Facebook URL"
                                      value={resObj.dietitianfacebookurl}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitianfacebookurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Instagram
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Instagram URL"
                                      className="col-12"
                                      value={resObj.dietitianinstaurl}
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitianinstaurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Youtube
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Youtube URL"
                                      className="col-12"
                                      value={resObj.dietitianyoutubeurl}
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitianyoutubeurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Twitter
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Twitter URL"
                                      className="col-12"
                                      value={resObj.dietitiantwitterurl}
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitiantwitterurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Education Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className='d-flex flex-column gap-3'>
                              {resObj.degrees.map((degree, index) => (
                                <div key={index} className="degree-entry">
                                  <div className="row">
                                    <div className="flex flex-column">
                                      <label className="ym-input-label">
                                        Degree <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Degree"
                                          className="col-12"
                                          value={degree.degree}
                                          onChange={(e) => handleInputChange(index, 'degree', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="flex flex-column">
                                      <label className="ym-input-label">
                                        College/Institute
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter College/Institute"
                                          className="col-12"
                                          value={degree.college}
                                          onChange={(e) => handleInputChange(index, 'college', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="flex flex-column">
                                      <label className="ym-input-label">
                                        Year of Completion
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Year of Completion"
                                          className="col-12"
                                          value={degree.yearOfCompletion}
                                          onChange={(e) => handleInputChange(index, 'yearOfCompletion', e.target.value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    index == 0 && <div className="p-0 d-flex justify-content-start align-items-center mt-3">
                                      <Button className="Adddegree_btn" onClick={addDegree}>
                                        <i className="pi pi-plus-circle mr-1" style={{ fontSize: '12px' }}></i> Add Degree
                                      </Button>
                                    </div>
                                  }

                                  {index > 0 && resObj.degrees.length > 1 && (
                                    <Button className="DeleteDegree_btn mt-3" onClick={() => deleteDegree(index)}>
                                      <i className="pi pi-trash mr-1" style={{ fontSize: '12px' }}></i> Delete
                                    </Button>
                                  )}
                                </div>
                              ))}


                            </div>


                            <div className="row mt-3">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Certification
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Certification"
                                    className="col-12 "
                                    value={resObj.certification}
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        certification: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Memberships
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Memberships"
                                    value={memberships}
                                    className="col-12 "
                                    onChange={(e) => { handlemembership(e); }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Awards & Recognitions
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Awards & Recognitions"
                                    value={awardsandrec}
                                    className="col-12 "
                                    onChange={(e) => { awardsandrecog(e); }}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="row mt-3">
                              <div className="">
                                <div className="child-left" id="uploadimages">
                                  <label className="ym-input-label">
                                    Medical Registration Proof
                                  </label>
                                  <DropFileInput
                                    uploadedFiles={medicalregistrationproof}
                                    setUploadedFiles={setMedicalregistrationproof}
                                    resObj={resObj}
                                    setResObj={setResObj}

                                    purpose="medicalregistrationprooffile"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="grid mt-2 ml-1 gap-3">
                              {Array.isArray(resObj.certificationimages) &&
                                resObj.certificationimages.every(
                                  (item: any) => typeof item === "string"
                                ) &&
                                resObj.certificationimages.map((item: any, index: any) => {
                                  return (
                                    <div
                                      id="Imagecrd"
                                      style={{ position: "relative" }}
                                      key={index}
                                    >
                                      <img
                                        className="uploadimage fefe"
                                        key={item}
                                        src={item + "?" + sas}
                                        alt=""
                                      />

                                      <i
                                        className="pi pi-trash icontrash"
                                        onClick={() =>
                                          handleDeleteClick(index, "medicalregistrationprooffile")
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          margin: "8px",
                                        }}
                                      ></i>
                                    </div>
                                  );
                                })}
                            </div>


                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Clinic Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className="row">
                              <div className="flex flex-column">
                                <Controller
                                  name="clinicname"
                                  control={control}
                                  rules={{
                                    required: "Clinic name  is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Clinic Name <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Clinic Name"
                                          className="col-12 "
                                          value={resObj.clinicname}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              clinicname: e.target.value,
                                            });
                                          }}

                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col ">
                                <div className="">
                                  <Controller
                                    name="latitude"
                                    control={control}
                                    rules={{
                                      required: "latitude is required.",
                                      pattern: {
                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                        message: "Enter a valid latitude with only numbers and dots.",
                                      }
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label ">
                                            Latitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter the vaild latitube"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a vaild latitude"
                                            id={field.name}
                                            value={resObj.latitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                latitude: e.target.value,
                                              });

                                            }}

                                          />
                                        </div>

                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="longitude"
                                    control={control}
                                    rules={{
                                      required: "logitube is required.",

                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label">
                                            Longitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter the vaild logitude"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a vaild logitude"
                                            id={field.name}
                                            value={resObj.longitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                longitude: e.target.value,
                                              });
                                              // fetchAddress(resObj.latitude,e.target.value)
                                            }}
                                          // keyfilter={/^[d]*\.?\d*$/}
                                          />
                                        </div>

                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center mt-4">
                              <Button className="get-address" label="Get Address" severity="success" outlined aria-label="Search"
                                onClick={() => fetchAddress(resObj.latitude, resObj.longitude)}
                              />
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="address"
                                  control={control}
                                  rules={{ required: "Address  is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Address <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputTextarea
                                          id={field.name}
                                          {...field}
                                          placeholder="Enter Address Line"
                                          value={resObj.clinicaddress}
                                          rows={3}
                                          style={{ width: "100%" }}
                                          // cols={82}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              clinicaddress: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                      {/* {getFormErrorMessage(field.name)} */}
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Locality <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Locality"
                                      value={resObj.locality}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          locality: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    City <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter City"
                                      value={resObj.city}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          city: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    District <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter District"
                                      value={resObj.district}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          district: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    State <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter State"
                                      value={resObj.state}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          state: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Country <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Country"
                                      value={resObj.country}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          country: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Pincode <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Pincode"
                                      value={resObj.pincode}
                                      keyfilter={/^[0-9+\-\s]*$/}
                                      maxLength={6}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          pincode: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Clinic Phone Number
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Clinic Phone Number"
                                      keyfilter={/^[0-9+\-\s]*$/}
                                      maxLength={14}
                                      value={resObj.clinicphoneno}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinicphoneno: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinicphoneno: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Clinic Email
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Clinic Email"
                                      className="col-12"
                                      value={resObj.clinicemail}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinicemail: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinicemail: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Website
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Website URL"
                                    className="col-12 "
                                    value={resObj.clinicwebsite}
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        clinicwebsite: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Facebook
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Facebook URL"
                                      className="col-12"
                                      value={resObj.clinicfacebookurl}
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinicfacebookurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Instagram
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Instagram URL"
                                      className="col-12"
                                      value={resObj.clinicinstaurl}
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinicinstaurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Youtube
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Youtube URL"
                                      className="col-12"
                                      value={resObj.clinicyoutubeurl}
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinicyoutubeurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Twitter
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Twitter URL"
                                      className="col-12"
                                      value={resObj.clinictwitterurl}
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinictwitterurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <div className="row ">
                    <div className="col-12">
                      <div className="child-lef t" id="Timings">
                        <h4 className="rest-detail-head">Timings</h4>
                        <hr></hr>
                        <label className="ym-input-label mb-2">
                          Timings
                        </label>

                        <div className="">
                          <div className="row">
                            <div className="row">
                              {daysOfWeek.map((day: string) => (
                                <div className="col-lg-3" key={day}>
                                  <div className="d-flex align-items-center">
                                    <Checkbox
                                      inputId={`day${day}`}
                                      name="day"
                                      value={day}
                                      checked={checkedDays[day]}
                                      onChange={() => handleCheckboxChange(day)}
                                    />
                                    <label htmlFor={`day${day}`} className="ml-1">
                                      {day}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className=" d-flex from-to gap-2 align-items-center mb-3">
                              <div className="from d-flex flex-column col-lg-3">
                                <span className="restaurant-name">From</span>
                                <Dropdown
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                  }}
                                  id="eater"
                                  placeholder="Select"
                                  value={fromtimes}
                                  options={Timings}
                                  onChange={onFromTimeChange}
                                />
                              </div>
                              <div className="from d-flex flex-column col-3">
                                <span className="restaurant-name">To</span>
                                <Dropdown
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                  }}
                                  id="eater"
                                  placeholder="Select"
                                  value={totimes}
                                  options={Timings}
                                  onChange={onToTimeChange}
                                />
                              </div>
                              <div className="mt-3">
                                <button
                                  className="add-button"
                                  onClick={handleAddTiming}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                            <div className="row d-flex justify-content-start align-items-center mt-3">
                              <div className="col-8">
                                {Object.entries(selectedTimings).map(
                                  ([day, timing]) => (
                                    <div
                                      className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                      key={day}
                                    >
                                      <span className="day">{day}</span>
                                      <div className="row">
                                        <div className="col gap-1">
                                          {timing.map((time, index) => (
                                            <div className="col p-0 mt-1 gap-1">
                                              <span className="time" key={index}>
                                                {time}
                                              </span>

                                              <i
                                                className="bi bi-trash-fill"
                                                style={{
                                                  color: "#818181",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  const updatedTimings = {
                                                    ...selectedTimings,
                                                  };
                                                  updatedTimings[day] =
                                                    updatedTimings[day].filter(
                                                      (t) => t !== time
                                                    );

                                                  // Check if there are no times left, delete the entire day
                                                  if (
                                                    updatedTimings[day].length === 0
                                                  ) {
                                                    delete updatedTimings[day];
                                                  }

                                                  setSelectedTimings(updatedTimings);
                                                }}
                                              ></i>
                                              <br />
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            {/* <div className="row d-flex justify-content-start align-items-center mt-3">
                                                        <RestaurantTimings
                                                            timing={resObj.timings}
                                                            editable={true}
                                                        />
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 4 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="uploadimages">
                        <h4>Upload Images</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                            <div className="">
                              <label className="ym-input-label">
                                Dietitian Images <b style={{ color: "red" }}>*</b> <span style={{ fontSize: "12px" }}>(Add a min 1 or a max 5 Dietitian Images)</span>
                              </label>
                              <DropFileInput
                                uploadedFiles={dietitianimage}
                                setUploadedFiles={setDietitianimage}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="DietitianImage"
                              />
                            </div>

                            <div className="grid mt-2 ml-1 gap-3">
                              {Array.isArray(resObj.dietitianimages) &&
                                resObj.dietitianimages.every(
                                  (item: any) => typeof item === "string"
                                ) &&
                                resObj.dietitianimages.map((item: any, index: any) => {
                                  return (
                                    <div
                                      id="Imagecrd"
                                      style={{ position: "relative" }}
                                      key={index}
                                    >
                                      <img
                                        className="uploadimage fefe"
                                        key={item}
                                        src={item + "?" + sas}
                                        alt=""
                                      />

                                      <i
                                        className="pi pi-trash icontrash"
                                        onClick={() =>
                                          handleDeleteClick(index, "dietitianimages")
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          margin: "8px",
                                        }}
                                      ></i>
                                    </div>
                                  );
                                })}
                            </div>

                            <div className="">
                              <label className="ym-input-label">
                                Clinic Images
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <DropFileInput
                                uploadedFiles={clinicimages}
                                setUploadedFiles={setClinicimages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="clinicimages"
                              />
                            </div>

                            <div className="grid mt-2 ml-1 gap-3">
                              {Array.isArray(resObj.clinicimages) &&
                                resObj.clinicimages.every(
                                  (item: any) => typeof item === "string"
                                ) &&
                                resObj.clinicimages.map((item: any, index: any) => {
                                  // const imageUrl = URL.createObjectURL(item);

                                  return (
                                    <div
                                      id="Imagecrd"
                                      style={{ position: "relative" }}
                                      key={index}
                                    >
                                      <img
                                        className="uploadimage"
                                        key={item.id}
                                        src={item + "?" + sas}
                                        alt=""
                                      />

                                      <i
                                        className="pi pi-trash icontrash"
                                        onClick={() =>
                                          handleDeleteClick(index, "clinicimages")
                                        }
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          margin: "8px",
                                        }}
                                      ></i>
                                    </div>
                                  );
                                })}
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            </div>
            <div className="card-footer button-container">
              {activeStep === 0 ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="row">
                      <Button
                        className="next"
                        label="Next"
                        type="submit"
                        // icon="pi pi-check"
                        onClick={
                          handleNext
                        }

                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <Button
                    className="ym-add-button-previous"
                    label="Back"
                    onClick={handleBack}
                  ></Button>

                  {activeStep === 4 ? (
                    <Button
                      className="p-button-success ym-add-button"
                      label="Save Changes"
                      onClick={handleEditDietitianObj}
                    ></Button>
                  ) : (

                    <Button
                      className="next"
                      label="Next"
                      type="submit"
                      // icon="pi pi-check"
                      onClick={
                        handleNext
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default EditDietitianCustomStepper;
