import React, { useRef, useState } from 'react';
import { Galleria } from 'primereact/galleria';
import { sas } from "../../config/constants";

interface Props {
  clinicimages: string[];
  dietitianimages: string[];
}

const ClinicAndDietitianImages: React.FC<Props> = ({ dietitianimages, clinicimages }) => {
  const galleriaDietitian = useRef<Galleria>(null);
  const galleriaClinic = useRef<Galleria>(null);

  // Separate active indices for both galleries
  const [activeDietitianIndex, setActiveDietitianIndex] = useState<number>(0);
  const [activeClinicIndex, setActiveClinicIndex] = useState<number>(0);

  // Template for gallery items
  const itemTemplate = (item: any) => (
    <img
      src={`${item}?${sas}`}
      alt={item.alt}
      style={{ width: '100%', display: 'block' }}
    />
  );

  // Template for thumbnails
  const thumbnailTemplate = (item: any) => (
    <img
      src={`${item}?${sas}`}
      alt={item.alt}
      style={{ display: 'block' }}
    />
  );

  // Handle keyboard navigation for dietitian images
  const handleDietitianKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      setActiveDietitianIndex((prevIndex) => (prevIndex + 1) % dietitianimages.length);
    } else if (e.key === 'ArrowLeft') {
      setActiveDietitianIndex((prevIndex) =>
        (prevIndex - 1 + dietitianimages.length) % dietitianimages.length
      );
    }
  };

  // Handle keyboard navigation for clinic images
  const handleClinicKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowRight') {
      setActiveClinicIndex((prevIndex) => (prevIndex + 1) % clinicimages.length);
    } else if (e.key === 'ArrowLeft') {
      setActiveClinicIndex((prevIndex) =>
        (prevIndex - 1 + clinicimages.length) % clinicimages.length
      );
    }
  };

  return (
    <div className="d-flex flex-column gap-4 p-3">
      {/* Dietitian Photos Section */}
      <div
        className="d-flex flex-column gap-2"
        onKeyDown={handleDietitianKeyDown}
        tabIndex={0}
        style={{ outline: 'none' }} // Ensure it can capture keyboard events
      >
        <div className="picheads-store">Dietitian Images ({dietitianimages.length})</div>
        <div className="d-flex flex-wrap pt-2 rest-img row">
          <Galleria
            ref={galleriaDietitian}
            value={dietitianimages}
            numVisible={7}
            style={{ maxWidth: '850px' }}
            activeIndex={activeDietitianIndex}
            onItemChange={(e) => setActiveDietitianIndex(e.index)}
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          />
          <div className="d-flex">
            {dietitianimages.map((image, index) => (
              <div key={index}>
                <img
                  src={`${image}?${sas}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setActiveDietitianIndex(index);
                    galleriaDietitian.current?.show();
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Clinic Photos Section */}
      <div
        className="d-flex flex-column gap-2"
        onKeyDown={handleClinicKeyDown}
        tabIndex={0}
        style={{ outline: 'none' }} // Ensure it can capture keyboard events
      >
        <div className="picheads-store">Clinic Photos ({clinicimages.length})</div>
        <div className="pt-2">
          <Galleria
            ref={galleriaClinic}
            value={clinicimages}
            numVisible={7}
            style={{ maxWidth: '850px' }}
            activeIndex={activeClinicIndex}
            onItemChange={(e) => setActiveClinicIndex(e.index)}
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          />
          <div className="image_gallery">
            {clinicimages.map((image, index) => (
              <div className='rest-img' key={index}>
                <img
                  src={`${image}?${sas}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setActiveClinicIndex(index);
                    galleriaClinic.current?.show();
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicAndDietitianImages;
