import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import "./addOrganicStore.component.css";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { AutoComplete } from "primereact/autocomplete";
import DropFileInput from "../../pages/restaurants/drop-file-input/drop-file-input";
import { Toast } from "primereact/toast";
import { getRefreshedToken, isTokenExpired } from "../../api/auth.api";
import { SubmitOrganicStore } from "../../api/addRestarant.api";
import Loader from "../ui/loader/loader.components";
interface orgaicStepper {
  steps: string[];
  images: string[];
  stepdesc: string[];
}

type resObj = {
  // lat: any;
  storename: string;
  address: string;
  // address2: string;
  city: string;
  state: string;
  latitude: string;
  longitude: string;
  locality: string;
  country: string;
  pincode: string;
  website: string;
  email: string;
  phoneno: string;
  servicetype: string[];
  serviceoptions: string;
  timings: any[];
  district: string;
  thumbnails: any[];
  storeimages: any[];
  storesize: any[];
  storetype: string[];
  facebookurl: string;
  instaurl: string;
  youtubeurl: string;
  twitterurl: string;
};
const initialState = {
  storename: "",
  address: "",
  city: "",
  state: "",
  latitude: "",
  longitude: "",
  locality: "",
  country: "",
  pincode: "",
  website: "",
  email: "",
  phoneno: "",
  servicetype: [],
  serviceoptions: "",
  timings: [],
  district: "",
  storeimages: [],
  thumbnails: [],
  storesize: [],
  storetype: [],
  facebookurl: "",
  instaurl: "",
  youtubeurl: "",
  twitterurl: "",
};

const AddOrganicStore: React.FC<orgaicStepper> = ({
  steps,
  images,
  stepdesc,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(Array(steps.length).fill(false));
  //   const [servicesOptions, setServicesOptions] = useState<string>("");
  const [servicestype, setServicestype] = useState<string[]>([]);
  const [resObj, setResObj] = useState<resObj>(initialState);
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();
  const [storeSize, setStoreSize] = useState(null);
  const [storeThumbnailImages, setStoreThumbnailImages] = useState<any[]>([]);
  const [storeImages, setStoreImages] = useState<any[]>([]);
  const [stepColors, setStepColors] = useState(Array(steps.length).fill(""));
  const [isAdding, setIsadding] = useState(false);
  const toast = useRef<Toast>(null);

  const show = (message: any, severity: any, summary: any) => {
    console.log("ouside of toast");
    if (toast.current) {
      console.log("going in toast");
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];
  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const defaultValues = {
    restaurantname: "",
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
    specialitem: "",
    average: "",
    eaterytype: "",
    foodtype: "",
    ambience: "",
    aboutrestaurant: "",
    whyyeldam: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    // getValues,
    reset,
  } = useForm({ defaultValues });

  const storesizes = [
    { name: "Small", code: "SM" },
    { name: "Medium", code: "MD" },
    { name: "Large", code: "LG" },
  ];

  //for timing
  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);
  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter(
        (day) => checkedDays[day]
      );

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);
        // setResObj({ ...resObj, timings: updatedTimings });
        return updatedTimings;
      });
    }
  };

  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });

  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };

  const Servicestyps = (e: any) => {
    let _servicestypes = [...servicestype];

    if (e.checked) _servicestypes.push(e.value);
    else _servicestypes.splice(_servicestypes.indexOf(e.value), 1);

    setServicestype(_servicestypes);
    setResObj({ ...resObj, servicetype: _servicestypes });
  };

  const onStoreSizeChange = (e: any) => {
    setStoreSize(e.value);
    setResObj({ ...resObj, storesize: e.value.name });
  };

  const [storetype, setStoretype] = useState<string[]>([]);
  const Storetypes = (e: any) => {
    let _Storetypes = [...storetype];

    if (e.checked) _Storetypes.push(e.value);
    else _Storetypes.splice(_Storetypes.indexOf(e.value), 1);

    setStoretype(_Storetypes);
    setResObj({ ...resObj, storetype: _Storetypes });
  };

  const getFormErrorMessage = (name: string) => {
    const errorObject = errors[name as keyof typeof defaultValues];

    return errorObject ? (
      <small className="p-error">{errorObject.message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const handleNext = () => {
    console.log("clicked next");

    if (!validateFields()) {
      return;
    }

    const updatedStepData = [...stepData];

    updatedStepData[activeStep] = {
      ...updatedStepData[activeStep],
      completed: true,
    };

    const updatedCompletedSteps = [...completedSteps];
    updatedCompletedSteps[activeStep] = true;

    setStepData(updatedStepData);
    setCompletedSteps(updatedCompletedSteps);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    updateStepColors();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    updateStepColors();
  };
  const updateStepColors = () => {
    const newStepColors = [...stepColors];
    newStepColors[activeStep] = "lightblue"; 
    setStepColors(newStepColors);
  };

  const ResetForm = () => {
    console.log("resetting the form");
    setIsadding(true);
    window.location.reload();
    setResObj(initialState);
  };
  const SubmitResObj = async () => {
    setIsadding(true);
    if (storeThumbnailImages.length > 5) {
      show(
        "You can upload up to 5 thumbnail images only.",
        "error",
        "Limit Exceeded"
      );
      setIsadding(false)

      return; 

    }

    if (storeImages.length > 25) {
      show("You can upload up to 25 organic store images only.",
        "error",
        "Limit Exceeded"
      );
      setIsadding(false)

      return;
    }
    console.log("ResObj ", resObj);
    setTimeout(async () => {
      if (
        resObj.storename.length === 0 ||
        resObj.address.length === 0 ||
        resObj.city.length === 0 ||
        resObj.district.length === 0 ||
        resObj.state.length === 0 ||
        resObj.pincode.length === 0 ||
        resObj.country.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.locality.length === 0 ||

        resObj.storetype.length === 0 ||
        resObj.servicetype.length === 0 ||

        resObj.thumbnails.length === 0 ||
        resObj.storeimages.length === 0
      ) {
        show("Please check all Mandatory filed", "warn", "Warn");
        setIsadding(false);
      } else {
        const formData = new FormData();

        Object.entries(resObj).forEach(([key, value]) => {
          // if (
          //   key === "foodtype" ||
          //   key === "diettype" ||
          //   key === "cuisine" ||
          //   key === "values" ||
          //   key === "eaterytype"
          // ) {
          //   if (Array.isArray(value)) {
          //     value.forEach((val, index) => {
          //       const newVal = `${key}[${index}]`;
          //       formData.append(`${newVal}[name]`, val.name);
          //       formData.append(`${newVal}[code]`, val.code);
          //     });
          //   }
          // } else {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              formData.append(key, item);
            });
          } else {
            formData.append(key, value);
          }
          // }
        });
        const timingsJSON = JSON.stringify(selectedTimings);
        formData.append("timings", timingsJSON);
        if (isTokenExpired()) {
          await getRefreshedToken();
        } else {
          await SubmitOrganicStore(formData)
            .then((response: any) => {
              if (response.status === 201) {
                setIsadding(false);
                console.log("in-to the success");
                show("Organic Store Added Successfully", "info", "Info");

                setTimeout(() => {
                  ResetForm();
                }, 3000);
              } else {
                setIsadding(false);
                console.log("in-to the invail");
                show("Invalid Access", "error", "Error");
              }
            })
            .catch((error: any) => {
              console.log(error.response.status);
              setIsadding(false);
              if (error.response.status === 403) {
                console.log("in-to the expired");
                show("Token Expired", "warn", "Warn");
              }
            });
        }
      }
    }, 1000);
  };

  const [stepData, setStepData] = useState([
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false }
  ]);

  const validateFields = (): boolean => {
    const show = (message: any, severity: any, summary: any) => {
      if (toast.current) {
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      }
    };

    let updatedStepData = [...stepData];
    if (activeStep === 0) {
      if (
        resObj.storename.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.address.length === 0 ||
        resObj.locality.length === 0 ||
        resObj.city.length === 0 ||
        resObj.district.length === 0 ||
        resObj.state.length === 0 ||
        resObj.country.length === 0 ||
        resObj.pincode.length === 0

      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    }
    else if (activeStep === 1) {
      if (
        resObj.storetype.length === 0 ||
        resObj.servicetype.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;

    }
    else if (activeStep === 3) {
      if (
        resObj.thumbnails.length === 0 ||
        resObj.storeimages.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    }

    setStepData(updatedStepData);
    return true;
  };

  const handleImageClick = (index: any) => {
    setActiveStep(index);
    validateFields(); // Trigger validation when a step is clicked
  };
  console.log("restObj", resObj);




  const fetchAddress = async (lat: any, long: any) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
      );
      const data = await response.json();
      console.log(data);
  
      let address = '';
      let district = '';
      let city = '';
      let state = '';
      let pincode = '';
      let countryName = '';
      let locality = '';  
  
      data.results.forEach((result: any) => {
        result.address_components.forEach((component: any) => {
          if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
            address += component.long_name + ', ';
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (component.types.includes('administrative_area_level_3')) {
            district = component.long_name;
          } else if (component.types.includes('postal_code')) {
            pincode = component.long_name;
          } else if (component.types.includes('country')) {
            countryName = component.long_name;
          } 

          else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('neighborhood')) {
            locality = component.long_name;
          }
        });
      });
  

      locality = locality || address.slice(0, -2); 
  
      setResObj({
        ...resObj,
        locality: locality,  
        district: district,
        city: city,
        state: state,
        pincode: pincode,
        country: countryName,
      });
  
    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };
  

  return (
    <>
      {!isAdding ? "" : <Loader />}
      <div className="d-flex justify-content-center align-items-center">



        <div className="col d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center" style={{ height: "85vh" }}>
            <ol className="c-stepper">
              {steps.map((label, index) => (
                <li key={label} className={`c-stepper__item`}>
                  <img
                    className="c-stepper__item__img"
                    src={images[index]}
                    style={{ fill: "#00ff00" }}
                    // onClick={() => handleImageClick(index)}
                  />
                  <div className="c-stepper__content">
                    <h3 className="c-stepper__title">Step {index + 1}</h3>
                    <p className="c-stepper__disc">{stepdesc[index]}</p>
                    <p className={`fs-12 ${index === activeStep ? "in-progress" : stepData[index]?.completed ? "completed" : ""}`}>
                      {index === activeStep ? "In progress" :
                        stepData[index]?.completed ? "Completed" : " "}
                    </p>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>



        <div className="col">
          <div
            className="card details-card"
            style={{
              width: "calc(100vw - 60vw)",
              height: "92vh",
              borderRadius: "12px",
            }}
          >
            <div className="next-card card-body">
              <Toast ref={toast}></Toast>
              {activeStep === 0 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4>Organic Store Details</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>
                            {/* <div className="row">
                              <label className="ym-input-label">
                                Search Existing Organic Store
                              </label>

                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="two-s">
                                  <div className="flex gap-3">
                                    <div className="search p-inputgroup flex-1">
                                      <AutoComplete
                                        id="search"
                                        className="Organicstore"
                                        placeholder="Search Organic Store Name..."
                                        style={{ fontFamily: "roboto" }}
                                        value={search}
                                        suggestions={suggestions}
                                        onSelect={(e: any) => {
                                          setSearch(e.value.name);
                                        }}
                                        completeMethod={searchs}
                                        onChange={(
                                          e: AutoCompleteChangeEvent
                                        ) => {
                                          SearchResBar(e);
                                        }}
                                        itemTemplate={itemTemplate}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            {/* <br></br> */}

                            <div className="row">
                              <Controller
                                name="restaurantname"
                                control={control}
                                rules={{
                                  required: "Restaurant name  is required.",
                                }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="ym-input-label">
                                      Organic Store Name{" "}
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                    <div className="flex flex-column">
                                      <InputText
                                        placeholder="Enter Organic Store Name"
                                        maxLength={50}
                                        id={field.name}
                                        value={resObj.storename}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        onChange={(e) => {
                                          let value = e.target.value;
                                          value = value.trimStart();
                                          if (value.length > 0) {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              storename: value,
                                            });
                                          } else {
                                            setResObj({
                                              ...resObj,
                                              storename: '',
                                            });
                                          }
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>

                            <div className="row">
                              <label className="ym-input-label">
                                Phone Number
                              </label>
                              <div className="flex flex-column">
                                <InputText
                                  id="phoneno"
                                  className="no-spinner"
                                  maxLength={14}
                                  value={resObj.phoneno}
                                  onChange={(e) => {
                                    const rawValue = e.target.value;
                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                    if (trimmedValue) { // Check if the trimmed value is not just spaces
                                      setResObj({
                                        ...resObj,
                                        phoneno: trimmedValue,
                                      });
                                    } else {
                                      setResObj({
                                        ...resObj,
                                        phoneno: '', // Set to empty if invalid
                                      });
                                    }
                                  }}
                                  placeholder="Enter Phone Number"
                                  aria-describedby="username-help"

                                  keyfilter={/^[0-9+\-\s]*$/}
                                />
                              </div>
                            </div>
                            <br></br>

                            <div className="row">
                              <div className="d-flex align-items-baseline gap-1">
                                <label className="ym-input-label">
                                  Email Address
                                </label>
                                <i
                                  className="bi bi-info-circle"
                                  title="Enter the valid email"
                                  id="icon"
                                ></i>
                              </div>

                              <div className="flex flex-column gap-2">
                                <InputText
                                  id="email"
                                  maxLength={50}
                                  value={resObj.email}
                                  placeholder="Enter Email Address"
                                  aria-describedby="username-help"
                                  onChange={(e) => {
                                    const rawValue = e.target.value;
                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                    if (trimmedValue) { // Check if the trimmed value is not just spaces
                                      setResObj({
                                        ...resObj,
                                        email: trimmedValue,
                                      });
                                    } else {
                                      setResObj({
                                        ...resObj,
                                        email: '', // Set to empty if invalid
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              <div className="d-flex align-items-baseline gap-1">
                                <label className="ym-input-label">
                                  Website URL{" "}
                                </label>
                                <i
                                  className="bi bi-info-circle"
                                  title="Enter the valid Website URL"
                                  id="icon"
                                ></i>
                              </div>
                              <div className="flex flex-column gap-2">
                                <InputText
                                  id="website"
                                  maxLength={50}
                                  value={resObj.website}
                                  placeholder="Enter Website URL"
                                  aria-describedby="username-help"
                                  onChange={(e) => {
                                    const rawValue = e.target.value;
                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                    if (trimmedValue) { // Check if the trimmed value is not just spaces
                                      setResObj({
                                        ...resObj,
                                        website: trimmedValue,
                                      });
                                    } else {
                                      setResObj({
                                        ...resObj,
                                        website: '', // Set to empty if invalid
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Facebook
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Facebook URL"
                                      value={resObj.facebookurl}
                                      className="col-12"
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          facebookurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Instagram
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Instagram URL"
                                      value={resObj.instaurl}
                                      className="col-12"
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          instaurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Youtube
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Youtube URL"
                                      className="col-12"
                                      value={resObj.youtubeurl}
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          youtubeurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    X
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter X URL"
                                      className="col-12"
                                      value={resObj.twitterurl}
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          twitterurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>


                            <div className="row mt-3">
                              <div className="col ">
                                <div className="">
                                  <Controller
                                    name="latitude"
                                    control={control}
                                    rules={{
                                      required: "latitude is required.",
                                      pattern: {
                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                        message: "Enter a valid latitude with only numbers and dots.",
                                      }
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label ">
                                            Latitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter the valid latitude"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a valid latitude"
                                            id={field.name}
                                            value={resObj.latitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart();

                                              if (trimmedValue === "" || /^[+-]?([0-9]*[.]?[0-9]*)$/.test(trimmedValue)) {
                                                field.onChange(e);
                                                setResObj({
                                                  ...resObj,
                                                  latitude: trimmedValue,
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="longitude"
                                    control={control}
                                    rules={{
                                      required: "logitube is required.",

                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label">
                                            Longitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter the valid longitude"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a valid longitude"
                                            id={field.name}
                                            value={resObj.longitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart();

                                              // Allow the user to type a dot, even if the full input isn't yet valid
                                              if (trimmedValue === "" || /^[+-]?([0-9]*[.]?[0-9]*)$/.test(trimmedValue)) {
                                                field.onChange(e);
                                                setResObj({
                                                  ...resObj,
                                                  longitude: trimmedValue,
                                                });
                                              }
                                            }}

                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center mb-1">
                              <Button className="get-address" label="Get Address" severity="success" outlined aria-label="Search"
                                onClick={() => fetchAddress(resObj.latitude, resObj.longitude)}
                              />
                            </div>

                            <div className="row mt-3">
                              <Controller
                                name="address"
                                control={control}
                                rules={{ required: "Address  is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="ym-input-label">
                                      Address <b style={{ color: "red" }}>*</b>
                                    </label>
                                    <div className="flex flex-column">
                                      <InputTextarea
                                        maxLength={130}
                                        id={field.name}
                                        {...field}
                                        placeholder="Enter Address Line"
                                        value={resObj.address}
                                        // onChange={(e) => setAddress(e.target.value)}
                                        onChange={(e) => {
                                          const rawValue = e.target.value;
                                          const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                          if (trimmedValue) { // Check if the trimmed value is not just spaces
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              address: trimmedValue,
                                            });
                                          } else {
                                            setResObj({
                                              ...resObj,
                                              address: '', // Set to empty if invalid
                                            });
                                          }
                                        }}
                                        rows={5}
                                        cols={30}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>
                            <div className="row ">

                              <div className="col m-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Locality <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Locality"
                                      value={resObj.locality}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            locality: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            locality: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col m-0">
                                <div className="">
                                  <Controller
                                    name="city"
                                    control={control}
                                    rules={{ required: "city is required." }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <label className="ym-input-label ">
                                          City<b style={{ color: "red" }}>*</b>
                                        </label>

                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            id={field.name}
                                            value={resObj.city}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            placeholder="Enter City"
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                              if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                setResObj({
                                                  ...resObj,
                                                  city: trimmedValue,
                                                });
                                              } else {
                                                setResObj({
                                                  ...resObj,
                                                  city: '', // Set to empty if invalid
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>

                            </div>

                            <div className="row">

                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="district"
                                    control={control}
                                    rules={{ required: "district is required." }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <label className="ym-input-label">
                                          District
                                          <b style={{ color: "red" }}>*</b>
                                        </label>

                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter District"
                                            id={field.name}
                                            value={resObj.district}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                              if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                setResObj({
                                                  ...resObj,
                                                  district: trimmedValue,
                                                });
                                              } else {
                                                setResObj({
                                                  ...resObj,
                                                  district: '', // Set to empty if invalid
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="col ">
                                <div className="">
                                  <Controller
                                    name="state"
                                    control={control}
                                    rules={{ required: "state is required." }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <label className="ym-input-label ">
                                          State<b style={{ color: "red" }}>*</b>
                                        </label>

                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            id={field.name}
                                            value={resObj.state}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            placeholder="Enter State"
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                              if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                setResObj({
                                                  ...resObj,
                                                  state: trimmedValue,
                                                });
                                              } else {
                                                setResObj({
                                                  ...resObj,
                                                  state: '', // Set to empty if invalid
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col m-0">
                                <div className="">
                                  <Controller
                                    name="country"
                                    control={control}
                                    rules={{ required: "country is required." }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <label className="ym-input-label ">
                                          Country<b style={{ color: "red" }}>*</b>
                                        </label>

                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            id={field.name}
                                            value={resObj.country}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            placeholder="Entry Country"
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                              if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                setResObj({
                                                  ...resObj,
                                                  country: trimmedValue,
                                                });
                                              } else {
                                                setResObj({
                                                  ...resObj,
                                                  country: '', // Set to empty if invalid
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="col m-0">
                                <Controller
                                  name="pincode"
                                  control={control}
                                  rules={{ required: "pincode  is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Pincode<b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <InputText
                                          maxLength={6}
                                          placeholder="Enter Pincode"
                                          id={field.name}
                                          value={resObj.pincode}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          onChange={(e) => {
                                            const rawValue = e.target.value;
                                            // Ensure only numbers and up to 6 digits are allowed for pincode
                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                            if (/^\d{0,6}$/.test(trimmedValue)) { // Validate pincode format
                                              setResObj({
                                                ...resObj,
                                                pincode: trimmedValue,
                                              });
                                            } else {
                                              setResObj({
                                                ...resObj,
                                                pincode: '', // Set to empty if invalid
                                              });
                                            }
                                          }}
                                          keyfilter={/^[0-9]*$/}

                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="FoodPerferences">
                        <h4>Service Options & Preferences</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                            <div className="row">
                              <label className="ym-input-label mt-2">
                                Store Type<b style={{ color: "red" }}>*</b>

                              </label>
                              <div className="row">
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="storetype1"
                                      name="storetype"
                                      value="Groceries"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Groceries")}
                                    />
                                    <label htmlFor="preference1" className="">
                                      Groceries
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="storetype2"
                                      name="storetype"
                                      value="Vegetables"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Vegetables")}
                                    />
                                    <label htmlFor="preference2" className="ml-2">
                                      Vegetables
                                    </label>
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="storetype3"
                                      name="storetype"
                                      value="Fruits"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Fruits")}
                                    />
                                    <label htmlFor="storetype3" className="ml-2">
                                      Fruits
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="storetype3"
                                      name="storetype"
                                      value="Dairy"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Dairy")}
                                    />
                                    <label htmlFor="storetype4" className="ml-2">
                                      Dairy
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="storetype5"
                                      name="storetype"
                                      value="Nuts"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Nuts")}
                                    />
                                    <label htmlFor="storetype5" className="ml-2">
                                      Nuts
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="storetype5"
                                      name="storetype"
                                      value="Eggs"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Eggs")}
                                    />
                                    <label htmlFor="storetype5" className="ml-2">
                                    Eggs
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="storetype5"
                                      name="storetype"
                                      value="Oils"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Oils")}
                                    />
                                    <label htmlFor="storetype5" className="ml-2">
                                      Oils
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="storetype5"
                                      name="storetype"
                                      value="Millets"
                                      onChange={Storetypes}
                                      checked={storetype.includes("Millets")}
                                    />
                                    <label htmlFor="storetype5" className="ml-2">
                                      Millets
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <label className="ym-input-label mt-2">
                                Service Type<b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="row">
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="service1"
                                      name="servicestype"
                                      value="Delivery"
                                      onChange={Servicestyps}
                                      checked={servicestype.includes("Delivery")}
                                    />
                                    <label htmlFor="service1" className="">
                                      Delivery
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="service2"
                                      name="servicestype"
                                      value="In-store Shopping"
                                      onChange={Servicestyps}
                                      checked={servicestype.includes(
                                        "In-store Shopping"
                                      )}
                                    />
                                    <label htmlFor="service2" className="ml-2">
                                      In-store Shopping
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center">
                                    <Checkbox
                                      inputId="service3"
                                      name="servicestype"
                                      value="In-store Pickup"
                                      onChange={Servicestyps}
                                      checked={servicestype.includes(
                                        "In-store Pickup"
                                      )}
                                    />
                                    <label htmlFor="service3" className="ml-2">
                                      In-store Pickup
                                    </label>
                                  </div>
                                </div>
                              </div>
                              </div>
                              {/* <div className="row mt-1">
                                <div className="col">
                                  <div className="">
                                    <label className="ym-input-label">
                                      Services Options
                                    </label>
                                  </div>

                                  <div className="flex flex-column">
                                    <InputText
                                      value={resObj.serviceoptions}
                                      placeholder="Enter sevice options"
                                      onChange={(e: any) => {
                                        setResObj({
                                          ...resObj,
                                          serviceoptions: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="">
                                    <label className="ym-input-label">
                                      Store Size
                                    </label>
                                  </div>
                                  <div className="flex flex-column">
                                    <Dropdown
                                      value={storeSize}
                                      onChange={onStoreSizeChange}
                                      options={storesizes}
                                      optionLabel="name"
                                      placeholder="Select"
                                    />
                                  </div>
                                </div>
                              </div> */}
                            

                            <br></br>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="Timings">
                        <h4>Timings</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                            <div className="row">
                              <label className="ym-input-label">Timings</label>
                              <div className="row">
                                {daysOfWeek.map((day) => (
                                  <div className="col-3" key={day}>
                                    <div className="d-flex align-items-center">
                                      <Checkbox
                                        inputId={`day${day}`}
                                        name="day"
                                        value={day}
                                        checked={checkedDays[day]}
                                        onChange={() => handleCheckboxChange(day)}
                                      />
                                      <label
                                        htmlFor={`day${day}`}
                                        className="ml-1"
                                      >
                                        {day}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">From</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={fromtimes}
                                    options={Timings}
                                    onChange={onFromTimeChange}
                                  />
                                </div>
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">To</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={totimes}
                                    options={Timings}
                                    onChange={onToTimeChange}
                                  />
                                </div>
                                <div className="mt-3">
                                  <button
                                    className="add-button"
                                    onClick={handleAddTiming}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-start align-items-center mt-3">
                                <div className="col-8">
                                  {Object.entries(selectedTimings).map(
                                    ([day, timing]) => (
                                      <div
                                        className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                        key={day}
                                      >
                                        <span className="day">{day}</span>
                                        <div className="row">
                                          <div className="col gap-1">
                                            {timing.map((time, index) => (
                                              <div className="col p-0 mt-1 gap-1">
                                                <span
                                                  className="time"
                                                  key={index}
                                                >
                                                  {time}
                                                </span>

                                                <i
                                                  className="bi bi-trash-fill"
                                                  style={{
                                                    color: "#818181",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    const updatedTimings = {
                                                      ...selectedTimings,
                                                    };
                                                    updatedTimings[day] =
                                                      updatedTimings[day].filter(
                                                        (t) => t !== time
                                                      );

                                                    // Check if there are no times left, delete the entire day
                                                    if (
                                                      updatedTimings[day]
                                                        .length === 0
                                                    ) {
                                                      delete updatedTimings[day];
                                                    }

                                                    setSelectedTimings(
                                                      updatedTimings
                                                    );
                                                  }}
                                                ></i>
                                                <br />
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="uploadimages">
                        <h4>Upload Images</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                            <div className="">
                              <label className="ym-input-label">
                              Thumbnail Images  <b style={{ color: "red" }}>*</b> <span style={{fontSize:"12px"}}>(Add a min 1 or a max 5 Thumbnail Images)</span>

                              </label>
                              <DropFileInput
                                uploadedFiles={storeThumbnailImages}
                                setUploadedFiles={setStoreThumbnailImages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="storeThumbnailImages"
                              />
                            </div>
                            <div className="">
                              <label className="ym-input-label">
                                Organic Store Images
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <DropFileInput
                                uploadedFiles={storeImages}
                                setUploadedFiles={setStoreImages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="Storeimages"
                              />
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            </div>

            <div className="card-footer button-container">
              {activeStep === 0 ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="row">
                      <Button
                        className="next"
                        label="Next"
                        type="submit"
                        // icon="pi pi-check"
                        onClick={
                          handleNext
                        }

                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <Button
                    className="ym-add-button-previous"
                    label="Back"
                    onClick={handleBack}
                  ></Button>

                  {activeStep === 3 ? (
                    <Button
                      className="p-button-success ym-add-button"
                      label="Add Store "
                      onClick={() => {
                        SubmitResObj();
                      }}
                    // disabled={currentIndex === 3}
                    ></Button>
                  ) : (
                    // <Button
                    //   className=" ym-add-button-next"
                    //   label="Next"
                    //   onClick={()=>{handleNext();
                    //     handleSubmit(onSubmit)}}
                    // // disabled={currentIndex === 3}
                    // ></Button>
                    <Button
                      className="next"
                      label="Next"
                      type="submit"
                      // icon="pi pi-check"
                      onClick={
                        handleNext
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrganicStore;
