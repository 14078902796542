import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Loader from '../../components/ui/loader/loader.components';
import { classNames } from 'primereact/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { GetRestbyID } from '../../api/pendingTable.api';
import { Button } from 'primereact/button';
import { EditRestaurantSubmit } from '../../api/editRestaurant.api';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import DropFileInput from "../../pages/restaurants/drop-file-input/drop-file-input"
import { getCategories } from '../../api/addRestarant.api';
import RestaurantTimings from "../../components/RestaurantTimings/timings.component";
import Timings from './../RestaurantTimings/timings.component';
import { sas } from '../../config/constants';
import { RadioButton } from 'primereact/radiobutton';


interface CustomStepperProps {
    steps: string[];
    images: string[];
    stepdesc: string[];
}
interface eateryType {
    name: string;
    code: string;
}
interface valuess {
    name: string;
    code: string;
}
interface Cuisine {
    name: string;
    code: string;
}
interface ServiceOption {
    name: string;
    code: string;
}
type resObj = {
    // lat: any;
    _id: string;
    name: string;
    address1: string;
    locality: string;
    sizeofestablishment: string;
    city: string;
    location: {
        coordinates: number[];
    };
    state: string;
    maplink: string;
    latitude: string;
    longitude: string;
    country: string;
    diettype: any[];
    pincode: string;
    landmark: string;
    website: string;
    email: string;
    phoneno: string;
    eaterytype: any[];
    foodtype: any[];
    preference: string[];
    cuisine: string[];
    ambience: string;
    serving: string[];
    mealoftheday: string[];
    serviceoptions: string[];
    values: any[];
    features: string[];
    specialitems: string[];
    source: string;
    sourcelink: string;
    about: string[];
    whyinyeldam: string[];
    zomatolink: string;
    swiggylink: string;
    dineoutlink: string;
    averagecost: string;
    district: string;
    menuitems: string[];
    menuimages: any[];
    thumbnails: any[];
    images: any[];
    tags: string;
    offers: string[];
    timings: string[];
    hygiene: number;
    health: number;
    taste: number;
    costeffective: number,
    valuesrating: number,
    hospitality: number,
    facebookurl: string;
    instaurl: string;
    youtubeurl: string;
    twitterurl: string;
};
const initialState = {
    name: "",
    _id: "",
    address1: "",
    locality: "",
    city: "",
    state: "",
    maplink: "",
    diettype: [],
    country: "",
    pincode: "",
    landmark: "",
    location: {
        coordinates: [0, 0],
    },
    latitude: "",
    longitude: "",
    website: "",
    email: "",
    phoneno: "",
    eaterytype: [],
    foodtype: [],
    preference: [],
    cuisine: [],
    ambience: "",
    serving: [],
    mealoftheday: [],
    serviceoptions: [],
    values: [],
    features: [],
    specialitems: [],
    source: "",
    sourcelink: "",
    about: [],
    whyinyeldam: [],
    images: [],
    zomatolink: "",
    swiggylink: "",
    dineoutlink: "",
    averagecost: "",
    sizeofestablishment: "",
    district: "",
    menuitems: [],
    menuimages: [],
    restaurantimages: [],
    thumbnails: [],
    tags: "",
    offers: [],
    timings: [],
    hygiene: 0,
    health: 0,
    taste: 0,
    costeffective: 0,
    valuesrating: 0,
    hospitality: 0,
    facebookurl: "",
    instaurl: "",
    youtubeurl: "",
    twitterurl: "",
};

const EditsRestaurant: React.FC<CustomStepperProps> = ({
    steps,
    images,
    stepdesc,
}) => {

    const [isAdding, setIsadding] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [stepColors, setStepColors] = useState(Array(steps.length).fill(""));
    const [completedSteps, setCompletedSteps] = useState(Array(steps.length).fill(false));
    const [thumbnailImages, setThumbnailImages] = useState<any[]>([]);
    const [menuImages, setMenuImages] = useState<any[]>([]);
    const [restaurantImages, setRestantImages] = useState<any[]>([]);
    const [resObj, setResObj] = useState<resObj>(initialState);
    const [spitems, setspitems] = useState<any>([]);
    const [menutems, setmenutems] = useState<any>([]);
    const [whyyeldam, setwhyyeldam] = useState<any>(null);
    const [aboutrest, setaboutrest] = useState<any>(null);
    const [services, setServices] = useState<string[]>([]);
    const [restaurantTages, setRestaurantTages] = useState(null);
    const [value, setValue] = useState<any | null>(null);
    const [cuisine, setCuisine] = useState<any>(null);
    const [diet, setDiet] = useState<any | null>(null);
    const [serviceopt, setServiceopt] = useState<any | null>(null);
    const [foodType, setFoodType] = useState<any | null>(null);
    const [eatery, setEatery] = useState<any | null>(null);
    const [ambience, setAmbience] = useState<any[]>([]);
    const [mod, setMod] = useState<string[]>([]);
    const [valueslist, setValueslist] = useState([]);
    const [cuisinelist, setCuisinelist] = useState([]);
    const [diettypelist, setDiettypelist] = useState([]);
    const [foodtypelist, setFoodtypelist] = useState([]);
    const [eaterytypelist, setEaterytypelist] = useState<any[]>([]);
    const [ambiencelist, setAmbiencelist] = useState([]);
    const [serviceoptions, setServiceoptions] = useState([]);
    const toast = useRef<Toast>(null);


    const { id, type } = useParams();
    const navigate = useNavigate();

    const get_categories = async () => {
        const response = await getCategories();
        console.log("resoojosdbvksv", response.data.data)
        response.data.data.forEach((item: any) => {
            switch (item.type) {
                case 'ambience':
                    setAmbiencelist(item.value);
                    break;
                case 'cuisines':
                    setCuisinelist(item.value);
                    break;
                case 'values':
                    setValueslist(item.value);
                    break;
                case 'serviceoptions':
                    setServiceoptions(item.value);
                    break;
                case 'eaterytype':
                    setEaterytypelist(item.value);
                    break;
                case 'foodtype':
                    setFoodtypelist(item.value);
                    break;
                case 'diettype':
                    setDiettypelist(item.value);
                    break;
                default:
                    break;
            }
        });
    };

    const handleDeleteClick = (index: any, purpose: any) => {
        const updatedRestObj = { ...resObj };
        console.log("updatedRestObj", updatedRestObj);
        if (purpose === "ThumbnailImages") {
            const updatedThumbnails = [...resObj.thumbnails];
            updatedThumbnails.splice(index, 1);
            updatedRestObj.thumbnails = updatedThumbnails;
        } else if (purpose === "RestaurantImages") {
            const updatedImages = [...updatedRestObj.images];
            updatedImages.splice(index, 1);
            updatedRestObj.images = updatedImages;
        } else if (purpose === "MenuImages") {
            const updatedMenuImages = [...updatedRestObj.menuimages];
            updatedMenuImages.splice(index, 1);
            updatedRestObj.menuimages = updatedMenuImages;
        }

        setResObj(updatedRestObj);
    };
    const loadLazyData = async () => {
        try {
            setIsadding(true);

            const resData = await GetRestbyID(id as string);

            console.log("resData rest", resData.data.data);

            if (resData.data.data) {
                const restaurantData = resData.data.data;

                setspitems(restaurantData.specialitems.join(","))

                setResObj({
                    ...restaurantData,
                    latitude: restaurantData.location?.coordinates[1]?.toString() || "",
                    longitude: restaurantData.location?.coordinates[0]?.toString() || ""
                });
            }

            setIsadding(false);
        } catch (error) {
            // Handle any potential errors from API calls
            console.error("Error loading data:", error);
            setIsadding(false);
        }
    };


    const defaultValues = {
        restaurantname: "",
        latitude: "",
        longitude: "",
        address: "",
        city: "",
        district: "",
        location: {
            coordinates: [0, 0],
        },
        state: "",
        country: "",
        pincode: "",
        specialitem: "",
        average: "",
        eaterytype: "",
        foodtype: "",
        ambience: "",
        aboutrestaurant: "",
        whyyeldam: "",
        tags: []
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        // getValues,
        reset,
    } = useForm({ defaultValues });

    const validateFields = (): boolean => {
        const show = (message: any, severity: any, summary: any) => {
            if (toast.current) {
                toast.current.show({
                    severity: severity,
                    summary: summary,
                    detail: message,
                });
            }
        };

        let updatedStepData = [...stepData];

        if (activeStep === 0) {
            if (
                !resObj.name || resObj.name.length === 0 ||
                !resObj.latitude || resObj.latitude.length === 0 ||
                !resObj.longitude || resObj.longitude.length === 0 ||
                !resObj.address1 || resObj.address1.length === 0 ||
                !resObj.locality || resObj.locality.length === 0 ||
                !resObj.city || resObj.city.length === 0 ||
                !resObj.district || resObj.district.length === 0 ||
                !resObj.state || resObj.state.length === 0 ||
                !resObj.country || resObj.country.length === 0 ||
                !resObj.pincode || resObj.pincode.length === 0
            ) {
                show("Please check all Mandatory fields", "warn", "Warn");
                return false;
            }
            updatedStepData[activeStep].completed = true;
        }
        else if (activeStep === 1) {
            if (
                !resObj.averagecost || resObj.averagecost.length === 0 ||
                !resObj.eaterytype || resObj.eaterytype.length === 0 ||
                !resObj.foodtype || resObj.foodtype.length === 0 ||
                !resObj.preference || resObj.preference.length === 0 ||
                !resObj.serving || resObj.serving.length === 0 ||
                !resObj.mealoftheday || resObj.mealoftheday.length === 0 ||
                !resObj.values || resObj.values.length === 0 ||
                !resObj.serviceoptions || resObj.serviceoptions.length === 0
            ) {
                show("Please check all Mandatory fields", "warn", "Warn");
                return false;
            }
            updatedStepData[activeStep].completed = true;
        }
        else if (activeStep === 3) {
            if (
                !resObj.thumbnails || resObj.thumbnails.length === 0 ||
                !resObj.images || resObj.images.length === 0
            ) {
                show("Please check all Mandatory fields", "warn", "Warn");
                return false;
            }
            updatedStepData[activeStep].completed = true;
        }
        else if (activeStep === 4) {
            if (
                !resObj.about || resObj.about.length === 0 ||
                !resObj.whyinyeldam || resObj.whyinyeldam.length === 0
            ) {
                show("Please check all Mandatory fields", "warn", "Warn");
                return false;
            }
            updatedStepData[activeStep].completed = true;
        }

        setStepData(updatedStepData);
        return true;
    };


    const handleImageClick = (index: any) => {
        setActiveStep(index);
        validateFields();
    };
    const About = (e: any) => {
        const restabout = e.target.value;
        const about = restabout.split(".");
        setaboutrest(about);
        setResObj({ ...resObj, about: about });
    };
    const handleNext = () => {
        console.log("clicked next");
        if (thumbnailImages.length > 5) {
            show(
                "You can upload up to 5 thumbnail images only.",
                "error",
                "Limit Exceeded"
            );
            return;
        }
        if (menuImages.length > 20) {
            show("You can upload up to 20 menu images only.",
                "error",
                "Limit Exceeded"
            );
            return;
        }
        if (restaurantImages.length > 25) {
            show("You can upload up to 25 restaurant images only.",
                "error",
                "Limit Exceeded"
            );
            return;
        }
        if (!validateFields()) {
            return;
        }
        // Create a copy of the current step data array
        const updatedStepData = [...stepData];

        // Update the current step status to "completed"
        updatedStepData[activeStep] = {
            ...updatedStepData[activeStep],
            completed: true,
        };

        // Create a copy of completedSteps and mark the current step as completed
        const updatedCompletedSteps = [...completedSteps];
        updatedCompletedSteps[activeStep] = true;

        // Update state with the new step data and completed steps
        setStepData(updatedStepData);
        setCompletedSteps(updatedCompletedSteps);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        updateStepColors();
        console.log(resObj);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        updateStepColors();
        // console.log(resObj);
        reset(defaultValues);
    };
    const Services = (e: any) => {
        let _services = [...services];

        if (e.checked) _services.push(e.value);
        else _services.splice(_services.indexOf(e.value), 1);

        setServices(_services);
        setResObj({ ...resObj, serving: _services });
    };

    const MOD = (e: any) => {
        let _mod = [...mod];

        if (e.checked) _mod.push(e.value);
        else _mod.splice(_mod.indexOf(e.value), 1);

        setMod(_mod);
        setResObj({ ...resObj, mealoftheday: _mod });
    };

    const [preferences, setPreferences] = useState<string[]>([]);
    const handlePreferenceChange = (e: any) => {
        const selectedPreference = e.value;

        setPreferences([selectedPreference]);
        setResObj({
            ...resObj,
            preference: [selectedPreference]
        });
    };

    const handleEaterytypeChange = (e: any) => {
        const selectedEateryType = eaterytypelist.find(item => item.name === e.value);
        if (selectedEateryType) {
            setEatery([selectedEateryType]);
            setResObj({
                ...resObj,
                eaterytype: [selectedEateryType]
            });
        }
    };

    const [features, setFeatures] = useState<string[]>([]);
    const Features = (e: any) => {
        let _features = [...features];

        if (e.checked) _features.push(e.value);
        else _features.splice(_features.indexOf(e.value), 1);

        setFeatures(_features);
        setResObj({ ...resObj, features: _features });
    };

    const updateStepColors = () => {
        const newStepColors = [...stepColors];
        newStepColors[activeStep] = "lightblue"; // Change this to the desired color
        setStepColors(newStepColors);
    };
    const getFormErrorMessage = (name: string) => {
        const errorObject = errors[name as keyof typeof defaultValues];

        return errorObject ? (
            <small className="p-error">{errorObject.message}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };

    const fetchAddress = async (lat: any, long: any) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
            );
            const data = await response.json();
            console.log(data);

            let address = '';
            let district = '';
            let city = '';
            let state = '';
            let pincode = '';
            let countryName = '';
            let locality = '';

            data.results.forEach((result: any) => {
                result.address_components.forEach((component: any) => {
                    if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
                        address += component.long_name + ', ';
                    } else if (component.types.includes('locality')) {
                        city = component.long_name;
                    } else if (component.types.includes('administrative_area_level_1')) {
                        state = component.long_name;
                    } else if (component.types.includes('administrative_area_level_3')) {
                        district = component.long_name;
                    } else if (component.types.includes('postal_code')) {
                        pincode = component.long_name;
                    } else if (component.types.includes('country')) {
                        countryName = component.long_name;
                    }

                    else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('neighborhood')) {
                        locality = component.long_name;
                    }
                });
            });


            locality = locality || address.slice(0, -2);

            setResObj({
                ...resObj,
                locality: locality,
                district: district,
                city: city,
                state: state,
                pincode: pincode,
                country: countryName,
            });

        } catch (error) {
            console.error("Error fetching city:", error);
        }
    };
    const show = (message: any, severity: any, summary: any) => {
        if (toast.current) {
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };

    const handleCheckboxChange = (day: string) => {
        setCheckedDays((prevState) => ({
            ...prevState,
            [day]: !prevState[day],
        }));
    };
    const onFromTimeChange = (e: any) => {
        setfromtimes(e.value);
    };

    const onToTimeChange = (e: any) => {
        settotimes(e.value);
    };


    const onAmbienceChange = (e: any) => {
        setAmbience(e.value);
        setResObj({ ...resObj, ambience: e.value });
    };

    const onEateryChange = (e: any) => {
        setEatery(e.value);
        setResObj({ ...resObj, eaterytype: e.value });
    };
    const onValueChange = (e: any) => {
        setValue(e.value);
        setResObj({ ...resObj, values: e.value });
    };
    const onFoodTypeChange = (e: any) => {
        setFoodType(e.value);
        setResObj({ ...resObj, foodtype: e.value });
    };

    const onDietChange = (e: any) => {
        setDiet(e.value);
        setResObj({ ...resObj, diettype: e.value });
    };

    const OnServiceOptionschange = (e: any) => {
        const optionNames: string[] = e.value.map((option: { name: any; }) => option.name);

        setServiceopt(e.value);
        setResObj({ ...resObj, serviceoptions: optionNames });
    };

    const onCuisineChange = (e: any) => {
        setCuisine(e.value);
        setResObj({
            ...resObj,
            cuisine: e.value,
        });
    };



    const onTagchange = (e: any) => {
        setRestaurantTages(e.value);
        console.log(e.value)
        setResObj({ ...resObj, tags: e.value.name });
    };

    const splitaray = (val: string): string[] => {
        return val.split(',').map(item => item.trimStart()).filter(item => item.length > 0);
    };

    const SpecialItemss = (e: any) => {
        const value = e.target.value.trimStart();
        setspitems(value);
        setResObj({
            ...resObj,
            specialitems: splitaray(value)
        });
    };

    const MenuItemss = (items: any) => {
        // const actualArray = JSON.parse(items);
        console.log("menuitems", items);
        setmenutems(items);
        setResObj({ ...resObj, menuitems: items });
    };
    const ResetForm = () => {
        console.log("resetting the form");
        setIsadding(true);
        window.location.reload();
        setResObj(initialState);
    };
    const WhyinYeldam = (e: any) => {
        const why = e.target.value;
        const whyus = why.split(".");
        setwhyyeldam(whyus);
        setResObj({ ...resObj, whyinyeldam: whyus });
    };
    const Timings = [
        "00:00am",
        "00:30am",
        "01:00am",
        "01:30am",
        "02:00am",
        "02:30am",
        "03:00am",
        "03:30am",
        "04:00am",
        "04:30am",
        "05:00am",
        "05:30am",
        "06:00am",
        "06:30am",
        "07:00am",
        "07:30am",
        "08:00am",
        "08:30am",
        "09:00am",
        "09:30am",
        "10:00am",
        "10:30am",
        "11:00am",
        "11:30am",
        "12:00pm",
        "12:30pm",
        "01:00pm",
        "01:30pm",
        "02:00pm",
        "02:30pm",
        "03:00pm",
        "03:30pm",
        "04:00pm",
        "04:30pm",
        "05:00pm",
        "05:30pm",
        "06:00pm",
        "06:30pm",
        "07:00pm",
        "07:30pm",
        "08:00pm",
        "08:30pm",
        "09:00pm",
        "09:30pm",
        "10:00pm",
        "10:30pm",
        "11:00pm",
        "11:30pm",
        "11:59pm",
        "12:00am",
    ];
    const daysOfWeek: string[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const [checkedDays, setCheckedDays] = useState(() => {
        const initialCheckedState: Record<string, boolean> = {};
        daysOfWeek.forEach((day) => {
            initialCheckedState[day] = false;
        });
        return initialCheckedState;
    });
    //for timing
    const initialSelectedTimings = {};
    const [selectedTimings, setSelectedTimings] = useState<
        Record<string, string[]>
    >(initialSelectedTimings);
    const [totimes, settotimes] = useState();
    const [fromtimes, setfromtimes] = useState();

    const handleAddTiming = () => {
        if (fromtimes && totimes) {
            const selectedDays = Object.keys(checkedDays).filter(
                (day) => checkedDays[day]
            );

            setSelectedTimings((prevSelectedTimings) => {
                const updatedTimings = { ...prevSelectedTimings };

                selectedDays.forEach((dayKey) => {
                    const existingTimings = updatedTimings[dayKey] || [];
                    const newTiming = `${fromtimes} to ${totimes}`;
                    updatedTimings[dayKey] = [...existingTimings, newTiming];
                });

                console.log("selectedTimings", updatedTimings);

                // Directly set the updatedTimings without wrapping it in an array
                //setResObj({ ...resObj, timings: updatedTimings });

                return updatedTimings;
            });
        }
    };
    const [hygienerating, sethygienerating] = useState<number | null>(null);
    const [healthrating, sethealthrating] = useState<number | null>(null);
    const [tasterating, settasterating] = useState<number | null>(null);
    const [effectivecostrating, seteffectivecostrating] = useState<number | null>(null);
    const [valuesrating, setvaluesrating] = useState<number | null>(null);
    const [hospitalityrating, sethospitalityrating] = useState<number | null>(null);
    const [boxNumberColors, setBoxNumberColors] = useState<{ [key: number]: string }>(
        Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
    );
    const [hygieneboxNumberColors, sethygieneBoxNumberColors] = useState<{ [key: number]: string }>(
        Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
    );
    const [tasteboxNumberColors, settasteBoxNumberColors] = useState<{ [key: number]: string }>(
        Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
    );
    const [costboxNumberColors, setcostBoxNumberColors] = useState<{ [key: number]: string }>(
        Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
    );
    const [valueboxNumberColors, setvalueBoxNumberColors] = useState<{ [key: number]: string }>(
        Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
    );
    const [hospitalityboxNumberColors, sethospitalityBoxNumberColors] = useState<{ [key: number]: string }>(
        Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
    );

    const handleHygieneRatingClick = (value: number) => {
        sethygienerating(value);
        setResObj(prevState => ({ ...prevState, hygiene: value }));
        console.log("hygiene", value);
        let backgroundColor = "";
        if (value >= 1 && value <= 3) {
            backgroundColor = "pink";
        } else if (value >= 4 && value <= 6) {
            backgroundColor = "orange";
        } else if (value >= 7 && value <= 10) {
            backgroundColor = "#36a41d";
        }
        // Update background color
        const updatedColors = { ...hygieneboxNumberColors, [value - 1]: backgroundColor };
        sethygieneBoxNumberColors(updatedColors);
        console.log("bbb", updatedColors);
        // Update background color of document body
        document.body.style.backgroundColor = backgroundColor;

    };

    const handleHealthRatingClick = (value: number) => {
        sethealthrating(value);
        setResObj(prevState => ({ ...prevState, health: value }));
        console.log("health", value);
        let backgroundColor = "";
        if (value >= 1 && value <= 3) {
            backgroundColor = "pink";
        } else if (value >= 4 && value <= 6) {
            backgroundColor = "orange";
        } else if (value >= 7 && value <= 10) {
            backgroundColor = "#36a41d";
        }

        // Update background color
        const updatedColors = { ...boxNumberColors, [value - 1]: backgroundColor };
        setBoxNumberColors(updatedColors);
        console.log("bbb", updatedColors);
        // Update background color of document body
        document.body.style.backgroundColor = backgroundColor;
    };

    const handleTasteRatingClick = (value: number) => {
        settasterating(value);
        setResObj(prevState => ({ ...prevState, taste: value }));
        console.log("taste", value);
        let backgroundColor = "";
        if (value >= 1 && value <= 3) {
            backgroundColor = "pink";
        } else if (value >= 4 && value <= 6) {
            backgroundColor = "orange";
        } else if (value >= 7 && value <= 10) {
            backgroundColor = "#36a41d";
        }
        const updatedColors = { ...tasteboxNumberColors, [value - 1]: backgroundColor };
        settasteBoxNumberColors(updatedColors);
        console.log("bbb", updatedColors);
        // Update background color of document body
        document.body.style.backgroundColor = backgroundColor;
    };

    const handleCostEffectiveRatingClick = (value: number) => {
        seteffectivecostrating(value);
        setResObj(prevState => ({ ...prevState, costeffective: value }));
        console.log("cost", value);
        let backgroundColor = "";
        if (value >= 1 && value <= 3) {
            backgroundColor = "pink";
        } else if (value >= 4 && value <= 6) {
            backgroundColor = "orange";
        } else if (value >= 7 && value <= 10) {
            backgroundColor = "#36a41d";
        }
        const updatedColors = { ...costboxNumberColors, [value - 1]: backgroundColor };
        setcostBoxNumberColors(updatedColors);
        console.log("bbb", updatedColors);
        // Update background color of document body
        document.body.style.backgroundColor = backgroundColor;
    };

    const handleValueRatingClick = (value: number) => {
        setvaluesrating(value);
        setResObj(prevState => ({ ...prevState, valuesrating: value }));
        console.log("values", value);
        let backgroundColor = "";
        if (value >= 1 && value <= 3) {
            backgroundColor = "pink";
        } else if (value >= 4 && value <= 6) {
            backgroundColor = "orange";
        } else if (value >= 7 && value <= 10) {
            backgroundColor = "#36a41d";
        }
        const updatedColors = { ...valueboxNumberColors, [value - 1]: backgroundColor };
        setvalueBoxNumberColors(updatedColors);
        console.log("bbb", updatedColors);
        // Update background color of document body
        document.body.style.backgroundColor = backgroundColor;
    };

    const handleHospitalityRatingClick = (value: number) => {
        sethospitalityrating(value);
        setResObj(prevState => ({ ...prevState, hospitality: value }));
        console.log("hospitality", value);
        let backgroundColor = "";

        if (value >= 1 && value <= 3) {
            backgroundColor = "pink";
        } else if (value >= 4 && value <= 6) {
            backgroundColor = "orange";
        } else if (value >= 7 && value <= 10) {
            backgroundColor = "#36a41d";
        }
        const updatedColors = { ...hospitalityboxNumberColors, [value - 1]: backgroundColor };
        sethospitalityBoxNumberColors(updatedColors);
        console.log("bbb", updatedColors);
        // Update background color of document body
        document.body.style.backgroundColor = backgroundColor;
    };

    const [stepData, setStepData] = useState([
        { completed: false },
        { completed: false },
        { completed: false },
        { completed: false },
        { completed: false }
    ]);



    const handleEditRestaurant = async () => {
        setIsadding(true);
        if (!resObj.name || resObj.name.length === 0 ||
            !resObj.latitude || resObj.latitude.length === 0 ||
            !resObj.longitude || resObj.longitude.length === 0 ||
            !resObj.address1 || resObj.address1.length === 0 ||
            !resObj.locality || resObj.locality.length === 0 ||
            !resObj.city || resObj.city.length === 0 ||
            !resObj.district || resObj.district.length === 0 ||
            !resObj.state || resObj.state.length === 0 ||
            !resObj.country || resObj.country.length === 0 ||
            !resObj.pincode || resObj.pincode.length === 0 ||
            !resObj.averagecost || resObj.averagecost.length === 0 ||
            !resObj.eaterytype || resObj.eaterytype.length === 0 ||
            !resObj.foodtype || resObj.foodtype.length === 0 ||
            !resObj.preference || resObj.preference.length === 0 ||
            !resObj.mealoftheday || resObj.mealoftheday.length === 0 ||
            !resObj.values || resObj.values.length === 0 ||
            !resObj.serviceoptions || resObj.serviceoptions.length === 0 ||
            !resObj.thumbnails || resObj.thumbnails.length === 0 ||
            !resObj.images || resObj.images.length === 0 ||
            (!resObj.about || resObj.about.length === 0 || resObj.about[0].trim() === '') ||
            (!resObj.whyinyeldam || resObj.whyinyeldam.length === 0 || resObj.whyinyeldam[0].trim() === '')
        ) {
            show("Please check all Mandatory fields", "warn", "Warn");
            return;
        }
        if (resObj) {

            const updatedResObj = {
                ...resObj,
                location: {
                    coordinates: [
                        parseFloat(resObj.longitude) || 0,
                        parseFloat(resObj.latitude) || 0,
                    ],
                },
            };

            const formData = new FormData();

            // Basic details
            formData.append("name", updatedResObj.name);
            formData.append("phoneno", updatedResObj.phoneno);
            formData.append("email", updatedResObj.email);
            formData.append("website", updatedResObj.website);
            formData.append("facebookurl", updatedResObj.facebookurl);
            formData.append("instaurl", updatedResObj.instaurl);
            formData.append("youtubeurl", updatedResObj.youtubeurl)
            formData.append("twitterurl", updatedResObj.twitterurl);
            formData.append("address1", updatedResObj.address1);
            formData.append("locality", updatedResObj.locality);
            formData.append("latitude", updatedResObj.latitude);
            formData.append("longitude", updatedResObj.longitude);
            formData.append("city", updatedResObj.city);
            formData.append("district", updatedResObj.district);
            formData.append("state", updatedResObj.state);
            formData.append("country", updatedResObj.country);
            formData.append("pincode", updatedResObj.pincode);
            formData.append("averagecost", updatedResObj.averagecost);
            formData.append("ambience", updatedResObj.ambience);

            // Add preference
            updatedResObj.preference.forEach((pref: string, index: number) => {
                formData.append(`preference[${index}]`, pref);
            });

            // Add serving
            updatedResObj.serving.forEach((serve: string, index: number) => {
                formData.append(`serving[${index}]`, serve);
            });

            // Add meal of the day
            updatedResObj.mealoftheday.forEach((meal: string, index: number) => {
                formData.append(`mealoftheday[${index}]`, meal);
            });

            // Special items 
            updatedResObj.specialitems.forEach((special: any, index: number) => {
                formData.append(`specialitems[${index}]`, special);
            });

            // Menu Items
            updatedResObj.menuitems.forEach((menu: any, index: number) => {
                formData.append(`menuitems[${index}]`, menu);
            })

            // Eatery types (handling object structure)
            updatedResObj.eaterytype.forEach((eateryType: any, index: number) => {
                formData.append(`eaterytype[${index}][name]`, eateryType.name);
                formData.append(`eaterytype[${index}][code]`, eateryType.code);
            });

            // Food type 
            updatedResObj.foodtype.forEach((foodtype: any, index: number) => {
                formData.append(`foodtype[${index}][name]`, foodtype.name);
                formData.append(`foodtype[${index}][code]`, foodtype.code);
            });

            // Diet type
            updatedResObj.diettype.forEach((diettype: any, index: number) => {
                formData.append(`diettype[${index}][name]`, diettype.name);
                formData.append(`diettype[${index}][code]`, diettype.code);
            });

            // values
            updatedResObj.values.forEach((value: any, index: number) => {
                formData.append(`values[${index}][name]`, value.name);
                formData.append(`values[${index}][code]`, value.code);
            });

            // Cuisine types
            updatedResObj.cuisine.forEach((cuisine: any, index: number) => {
                formData.append(`cuisine[${index}][name]`, cuisine.name);
                formData.append(`cuisine[${index}][code]`, cuisine.code);
            });

            // Service options
            updatedResObj.serviceoptions.forEach((serviceoption: string, index: number) => {
                formData.append(`serviceoptions[${index}]`, serviceoption);
            });

            // Timings - Only append if they've changed
            if (JSON.stringify(selectedTimings) !== JSON.stringify(initialSelectedTimings)) {
                formData.append("timings", JSON.stringify(selectedTimings));
            }

            // Thumbnails (files or URLs)
            updatedResObj.thumbnails.forEach((thumbnail: File | string) => {
                if (typeof thumbnail === "string") {
                    formData.append("existingThumbnailURLs", thumbnail);
                } else {
                    formData.append("thumbnails", thumbnail);
                }
            });

            // Images (files or URLs)
            updatedResObj.images.forEach((image: File | string) => {
                if (typeof image === "string") {
                    formData.append("existingImageURLs", image);
                } else {
                    formData.append("images", image);
                }
            });

            // Menu Images (files or URLs)
            updatedResObj.menuimages.forEach((menuimage: File | string) => {
                if (typeof menuimage === "string") {
                    formData.append("existingMenuImageURLs", menuimage);
                } else {
                    formData.append("menuimages", menuimage);
                }
            });

            // Fecilities & features
            updatedResObj.features.forEach((features: any, index: number) => {
                formData.append(`features`, features);
            })

            // About 
            updatedResObj.about.forEach((about: any, index: number) => {
                formData.append(`about`, about);
            });

            // why in yeldam
            updatedResObj.whyinyeldam.forEach((why: any, index: number) => {
                formData.append(`whyinyeldam`, why);
            });

            try {
                const response = await EditRestaurantSubmit(updatedResObj._id, formData);

                const currentPath = window.location.pathname;
                const productId = updatedResObj._id;
                if (response.status === 200) {
                    show("Restaurant Updated Successfully", "success", "Success");
                    let targetUrl = '';
                    setTimeout(async () => {
                        if (currentPath.includes("/pending")) {
                            targetUrl = `/restaurants/pending/details/${productId}`;
                        } else if (currentPath.includes("/rejected")) {
                            targetUrl = `/restaurants/rejected/details/${productId}`;
                        }

                        if (targetUrl) {
                            navigate(targetUrl);
                            setIsadding(false);

                        }
                    }, 2000);
                }
                else {
                    setIsadding(false);
                    show("Invalid Access", "error", "Error");
                }

            } catch (error) {
                console.error("Error updating restaurant:", error);
                setIsadding(false);

            }
        }
    };


    useEffect(() => {
        setIsadding(true);
        loadLazyData();
        // console.log("et", eaterytype);
    }, [id]);
    useEffect(() => {
        // get_states();
        get_categories();
    }, []);


    console.log("updating the resobj", resObj);
    return (
        <>
            {!isAdding ? "" : <Loader />}

            <div className="d-flex justify-content-center align-items-center">

                <div className="col d-flex justify-content-center align-items-center">
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "85vh" }}
                    >
                        <ol className="c-stepper">
                            {steps.map((label, index) => (
                                <li key={label} className={`c-stepper__item`}>
                                    <img
                                        className="c-stepper__item__img"
                                        src={images[index]}
                                        style={{ fill: "#00ff00" }}
                                        onClick={() => handleImageClick(index)}
                                    />
                                    <div className="c-stepper__content">
                                        <h3 className="c-stepper__title">Step {index + 1}</h3>
                                        <p className="c-stepper__disc">{stepdesc[index]}</p>
                                        <p
                                            className="fs-12"
                                            style={{
                                                color: index === activeStep ? "#8856BB" : "green",
                                            }}
                                        >
                                            {index === activeStep ? "In progress" : "Completed"}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>

                <div className="col">
                    <div
                        className="card details-card"
                        style={{
                            width: "calc(100vw - 60vw)",
                            height: "92vh",
                            borderRadius: "12px",
                        }}
                    >
                        <div className="next-card card-body pb-0">
                            <Toast ref={toast}></Toast>

                            {activeStep === 0 && (
                                <>
                                    <div className="row m-0">
                                        <div className="col-12 p-1">
                                            <div className="child-left" id="RestaurantDetails">
                                                <h4 className="rest-detail-head">Restaurant Details</h4>
                                                <hr></hr>
                                                <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 30vh)" }}>

                                                    <div className="row">
                                                        <Controller
                                                            name="restaurantname"
                                                            control={control}
                                                            rules={{
                                                                required: "Restaurant name  is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <label className="ym-input-label">
                                                                        Restaurant Name{" "}
                                                                        <b style={{ color: "red" }}>*</b>
                                                                    </label>
                                                                    <div className="flex flex-column">
                                                                        <InputText
                                                                            placeholder="Enter Restaurant Name"
                                                                            maxLength={50}
                                                                            id={field.name}
                                                                            value={resObj.name}
                                                                            className={classNames({
                                                                                "p-invalid": fieldState.error,
                                                                            })}
                                                                            onChange={(e) => {
                                                                                let value = e.target.value;
                                                                                value = value.trimStart();
                                                                                if (value.length > 0) {
                                                                                    field.onChange(e);
                                                                                    setResObj({
                                                                                        ...resObj,
                                                                                        name: value,
                                                                                    });
                                                                                } else {
                                                                                    setResObj({
                                                                                        ...resObj,
                                                                                        name: '',
                                                                                    });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {getFormErrorMessage(field.name)}
                                                                </>
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="row">
                                                        <label className="ym-input-label">
                                                            Phone Number
                                                        </label>
                                                        <div className="flex flex-column">
                                                            <InputText
                                                                id="phoneno"
                                                                className="no-spinner"
                                                                maxLength={14}
                                                                value={resObj.phoneno}
                                                                onChange={(e) => {
                                                                    const rawValue = e.target.value;
                                                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                    if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                        setResObj({
                                                                            ...resObj,
                                                                            phoneno: trimmedValue,
                                                                        });
                                                                    } else {
                                                                        setResObj({
                                                                            ...resObj,
                                                                            phoneno: '', // Set to empty if invalid
                                                                        });
                                                                    }
                                                                }}
                                                                placeholder="Enter Phone Number"
                                                                aria-describedby="username-help"

                                                                keyfilter={/^[0-9+\-\s]*$/}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br></br>

                                                    <div className="row">
                                                        <div className="d-flex align-items-baseline gap-1">
                                                            <label className="ym-input-label">
                                                                Email Address
                                                            </label>
                                                            <i
                                                                className="bi bi-info-circle"
                                                                title="Enter the valid email"
                                                                id="icon"
                                                            ></i>
                                                        </div>

                                                        <div className="flex flex-column gap-2">
                                                            <InputText
                                                                id="email"
                                                                maxLength={50}
                                                                value={resObj.email}
                                                                placeholder="Enter Email Address"
                                                                aria-describedby="username-help"
                                                                onChange={(e) => {
                                                                    const rawValue = e.target.value;
                                                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                    if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                        setResObj({
                                                                            ...resObj,
                                                                            email: trimmedValue,
                                                                        });
                                                                    } else {
                                                                        setResObj({
                                                                            ...resObj,
                                                                            email: '', // Set to empty if invalid
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="row">
                                                        <div className="d-flex align-items-baseline gap-1">
                                                            <label className="ym-input-label">
                                                                Website URL{" "}
                                                            </label>
                                                            <i
                                                                className="bi bi-info-circle"
                                                                title="Enter the valid Website URL"
                                                                id="icon"
                                                            ></i>
                                                        </div>
                                                        <div className="flex flex-column gap-2">
                                                            <InputText
                                                                id="website"
                                                                maxLength={50}
                                                                value={resObj.website}
                                                                placeholder="Enter Website URL"
                                                                aria-describedby="username-help"
                                                                onChange={(e) => {
                                                                    const rawValue = e.target.value;
                                                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                    if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                        setResObj({
                                                                            ...resObj,
                                                                            website: trimmedValue,
                                                                        });
                                                                    } else {
                                                                        setResObj({
                                                                            ...resObj,
                                                                            website: '', // Set to empty if invalid
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                                                        <div className="col-6 p-0">
                                                            <div className="flex flex-column">
                                                                <label className="ym-input-label">
                                                                    Facebook
                                                                </label>
                                                                <div className="input-dropdown-container">
                                                                    <InputText
                                                                        placeholder="Enter Facebook URL"
                                                                        value={resObj.facebookurl}
                                                                        className="col-12"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value.trimStart(); // Trim leading spaces
                                                                            // Update the state only if the value is not just spaces
                                                                            setResObj({
                                                                                ...resObj,
                                                                                facebookurl: value.length > 0 ? value : '',
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 p-0">
                                                            <div className="flex flex-column">
                                                                <label className="ym-input-label">
                                                                    Instagram
                                                                </label>
                                                                <div className="input-dropdown-container">
                                                                    <InputText
                                                                        placeholder="Enter Instagram URL"
                                                                        value={resObj.instaurl}
                                                                        className="col-12"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value.trimStart(); // Trim leading spaces
                                                                            // Update the state only if the value is not just spaces
                                                                            setResObj({
                                                                                ...resObj,
                                                                                instaurl: value.length > 0 ? value : '',
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                                                        <div className="col-6 p-0">
                                                            <div className="flex flex-column">
                                                                <label className="ym-input-label">
                                                                    Youtube
                                                                </label>
                                                                <div className="input-dropdown-container">
                                                                    <InputText
                                                                        placeholder="Enter Youtube URL"
                                                                        className="col-12"
                                                                        value={resObj.youtubeurl}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value.trimStart(); // Trim leading spaces
                                                                            // Update the state only if the value is not just spaces
                                                                            setResObj({
                                                                                ...resObj,
                                                                                youtubeurl: value.length > 0 ? value : '',
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-6 p-0">
                                                            <div className="flex flex-column">
                                                                <label className="ym-input-label">
                                                                    X
                                                                </label>
                                                                <div className="input-dropdown-container">
                                                                    <InputText
                                                                        placeholder="Enter X URL"
                                                                        className="col-12"
                                                                        value={resObj.twitterurl}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value.trimStart(); // Trim leading spaces
                                                                            // Update the state only if the value is not just spaces
                                                                            setResObj({
                                                                                ...resObj,
                                                                                twitterurl: value.length > 0 ? value : '',
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <div className="">
                                                                <Controller
                                                                    name="latitude"
                                                                    control={control}
                                                                    rules={{
                                                                        required: "Latitude is required.",
                                                                        pattern: {
                                                                            value: /^[+-]?([0-9]*[.]?[0-9]+)$/, // Allowing negative and positive values with decimals
                                                                            message: "Enter a valid latitude with only numbers and dots.",
                                                                        },
                                                                    }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <div className="d-flex align-items-baseline">
                                                                                <label className="ym-input-label">
                                                                                    Latitude
                                                                                    <b style={{ color: "red" }}>*</b>
                                                                                </label>
                                                                                <i
                                                                                    className="bi bi-info-circle ml-1"
                                                                                    title="Enter the valid latitude"
                                                                                    id="icon"
                                                                                ></i>
                                                                            </div>
                                                                            <div className="flex flex-column">
                                                                                <InputText
                                                                                    maxLength={30}
                                                                                    placeholder="Enter a valid latitude"
                                                                                    id={field.name}
                                                                                    value={resObj.latitude}
                                                                                    className={classNames({
                                                                                        "p-invalid": fieldState.error,
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value.trim();
                                                                                        const latitude = value === "" ? null : parseFloat(value); // Set latitude to null if empty
                                                                                        field.onChange(e);
                                                                                        setResObj({
                                                                                            ...resObj,
                                                                                            latitude: value, // update latitude string in resObj
                                                                                            location: {
                                                                                                ...resObj.location,
                                                                                                coordinates: [
                                                                                                    resObj.location.coordinates[0], // keep longitude unchanged
                                                                                                    latitude !== null ? latitude : 0, // set default or actual latitude
                                                                                                ],
                                                                                            },
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {getFormErrorMessage(field.name)}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>



                                                        <div className="col">
                                                            <div className="">
                                                                <Controller
                                                                    name="longitude"
                                                                    control={control}
                                                                    rules={{
                                                                        required: "Longitude is required.",
                                                                        pattern: {
                                                                            value: /^[+-]?([0-9]*[.]?[0-9]+)$/, // Allowing negative and positive values with decimals
                                                                            message: "Enter a valid longitude with only numbers and dots.",
                                                                        },
                                                                    }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <div className="d-flex align-items-baseline">
                                                                                <label className="ym-input-label">
                                                                                    Longitude
                                                                                    <b style={{ color: "red" }}>*</b>
                                                                                </label>
                                                                                <i
                                                                                    className="bi bi-info-circle ml-1"
                                                                                    title="Enter the valid longitude"
                                                                                    id="icon"
                                                                                ></i>
                                                                            </div>
                                                                            <div className="flex flex-column">
                                                                                <InputText
                                                                                    maxLength={30}
                                                                                    placeholder="Enter a valid longitude"
                                                                                    id={field.name}
                                                                                    value={resObj.longitude}
                                                                                    className={classNames({
                                                                                        "p-invalid": fieldState.error,
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value.trim();
                                                                                        const longitude = value === "" ? null : parseFloat(value); // Set longitude to null if empty
                                                                                        field.onChange(e);
                                                                                        setResObj({
                                                                                            ...resObj,
                                                                                            longitude: value, // update longitude string in resObj
                                                                                            location: {
                                                                                                ...resObj.location,
                                                                                                coordinates: [
                                                                                                    longitude !== null ? longitude : 0, // set default or actual longitude
                                                                                                    resObj.location.coordinates[1], // keep latitude unchanged
                                                                                                ],
                                                                                            },
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {getFormErrorMessage(field.name)}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className="d-flex justify-content-center align-items-center mb-1">
                                                        <Button className="get-address" label="Get Address" severity="success" outlined aria-label="Search"
                                                            onClick={() => fetchAddress(resObj.latitude, resObj.longitude)}
                                                        />
                                                    </div>

                                                    <div className="row mt-3">
                                                        <Controller
                                                            name="address"
                                                            control={control}
                                                            rules={{ required: "Address  is required." }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <label className="ym-input-label">
                                                                        Address <b style={{ color: "red" }}>*</b>
                                                                    </label>
                                                                    <div className="flex flex-column">
                                                                        <InputTextarea
                                                                            maxLength={130}
                                                                            id={field.name}
                                                                            {...field}
                                                                            placeholder="Enter Address Line"
                                                                            value={resObj.address1}
                                                                            // onChange={(e) => setAddress(e.target.value)}
                                                                            onChange={(e) => {
                                                                                const rawValue = e.target.value;
                                                                                const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                    field.onChange(e);
                                                                                    setResObj({
                                                                                        ...resObj,
                                                                                        address1: trimmedValue,
                                                                                    });
                                                                                } else {
                                                                                    setResObj({
                                                                                        ...resObj,
                                                                                        address1: '', // Set to empty if invalid
                                                                                    });
                                                                                }
                                                                            }}
                                                                            rows={5}
                                                                            cols={30}
                                                                            className={classNames({
                                                                                "p-invalid": fieldState.error,
                                                                            })}
                                                                        />
                                                                    </div>
                                                                    {getFormErrorMessage(field.name)}
                                                                </>
                                                            )}
                                                        />
                                                    </div>




                                                    <div className="row ">

                                                        <div className="col m-0">
                                                            <div className="flex flex-column">
                                                                <label className="ym-input-label">
                                                                    Locality <b style={{ color: 'red' }}>*</b>
                                                                </label>
                                                                <div className="input-dropdown-container">
                                                                    <InputText
                                                                        placeholder="Enter Locality"
                                                                        value={resObj.locality}
                                                                        className="col-12"
                                                                        onChange={(e) => {
                                                                            const rawValue = e.target.value;
                                                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                            if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                setResObj({
                                                                                    ...resObj,
                                                                                    locality: trimmedValue,
                                                                                });
                                                                            } else {
                                                                                setResObj({
                                                                                    ...resObj,
                                                                                    locality: '', // Set to empty if invalid
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col m-0">
                                                            <div className="">
                                                                <Controller
                                                                    name="city"
                                                                    control={control}
                                                                    rules={{ required: "city is required." }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <label className="ym-input-label ">
                                                                                City<b style={{ color: "red" }}>*</b>
                                                                            </label>

                                                                            <div className="flex flex-column">
                                                                                <InputText
                                                                                    maxLength={30}
                                                                                    id={field.name}
                                                                                    value={resObj.city}
                                                                                    className={classNames({
                                                                                        "p-invalid": fieldState.error,
                                                                                    })}
                                                                                    placeholder="Enter City"
                                                                                    onChange={(e) => {
                                                                                        const rawValue = e.target.value;
                                                                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                city: trimmedValue,
                                                                                            });
                                                                                        } else {
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                city: '', // Set to empty if invalid
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {getFormErrorMessage(field.name)}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col">
                                                            <div className="">
                                                                <Controller
                                                                    name="district"
                                                                    control={control}
                                                                    rules={{ required: "district is required." }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <label className="ym-input-label">
                                                                                District
                                                                                <b style={{ color: "red" }}>*</b>
                                                                            </label>

                                                                            <div className="flex flex-column">
                                                                                <InputText
                                                                                    maxLength={30}
                                                                                    placeholder="Enter District"
                                                                                    id={field.name}
                                                                                    value={resObj.district}
                                                                                    className={classNames({
                                                                                        "p-invalid": fieldState.error,
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        const rawValue = e.target.value;
                                                                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                district: trimmedValue,
                                                                                            });
                                                                                        } else {
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                district: '', // Set to empty if invalid
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {getFormErrorMessage(field.name)}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col ">
                                                            <div className="">
                                                                <Controller
                                                                    name="state"
                                                                    control={control}
                                                                    rules={{ required: "state is required." }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <label className="ym-input-label ">
                                                                                State<b style={{ color: "red" }}>*</b>
                                                                            </label>

                                                                            <div className="flex flex-column">
                                                                                <InputText
                                                                                    maxLength={30}
                                                                                    id={field.name}
                                                                                    value={resObj.state}
                                                                                    className={classNames({
                                                                                        "p-invalid": fieldState.error,
                                                                                    })}
                                                                                    placeholder="Enter State"
                                                                                    onChange={(e) => {
                                                                                        const rawValue = e.target.value;
                                                                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                state: trimmedValue,
                                                                                            });
                                                                                        } else {
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                state: '', // Set to empty if invalid
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {getFormErrorMessage(field.name)}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col m-0">
                                                            <div className="">
                                                                <Controller
                                                                    name="country"
                                                                    control={control}
                                                                    rules={{ required: "country is required." }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <label className="ym-input-label ">
                                                                                Country<b style={{ color: "red" }}>*</b>
                                                                            </label>

                                                                            <div className="flex flex-column">
                                                                                <InputText
                                                                                    maxLength={30}
                                                                                    id={field.name}
                                                                                    value={resObj.country}
                                                                                    className={classNames({
                                                                                        "p-invalid": fieldState.error,
                                                                                    })}
                                                                                    placeholder="Entry Country"
                                                                                    onChange={(e) => {
                                                                                        const rawValue = e.target.value;
                                                                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                country: trimmedValue,
                                                                                            });
                                                                                        } else {
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                country: '', // Set to empty if invalid
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {getFormErrorMessage(field.name)}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col m-0">
                                                            <Controller
                                                                name="pincode"
                                                                control={control}
                                                                rules={{ required: "pincode  is required." }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <label className="ym-input-label">
                                                                            Pincode<b style={{ color: "red" }}>*</b>
                                                                        </label>
                                                                        <div className="flex flex-column">
                                                                            <InputText
                                                                                maxLength={6}
                                                                                placeholder="Enter Pincode"
                                                                                id={field.name}
                                                                                value={resObj.pincode}
                                                                                className={classNames({
                                                                                    "p-invalid": fieldState.error,
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    const rawValue = e.target.value;
                                                                                    // Ensure only numbers and up to 6 digits are allowed for pincode
                                                                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                    if (/^\d{0,6}$/.test(trimmedValue)) { // Validate pincode format
                                                                                        setResObj({
                                                                                            ...resObj,
                                                                                            pincode: trimmedValue,
                                                                                        });
                                                                                    } else {
                                                                                        setResObj({
                                                                                            ...resObj,
                                                                                            pincode: '', // Set to empty if invalid
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                keyfilter={/^[0-9]*$/}

                                                                            />
                                                                        </div>
                                                                        {getFormErrorMessage(field.name)}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                </ScrollPanel>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeStep === 1 && (
                                <>
                                    <div className="row m-0">
                                        <div className="col-12">
                                            <div className="child-left" id="FoodPerferences">
                                                <h4 className="rest-detail-head">Food Preference & Dining Details</h4>
                                                <hr></hr>
                                                <div className="">
                                                    <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                                                        <div className="row">
                                                            <label className="ym-input-label mt-2">
                                                                Preferences <b style={{ color: "red" }}>*</b>
                                                            </label>
                                                            <div className="d-flex align-items-center mt-1 gap-4">
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <RadioButton
                                                                            inputId="preference3"
                                                                            name="preferences"
                                                                            value="Pure-Veg"
                                                                            onChange={handlePreferenceChange}
                                                                            checked={(resObj.preference as string[]).includes("Pure-Veg")}
                                                                        />
                                                                        <label
                                                                            htmlFor="preference3"
                                                                            className={
                                                                                (resObj.preference as string[]).includes("Pure-Veg")
                                                                                    ? "pref-label-selected"
                                                                                    : "pref-label"
                                                                            }
                                                                        >
                                                                            Pure-Veg
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <RadioButton
                                                                            inputId="preference1"
                                                                            name="preferences"
                                                                            value="Veg & Non-Veg"
                                                                            onChange={handlePreferenceChange}
                                                                            checked={(resObj.preference as string[]).includes("Veg & Non-Veg")}
                                                                        />
                                                                        <label
                                                                            htmlFor="preference1"
                                                                            className={
                                                                                (resObj.preference as string[]).includes("Veg & Non-Veg")
                                                                                    ? "pref-label-selected"
                                                                                    : "pref-label"
                                                                            }
                                                                        >
                                                                            Veg & Non-Veg
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <RadioButton
                                                                            inputId="preference2"
                                                                            name="preferences"
                                                                            value="Non-Veg"
                                                                            onChange={handlePreferenceChange}
                                                                            checked={(resObj.preference as string[]).includes("Non-Veg")}
                                                                        />
                                                                        <label
                                                                            htmlFor="preference2"
                                                                            className={
                                                                                (resObj.preference as string[]).includes("Non-Veg")
                                                                                    ? "pref-label-selected"
                                                                                    : "pref-label"
                                                                            }
                                                                        >
                                                                            Non-Veg
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="ym-input-label mt-2">
                                                                Serving <b style={{ color: "red" }}>*</b>
                                                            </label>
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="service1"
                                                                            name="services"
                                                                            value="Ala Carte"
                                                                            onChange={Services}
                                                                            checked={(resObj.serving as string[]).includes("Ala Carte")}
                                                                        />
                                                                        <label htmlFor="service1" className="pref-label">
                                                                            Ala Carte
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="service2"
                                                                            name="services"
                                                                            value="Buffet"
                                                                            onChange={Services}
                                                                            checked={(resObj.serving as string[]).includes("Buffet")}
                                                                        />
                                                                        <label htmlFor="service2" className="pref-label">
                                                                            Buffet
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="service3"
                                                                            name="services"
                                                                            value="Meals"
                                                                            onChange={Services}
                                                                            checked={(resObj.serving as string[]).includes("Meals")}
                                                                        />
                                                                        <label htmlFor="service3" className="pref-label">
                                                                            Meals
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2" >
                                                                        <Checkbox
                                                                            inputId="service5"
                                                                            name="services"
                                                                            value="Thali"
                                                                            onChange={Services}
                                                                            checked={(resObj.serving as string[]).includes("Thali")}
                                                                        />
                                                                        <label htmlFor="service5" className="pref-label">
                                                                            Thali
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="ym-input-label mt-2">
                                                                Meal Of The Day <b style={{ color: "red" }}>*</b> {" "}
                                                            </label>
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="mod1"
                                                                            name="mods"
                                                                            value="Breakfast"
                                                                            onChange={MOD}
                                                                            checked={(resObj.mealoftheday as string[]).includes("Breakfast")}
                                                                        />
                                                                        <label htmlFor="mod1" className="pref-label">
                                                                            Breakfast
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="mod2"
                                                                            name="mods"
                                                                            value="Lunch"
                                                                            onChange={MOD}
                                                                            checked={(resObj.mealoftheday as string[]).includes("Lunch")}
                                                                        />
                                                                        <label htmlFor="mod2" className="pref-label">
                                                                            Lunch
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="mod3"
                                                                            name="mods"
                                                                            value="Dinner"
                                                                            onChange={MOD}
                                                                            checked={(resObj.mealoftheday as string[]).includes("Dinner")}
                                                                        />
                                                                        <label htmlFor="mod3" className="pref-label">
                                                                            Dinner
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="mod4"
                                                                            name="mods"
                                                                            value="Brunch"
                                                                            onChange={MOD}
                                                                            checked={(resObj.mealoftheday as string[]).includes("Brunch")}
                                                                        />
                                                                        <label htmlFor="mod4" className="pref-label">
                                                                            Brunch
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="mod5"
                                                                            name="mods"
                                                                            value="Snacks"
                                                                            onChange={MOD}
                                                                            checked={(resObj.mealoftheday as string[]).includes("Snacks")}
                                                                        />
                                                                        <label htmlFor="mod5" className="pref-label">
                                                                            Snacks
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="ym-input-label mt-2">
                                                                Eatery Type
                                                                <b style={{ color: "red" }}>*</b>
                                                            </label>
                                                            <div className="d-flex flex-wrap mt-1 gap-4">
                                                                {eaterytypelist && eaterytypelist.map((item, index) => (
                                                                    <div key={index} className="">
                                                                        <div className="flex align-items-center gap-2">
                                                                            <RadioButton
                                                                                inputId={`eaterytype${index}`}
                                                                                name="eaterytypes"
                                                                                value={item.name}
                                                                                onChange={handleEaterytypeChange}

                                                                                checked={resObj.eaterytype && resObj.eaterytype.some((type: ServiceOption) => type.name === item.name)}
                                                                            />
                                                                            <label
                                                                                htmlFor={`eaterytype${index}`}
                                                                                className={resObj.eaterytype && resObj.eaterytype.some((type: ServiceOption) => type.name === item.name)
                                                                                    ? "pref-label-selected"
                                                                                    : "pref-label"
                                                                                }
                                                                            >
                                                                                {item.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <Controller
                                                                name="specialitem"
                                                                control={control}
                                                                rules={{ required: "Special items are required." }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <div className="d-flex align-items-baseline">
                                                                            <label className="ym-input-label">
                                                                                Special Items
                                                                            </label>
                                                                            <i
                                                                                className="bi bi-info-circle ml-1"
                                                                                title="Enter the special items"
                                                                                id="icon"
                                                                            ></i>
                                                                        </div>
                                                                        <div className="flex flex-column">
                                                                            <InputTextarea
                                                                                maxLength={250}
                                                                                placeholder="Enter special items separated by commas"
                                                                                value={spitems}
                                                                                rows={5}
                                                                                cols={30}
                                                                                className={classNames({
                                                                                    "p-invalid": fieldState.error,
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    field.onChange(e);
                                                                                    SpecialItemss(e);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {getFormErrorMessage(field.name)}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="row">
                                                            <Controller
                                                                name="specialitem"
                                                                control={control}
                                                                rules={{ required: "special items is required." }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <div className="d-flex align-items-baseline">
                                                                            <label className="ym-input-label">
                                                                                Menu Items
                                                                            </label>
                                                                            <i
                                                                                className="bi bi-info-circle ml-1"
                                                                                title="Enter the special items"
                                                                                id="icon"
                                                                            ></i>
                                                                        </div>
                                                                        <div className="flex flex-column">
                                                                            <InputTextarea
                                                                                placeholder="Enter Menu items"
                                                                                value={resObj.menuitems.join(", ")}
                                                                                rows={5}
                                                                                cols={30}
                                                                                className={classNames({
                                                                                    "p-invalid": fieldState.error,
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    field.onChange(e);
                                                                                    const itemsString = e.target.value.trim();
                                                                                    const regex = /"(.*?)"/g;
                                                                                    const cleanedString = itemsString.match(regex) || [];
                                                                                    const items = cleanedString.map((item) => item.replace(/"/g, ''));
                                                                                    MenuItemss(items);

                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {getFormErrorMessage(field.name)}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>


                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="">
                                                                    <Controller
                                                                        name="average"
                                                                        control={control}
                                                                        rules={{
                                                                            required: "average cost is required.",
                                                                        }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <div className="d-flex align-items-baseline">
                                                                                    <label className="ym-input-label ">
                                                                                        Average cost (per 2 person)
                                                                                        <b style={{ color: "red" }}>*</b>
                                                                                    </label>
                                                                                    <i
                                                                                        className="bi bi-info-circle ml-1"
                                                                                        title="Please enter the average cost per 2 persons that can be spent in that restaurant"
                                                                                        id="icon"
                                                                                    ></i>
                                                                                </div>
                                                                                <div className="flex flex-column">
                                                                                    <InputText
                                                                                        maxLength={6}
                                                                                        id={field.name}
                                                                                        value={resObj.averagecost}
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                        placeholder="Enter Amount"
                                                                                        onChange={(e) => {
                                                                                            field.onChange(e);
                                                                                            setResObj({
                                                                                                ...resObj,
                                                                                                averagecost: e.target.value,
                                                                                            });
                                                                                        }}
                                                                                        keyfilter={/^[0-9]*$/}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="d-flex align-items-baseline gap-1">
                                                                    <label className="ym-input-label">
                                                                        Ambience
                                                                    </label>
                                                                    <i
                                                                        className="bi bi-info-circle ml-1"
                                                                        title="Please enter the average cost per 2 persons that can be spent in that restaurant"
                                                                        id="icon"
                                                                    ></i>
                                                                </div>
                                                                <div className="flex flex-column">
                                                                    <Dropdown
                                                                        placeholder="Select Ambience"
                                                                        options={ambiencelist}
                                                                        value={resObj.ambience}
                                                                        onChange={onAmbienceChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">

                                                            <div className="col">
                                                                <div className="">
                                                                    <Controller
                                                                        name="foodtype"
                                                                        control={control}
                                                                        rules={{ required: "foodtype is required." }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <div className="d-flex align-items-baseline gap-1">
                                                                                    <label className="ym-input-label ">
                                                                                        Food Type
                                                                                        <b style={{ color: "red" }}>*</b>
                                                                                    </label>
                                                                                    <i
                                                                                        className="bi bi-info-circle ml-1"
                                                                                        title="Select the food type for the restaurant"
                                                                                        id="icon"
                                                                                    ></i>
                                                                                </div>
                                                                                <div className="flex flex-column">
                                                                                    <MultiSelect
                                                                                        id="food-type"
                                                                                        value={resObj.foodtype.filter(
                                                                                            (eatery: eateryType[]) => eatery != null
                                                                                        )}
                                                                                        options={foodtypelist}
                                                                                        onChange={(
                                                                                            e: MultiSelectChangeEvent
                                                                                        ) => {
                                                                                            field.onChange(e.value);
                                                                                            onFoodTypeChange(e);
                                                                                        }}
                                                                                        optionLabel="name"
                                                                                        placeholder="Select"
                                                                                        display="chip"
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex align-items-baseline gap-1">
                                                                    <label className="ym-input-label">
                                                                        Diet Type
                                                                    </label>
                                                                    <i
                                                                        className="bi bi-info-circle ml-1"
                                                                        title="Select the diet type for the restaurant"
                                                                        id="icon"
                                                                    ></i>
                                                                </div>

                                                                <div className="flex flex-column">
                                                                    <MultiSelect
                                                                        value={resObj.diettype.filter(
                                                                            (eatery: eateryType[]) => eatery != null
                                                                        )}
                                                                        options={diettypelist}
                                                                        onChange={onDietChange}
                                                                        optionLabel="name"
                                                                        placeholder="Select "
                                                                        display="chip"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="d-flex align-items-baseline gap-1">
                                                                    <label className="ym-input-label">Values <b style={{ color: "red" }}>*</b></label>
                                                                    <i
                                                                        className="bi bi-info-circle ml-1"
                                                                        title="Select values for the restaurant"
                                                                        id="icon"
                                                                    ></i>
                                                                </div>
                                                                <div className="flex flex-column">
                                                                    <MultiSelect
                                                                        value={resObj.values.filter(
                                                                            (value: valuess[]) => value != null
                                                                        )}
                                                                        options={valueslist}
                                                                        onChange={onValueChange}
                                                                        optionLabel="name"
                                                                        placeholder="Select"
                                                                        display="chip"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="d-flex align-items-baseline gap-1">
                                                                    <label className="ym-input-label">Cuisine</label>
                                                                    <i
                                                                        className="bi bi-info-circle ml-1"
                                                                        title="Select the cuisine available in the restaurant"
                                                                        id="icon"
                                                                    ></i>
                                                                </div>
                                                                <div className="flex flex-column gap-2">
                                                                    <MultiSelect
                                                                        value={resObj.cuisine}
                                                                        options={cuisinelist}
                                                                        onChange={(e: MultiSelectChangeEvent) => {
                                                                            console.log("{restObj.eaterytype", resObj.eaterytype);
                                                                            setResObj({ ...resObj, cuisine: e.value });
                                                                        }}
                                                                        optionLabel="name"
                                                                        placeholder="Select Cuisines..."
                                                                        display="chip"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="d-flex align-items-baseline gap-1">
                                                                    <label className="ym-input-label">
                                                                        Service Options <b style={{ color: "red" }}>*</b>
                                                                    </label>
                                                                    <i
                                                                        className="bi bi-info-circle ml-1"
                                                                        title="Select the diet type for the restaurant"
                                                                        id="icon"
                                                                    ></i>
                                                                </div>

                                                                <div className="flex flex-column">
                                                                    <MultiSelect
                                                                        value={(resObj.serviceoptions.map((option: any, index: any) => ({
                                                                            name: option,
                                                                            code: option.toUpperCase().substring(0, 3),
                                                                        })))}
                                                                        options={serviceoptions}
                                                                        onChange={OnServiceOptionschange}
                                                                        optionLabel="name"
                                                                        placeholder="Select "
                                                                        display="chip"
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </ScrollPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeStep === 2 && (
                                <>
                                    <div className="row ">
                                        <div className="col-12">
                                            <div className="child-lef t" id="Timings">
                                                <h4 className="rest-detail-head">Timings</h4>
                                                <hr></hr>
                                                <label className="ym-input-label mb-2">
                                                    Timings
                                                </label>

                                                <div className="">
                                                    <div className="row">
                                                        <div className="row">
                                                            {daysOfWeek.map((day: string) => (
                                                                <div className="col-lg-3" key={day}>
                                                                    <div className="d-flex align-items-center">
                                                                        <Checkbox
                                                                            inputId={`day${day}`}
                                                                            name="day"
                                                                            value={day}
                                                                            checked={checkedDays[day]}
                                                                            onChange={() => handleCheckboxChange(day)}
                                                                        />
                                                                        <label htmlFor={`day${day}`} className="ml-1">
                                                                            {day}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                                            <div className="from d-flex flex-column col-lg-3">
                                                                <span className="restaurant-name">From</span>
                                                                <Dropdown
                                                                    style={{
                                                                        width: "100%",
                                                                        backgroundColor: "white",
                                                                    }}
                                                                    id="eater"
                                                                    placeholder="Select"
                                                                    value={fromtimes}
                                                                    options={Timings}
                                                                    onChange={onFromTimeChange}
                                                                />
                                                            </div>
                                                            <div className="from d-flex flex-column col-3">
                                                                <span className="restaurant-name">To</span>
                                                                <Dropdown
                                                                    style={{
                                                                        width: "100%",
                                                                        backgroundColor: "white",
                                                                    }}
                                                                    id="eater"
                                                                    placeholder="Select"
                                                                    value={totimes}
                                                                    options={Timings}
                                                                    onChange={onToTimeChange}
                                                                />
                                                            </div>
                                                            <div className="mt-3">
                                                                <button
                                                                    className="add-button"
                                                                    onClick={handleAddTiming}
                                                                >
                                                                    Add
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex justify-content-start align-items-center mt-3">
                                                            <div className="col-8">
                                                                {Object.entries(selectedTimings).map(
                                                                    ([day, timing]) => (
                                                                        <div
                                                                            className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                                                            key={day}
                                                                        >
                                                                            <span className="day">{day}</span>
                                                                            <div className="row">
                                                                                <div className="col gap-1">
                                                                                    {timing.map((time, index) => (
                                                                                        <div className="col p-0 mt-1 gap-1">
                                                                                            <span className="time" key={index}>
                                                                                                {time}
                                                                                            </span>

                                                                                            <i
                                                                                                className="bi bi-trash-fill"
                                                                                                style={{
                                                                                                    color: "#818181",
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    const updatedTimings = {
                                                                                                        ...selectedTimings,
                                                                                                    };
                                                                                                    updatedTimings[day] =
                                                                                                        updatedTimings[day].filter(
                                                                                                            (t) => t !== time
                                                                                                        );

                                                                                                    // Check if there are no times left, delete the entire day
                                                                                                    if (
                                                                                                        updatedTimings[day].length === 0
                                                                                                    ) {
                                                                                                        delete updatedTimings[day];
                                                                                                    }

                                                                                                    setSelectedTimings(updatedTimings);
                                                                                                }}
                                                                                            ></i>
                                                                                            <br />
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* <div className="row d-flex justify-content-start align-items-center mt-3">
                                                            <RestaurantTimings
                                                                timing={resObj.timings}
                                                                editable={true}
                                                            />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeStep === 3 && (
                                <>
                                    <div className="row m-0">
                                        <div className="col-12">
                                            <div className="child-left" id="uploadimages">
                                                <h4 className="rest-detail-head">Upload Images</h4>
                                                <hr></hr>
                                                <div className="">
                                                    <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                                                        <div className="row m-0">
                                                            <div className="col-12">
                                                                <div className="child-left" id="uploadimages">
                                                                    <div className="">
                                                                        <div className="">
                                                                            <label className="ym-input-label">
                                                                                Thumbnail Images  <b style={{ color: "red" }}>*</b> <span style={{ fontSize: "12px" }}>(Add a min 1 or a max 5 Thumbnail Images)</span>

                                                                            </label>
                                                                            <DropFileInput
                                                                                uploadedFiles={thumbnailImages}
                                                                                setUploadedFiles={setThumbnailImages}
                                                                                resObj={resObj}
                                                                                setResObj={setResObj}
                                                                                purpose="ThumbnailImages"
                                                                            />
                                                                        </div>

                                                                        <div className="grid mt-2 ml-1 gap-3">
                                                                            {Array.isArray(resObj.thumbnails) &&
                                                                                resObj.thumbnails.every(
                                                                                    (item: any) => typeof item === "string"
                                                                                ) &&
                                                                                resObj.thumbnails.map((item: any, index: any) => {
                                                                                    return (
                                                                                        <div
                                                                                            id="Imagecrd"
                                                                                            style={{ position: "relative" }}
                                                                                            key={index}
                                                                                        >
                                                                                            <img
                                                                                                className="uploadimage fefe"
                                                                                                key={item}
                                                                                                src={item + "?" + sas}
                                                                                                alt=""
                                                                                            />

                                                                                            <i
                                                                                                className="pi pi-trash icontrash"
                                                                                                onClick={() =>
                                                                                                    handleDeleteClick(index, "ThumbnailImages")
                                                                                                }
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    top: "0",
                                                                                                    right: "0",
                                                                                                    margin: "8px",
                                                                                                }}
                                                                                            ></i>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </div>


                                                                        <div className="">
                                                                            <label className="ym-input-label">
                                                                                Restaurant Images
                                                                                <b style={{ color: "red" }}>*</b>
                                                                            </label>
                                                                            <DropFileInput
                                                                                uploadedFiles={restaurantImages}
                                                                                setUploadedFiles={setRestantImages}
                                                                                resObj={resObj}
                                                                                setResObj={setResObj}
                                                                                purpose="RestaurantImages"
                                                                            />
                                                                        </div>
                                                                        <div className="grid mt-2 ml-1 gap-3">
                                                                            {Array.isArray(resObj.images) &&
                                                                                resObj.images.every(
                                                                                    (item: any) => typeof item === "string"
                                                                                ) &&
                                                                                resObj.images.map((item: any, index: any) => {
                                                                                    // const imageUrl = URL.createObjectURL(item);

                                                                                    return (
                                                                                        <div
                                                                                            id="Imagecrd"
                                                                                            style={{ position: "relative" }}
                                                                                            key={index}
                                                                                        >
                                                                                            <img
                                                                                                className="uploadimage"
                                                                                                key={item.id}
                                                                                                src={item + "?" + sas}
                                                                                                alt=""
                                                                                            />

                                                                                            <i
                                                                                                className="pi pi-trash icontrash"
                                                                                                onClick={() =>
                                                                                                    handleDeleteClick(index, "RestaurantImages")
                                                                                                }
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    top: "0",
                                                                                                    right: "0",
                                                                                                    margin: "8px",
                                                                                                }}
                                                                                            ></i>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </div>
                                                                        <br></br>
                                                                        <div className="">
                                                                            <label className="ym-input-label">Menu Images</label>
                                                                            <DropFileInput
                                                                                uploadedFiles={menuImages}
                                                                                setUploadedFiles={setMenuImages}
                                                                                resObj={resObj}
                                                                                setResObj={setResObj}
                                                                                purpose="MenuImages"
                                                                            />
                                                                        </div>

                                                                        <div className="grid mt-2 ml-1 gap-3">
                                                                            {Array.isArray(resObj.menuimages) &&
                                                                                resObj.menuimages.every(
                                                                                    (item: any) => typeof item === "string"
                                                                                ) &&
                                                                                resObj.menuimages.map((item: any, index: any) => {
                                                                                    // const imageUrl = URL.createObjectURL(item);

                                                                                    return (
                                                                                        <div
                                                                                            id="Imagecrd"
                                                                                            style={{ position: "relative" }}
                                                                                            key={index}
                                                                                        >
                                                                                            <img
                                                                                                className="uploadimage"
                                                                                                key={item.id}
                                                                                                src={item + "?" + sas}
                                                                                                alt=""
                                                                                            />

                                                                                            <i
                                                                                                className="pi pi-trash icontrash"
                                                                                                onClick={() =>
                                                                                                    handleDeleteClick(index, "MenuImages")
                                                                                                }
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    top: "0",
                                                                                                    right: "0",
                                                                                                    margin: "8px",
                                                                                                }}
                                                                                            ></i>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ScrollPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeStep === 4 && (
                                <>
                                    <div className="row m-0">
                                        <div className="col-12">
                                            <div className="child-left" id="AdditionalInformation">
                                                <h4 className="rest-detail-head">Additional Information</h4>
                                                <hr></hr>
                                                <div className="">
                                                    <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                                                        <div>
                                                            <label className="ym-input-label mt-2">
                                                                Features and Facilities
                                                            </label>

                                                            <div className="d-flex flex-wrap gap-4">
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="feature1"
                                                                            name="features"
                                                                            value="Lift"
                                                                            onChange={Features}
                                                                            checked={(resObj.features as string[]).includes("Lift")}
                                                                        />
                                                                        <label htmlFor="feature1" className="pref-label">
                                                                            Lift
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="feature2"
                                                                            name="features"
                                                                            value="Air Conditioned"
                                                                            onChange={Features}
                                                                            checked={(resObj.features as string[]).includes("Air Conditioned")}
                                                                        />
                                                                        <label htmlFor="feature2" className="pref-label">
                                                                            Air Conditioned
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="feature4"
                                                                            name="features"
                                                                            value="Parking"
                                                                            onChange={Features}
                                                                            checked={(resObj.features as string[]).includes("Parking")}
                                                                        />
                                                                        <label htmlFor="feature4" className="pref-label">
                                                                            Parking
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="feature5"
                                                                            name="features"
                                                                            value="Wifi"
                                                                            onChange={Features}
                                                                            checked={(resObj.features as string[]).includes("Wifi")}
                                                                        />
                                                                        <label htmlFor="feature5" className="pref-label">
                                                                            Wifi
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="feature6"
                                                                            name="features"
                                                                            value="Valet Parking"
                                                                            onChange={Features}
                                                                            checked={(resObj.features as string[]).includes("Valet Parking")}
                                                                        />
                                                                        <label htmlFor="feature6" className="pref-label">
                                                                            Valet Parking
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="feature7"
                                                                            name="features"
                                                                            value="Card Accepted"
                                                                            onChange={Features}
                                                                            checked={(resObj.features as string[]).includes("Card Accepted")}
                                                                        />
                                                                        <label htmlFor="feature7" className="pref-label">
                                                                            Card Accepted
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-3" >
                                                            <Controller
                                                                name="aboutrestaurant"
                                                                control={control}
                                                                rules={{
                                                                    required: "About restaurant is required.",
                                                                }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <div className="d-flex align-items-baseline gap-1">
                                                                            <label className="ym-input-label">
                                                                                About Restaurant{" "}
                                                                                <b style={{ color: "red" }}>*</b>
                                                                            </label>
                                                                            <i
                                                                                className="bi bi-info-circle ml-1"
                                                                                title="Enter About Restaurant"
                                                                                id="icon"
                                                                            ></i>
                                                                            <i></i>
                                                                        </div>
                                                                        <div className="flex flex-column">
                                                                            <InputTextarea
                                                                                maxLength={1500}
                                                                                value={resObj.about.join("")}
                                                                                placeholder="Enter About Restaurant"
                                                                                onChange={(e) => {
                                                                                    field.onChange(e);
                                                                                    About(e);
                                                                                }}
                                                                                rows={5}
                                                                                cols={30}
                                                                                className={classNames({
                                                                                    "p-invalid": fieldState.error,
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        {getFormErrorMessage(field.name)}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* why yeldam */}
                                                        <div className="row">
                                                            <Controller
                                                                name="whyyeldam"
                                                                control={control}
                                                                rules={{ required: "Why yeldam  is required." }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <label className="ym-input-label">
                                                                            Why in YELDAM{" "}
                                                                            <b style={{ color: "red" }}>*</b>
                                                                        </label>
                                                                        <div className="flex flex-column">
                                                                            <InputTextarea
                                                                                maxLength={1500}
                                                                                placeholder="Why Did You Choose This Restaurant?..."
                                                                                value={resObj.whyinyeldam.join("")}
                                                                                onChange={(e) => {
                                                                                    field.onChange(e);
                                                                                    WhyinYeldam(e);
                                                                                }}
                                                                                rows={5}
                                                                                cols={30}
                                                                                className={classNames({
                                                                                    "p-invalid": fieldState.error,
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        {getFormErrorMessage(field.name)}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* yeldam Ratings */}
                                                        {/* <div className="row Yeldam-ratings">
                                                            <label className="ym-input-label">
                                                                Yeldam Ratings{" "}

                                                            </label>
                                                            <div className="hygine-rating-div">
                                                                <span className="rating-category">Hygiene</span>
                                                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                                                    {[...Array(10)].map((_, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => handleHygieneRatingClick(index + 1)}
                                                                            className="box-number"
                                                                            style={{ backgroundColor: hygieneboxNumberColors[index] }}

                                                                        >
                                                                            {index + 1}
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                            <div className="hygine-rating-div">
                                                                <span className="rating-category">Health</span>
                                                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                                                    {[...Array(10)].map((_, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => handleHealthRatingClick(index + 1)}
                                                                            className="box-number"
                                                                            style={{ backgroundColor: boxNumberColors[index] }}

                                                                        >
                                                                            {index + 1}
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                            <div className="hygine-rating-div">
                                                                <span className="rating-category">Taste</span>
                                                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                                                    {[...Array(10)].map((_, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => handleTasteRatingClick(index + 1)}
                                                                            className="box-number"
                                                                            style={{ backgroundColor: tasteboxNumberColors[index] }}
                                                                        >
                                                                            {index + 1}
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                            <div className="hygine-rating-div">
                                                                <span className="rating-category">Cost Effective</span>
                                                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                                                    {[...Array(10)].map((_, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => handleCostEffectiveRatingClick(index + 1)}
                                                                            className="box-number"
                                                                            style={{ backgroundColor: costboxNumberColors[index] }}
                                                                        >
                                                                            {index + 1}
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                            <div className="hygine-rating-div">
                                                                <span className="rating-category">Values</span>
                                                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                                                    {[...Array(10)].map((_, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => handleValueRatingClick(index + 1)}
                                                                            className="box-number"
                                                                            style={{ backgroundColor: valueboxNumberColors[index] }}
                                                                        >
                                                                            {index + 1}
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                            <div className="hygine-rating-div">
                                                                <span className="rating-category">Hospitality</span>
                                                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                                                    {[...Array(10)].map((_, index) => (
                                                                        <span
                                                                            key={index}
                                                                            onClick={() => handleHospitalityRatingClick(index + 1)}
                                                                            className="box-number"
                                                                            style={{ backgroundColor: hospitalityboxNumberColors[index] }}
                                                                        >
                                                                            {index + 1}
                                                                        </span>
                                                                    ))}
                                                                </div>

                                                            </div>

                                                        </div> */}
                                                    </ScrollPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                        <div className="card-footer button-container">
                            {activeStep === 0 ? (
                                <>
                                    <div className="d-flex justify-content-end">
                                        <div className="row">
                                            <Button
                                                className="next"
                                                label="Next"
                                                type="submit"
                                                // icon="pi pi-check"
                                                onClick={
                                                    handleNext
                                                    //handleSubmit(onSubmit)
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="ym-add-button-previous"
                                        label="Back"
                                        onClick={handleBack}
                                    ></Button>

                                    {activeStep === 4 ? (
                                        <Button
                                            className="p-button-success ym-add-button"
                                            label="Save Changes"
                                            onClick={handleEditRestaurant}
                                        // disabled={currentIndex === 3}
                                        ></Button>
                                    ) : (
                                        // <Button
                                        //   className=" ym-add-button-next"
                                        //   label="Next"
                                        //   onClick={()=>{handleNext();
                                        //     handleSubmit(onSubmit)}}
                                        // // disabled={currentIndex === 3}
                                        // ></Button>
                                        <Button
                                            className="next"
                                            label="Next"
                                            type="submit"
                                            // icon="pi pi-check"
                                            onClick={
                                                handleNext
                                                //handleSubmit(onSubmit)
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default EditsRestaurant;
