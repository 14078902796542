import React, { useEffect, useState } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from 'primereact/button';
import { useNavigate } from "react-router-dom";
import {GetSuggestedStoresTableData} from "../../api/userSuggested.api"
import { getRefreshedToken, isTokenExpired } from '../../api/auth.api';


var width = window.screen.width;
var c_rows = 10;
if (width >= 1920) {
    c_rows = 15;
} else if (width >= 1400 || width <= 1600) {
    c_rows = 10;
}

const SuggestedStoreTable: React.FC<any> = ({}) => {
    
    const [loading, setLoading] = useState(false);
    const [totalstoreRecords, setTotalstoreRecords] = useState(0);
    const [customers, setCustomers] = useState(undefined);
    const navigate = useNavigate();

    const [lazyParams, setLazyParams] = useState({
        approvalstatus:1,
        first: 0,
        rows: c_rows,
        page: 0,
        sortField: '',
        sortOrder: 0,
        filters: {
            'storename': { value: '', matchMode: 'contains' },
            'city': { value: '', matchMode: 'contains' },
            
        },
      });
      let loadLazyTimeout: any = 0;

      useEffect(() => {
        loadLazyData();
      }, [lazyParams]); 

      const loadLazyData = () => {
        setLoading(true);
    
        if (loadLazyTimeout) {
          clearTimeout(loadLazyTimeout);
        }
    
        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(async () => {
          if (isTokenExpired()) {
            console.log("inside");
            await getRefreshedToken();
          } else {
            const respObj = await GetSuggestedStoresTableData(lazyParams, 1);
            console.log("Inside pending", respObj);
            setCustomers(respObj.data.data.stores);
            setTotalstoreRecords(respObj.data.data.totalRecords);
            setLoading(false);
          }
        }, 100);
      };

     

      const onPage = (event: any) => {
        setLazyParams(event);
      };
    
      const onSort = (event: any) => {
        setLazyParams(event);
      };
    
      const onFilter = (event: any) => {
        event["first"] = 0;
        setLazyParams(event);
      };

    const viewProduct = (product: any) => {
        console.log("first id ", product._id);
        navigate(`/usersuggested/storedetails/${product._id}`);
    };
    const actionBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-eye"
                    label="View Details"
                    className="p-button-rounded mr-2 view-bt p-dt-tooltip"
                    onClick={() =>
                        viewProduct(rowData)
                    }
                />
            </React.Fragment>
        );
    };

    const StatusBodyTemplate = () =>{
      return(
        <div className='pending-user'> 
          pending
        </div>
      );
    };


    return (
        <div className='suggested-table'>
            <DataTable
                paginator={totalstoreRecords >=10}
                rows={10}
                value={customers}
                lazy
                first={lazyParams.first}
                totalRecords={totalstoreRecords}
                loading={loading}
                onPage={onPage}
                onSort={onSort}
                onFilter={onFilter}
                sortField={lazyParams.sortField}
                sortOrder={0}
            >
                <Column
                    className='tab-head'
                    field="storename"
                    header="Organic Store Name"
                />
                <Column
                    className='tab-head'
                    field="city"
                    header="City"
                />
                <Column
                    className='tab-head'
                    field="storetype"
                    header="Store Type"
                />
                <Column
                    className='tab-head'
                    field="status"
                    header="Status"
                    body={StatusBodyTemplate}
                />
                <Column
                    header="Actions"
                    className="action_btn tab-head"
                    exportable={false}
                    body={actionBodyTemplate}
                    style={{ minWidth: "8rem" }}
                ></Column>
            </DataTable>
        </div>
    );
}

export default SuggestedStoreTable;
