import React, { useState } from 'react';
import "./detailsinfo.css";

interface props {
    registereddietitian: string;
    languages: string[];
    services: string[];
    specialities: any[];
    about: string;
    dietitianinstagram: string;
    dietitiantwitter: string;
    dietitianyoutube: string;
    dietitianfacebook: string;
    dietitianwebsite: string;
    idamembershipnumber: string;
    experience: string;
    fee: string;
    mode: string[];
    phone: string;
    email: string;
}

const DietitianInfo: React.FC<props> = ({
    registereddietitian,
    languages,
    services,
    specialities,
    about,
    dietitianinstagram,
    dietitiantwitter,
    dietitianyoutube,
    dietitianfacebook,
    dietitianwebsite,
    idamembershipnumber,
    experience,
    fee,
    mode,
    phone,
    email
}) => {
    const [lvisible, setLvisible] = useState(false);
    return (
        <div>
            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Phone Number</h6>
                    <p className='sub-detail-data'>{phone ? phone : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Email</h6>
                    <p className='sub-detail-data'>{email ? email : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Instagram</h6>
                    <p className='sub-detail-data'>{dietitianinstagram ? dietitianinstagram : "--"}</p>
                </div>
            </div>
            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Youtube</h6>
                    <p className='sub-detail-data'>{dietitianyoutube ? dietitianyoutube : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Facebook</h6>
                    <p className='sub-detail-data'>{dietitianfacebook ? dietitianfacebook : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">X</h6>
                    <p className='sub-detail-data'>{dietitiantwitter ? dietitiantwitter : "--"}</p>
                </div>
            </div>
            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Experience</h6>
                    <p className='sub-detail-data'>{experience ? experience : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Mode</h6>
                    <p className='sub-detail-data'>{mode.length > 0 ? mode.join(" & ") : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Fees</h6>
                    <p className='sub-detail-data'>{fee ? fee : "--"}</p>
                </div>
            </div>

            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Registered Dietitian</h6>
                    <p className='sub-detail-data'>{registereddietitian ? registereddietitian : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">IDA Membership Number</h6>
                    <p className='sub-detail-data'>{idamembershipnumber ? idamembershipnumber : "--"}</p>
                </div>

                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Languages</h6>
                    <p className='sub-detail-data'>{languages.length > 0 ? languages.join(", ") : "--"}</p>
                </div>
            </div>

            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Website</h6>
                    <p className='sub-detail-data'>{dietitianwebsite ? dietitianwebsite : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Instagram</h6>
                    <p className='sub-detail-data'>{dietitianinstagram ? dietitianinstagram : "--"}</p>
                </div>
                <div className='col-4 pt-0'>
                    <h6 className="mb-1 sub-detail-head">Specialities</h6>
                    <p className='sub-detail-data'>{specialities.length > 0 ? specialities.join(", ") : "--"}</p>
                </div>

            </div>


            <div className='d-flex'>
                <div className='col-4 pt-0'>
                    <h6 className="mb-1 sub-detail-head">Services</h6>
                    <p className='sub-detail-data'>{services.length > 0 ? services.join(", ") : "--"}</p>
                </div>
                <div className='col-6 pt-0'>
                    <h6 className="sub-detail-head">About</h6>
                    <p className='sub-detail-data'>{about ? about : "--"}</p>
                </div>

            </div>

        </div>
    );
}

export default DietitianInfo;
