import React, { useEffect, useState } from 'react';
import "./suggestedStoreDetails.pages.css";
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { TabPanel, TabView } from 'primereact/tabview';
import Loader from '../../../components/ui/loader/loader.components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Image } from "primereact/image";
import { sas } from "../../../config/constants"
import { GetUserSuggestedStorebyID } from '../../../api/userSuggested.api';

const SuggestedStoreDetails = () => {
  const [isAdding, setIsadding] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  const splitLocation = pathname.split

  const [restObj, setRestObj] = useState({
    _id: "",
    storename: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    mobileno: "",
    images: [],
    firstname: "",
    lastname: "",
    gender: "",
    phoneno: "",
    email: ""

  });

  const loadLazyData = async () => {
    try {
      // setIsadding(true);

      const storeData = await GetUserSuggestedStorebyID(id as string);
      console.log("suggested store Data", storeData.data.data);
      setRestObj(storeData.data.data);
    } catch (error) {
      console.error("Error loading data:", error);
      // setIsadding(false);
    }
  };

  useEffect(() => {
    // setIsadding(true);
    loadLazyData();
  }, [id]);

  return (
    <main>
      {!isAdding ? "" : <Loader />}
      <div className="">
        <Navbar />
        <section className="page-content">
          <div className="p-2 d-flex">
            &nbsp;
            <span
              className="back_rest_btn d-flex gap-2 align-items-center"
              onClick={() =>
                navigate(`/usersuggested`)
              }
            >
              <i className="pi pi-arrow-left"></i>
              Organic Store Details{" "}
            </span>
          </div>
          <hr className='mb-0'/>

          <div className='suggested-rest-details p-3'>
            <div className=''>
              <p className='suggested-rest-name'>{restObj.storename}</p>
            </div>
            <div className=''>
              <p className='suggested-address-head'>
                <i className="pi pi-map-marker mr-1"></i>
                Address
              </p>
              <p className='suggested-rest-address'>
                {restObj.address +
                  "," +
                  restObj.city +
                  "," +
                  " " +
                  restObj.state +
                  "-" +
                  restObj.pincode +
                  "."}
              </p>
            </div>
            <div className=''>
              <p className='suggested-phone-head'>
                <i className="pi pi-phone mr-1"></i>
                Phone
              </p>
              <p className='suggested-phone-number'>
                {restObj.mobileno ? restObj.mobileno : "--"}
              </p>
            </div>

          </div>

          <div className='rest-tab-suggested'>
            <TabView>
              <TabPanel header="Images">
                <div className="image-gallery">
                  {restObj.images.map((image, index) => (
                    <Image className='ml-2' key={index} src={image + "?" + sas} alt={`Image ${index}`} preview />
                  ))}
                </div>
              </TabPanel>
              <TabPanel header="User Details">
                <div className='d-flex justify-content-between'>
                  <div className='userdetail'>
                    <p className='userdetails-head'>First Name</p>
                    <p className='userdetail-value'>{restObj.firstname ? restObj.firstname : "--"}</p>
                  </div>
                  <div className='userdetail'>
                    <p className='userdetails-head'>Last Name</p>
                    <p className='userdetail-value'>{restObj.lastname ? restObj.lastname : "--"}</p>
                  </div>
                  <div className='userdetail'>
                    <p className='userdetails-head'>Gender</p>
                    <p className='userdetail-value'>{restObj.gender ? restObj.gender : "--"}</p>
                  </div>
                  <div className='userdetail'>
                    <p className='userdetails-head'>Phone Number</p>
                    <p className='userdetail-value'>{restObj.phoneno ? restObj.phoneno : "--"}</p>
                  </div>
                  <div className='userdetail'>
                    <p className='userdetails-head'>Email</p>
                    <p className='userdetail-value'>{restObj.email ? restObj.email : "--"}</p>
                  </div>
                  <div className='userdetail'>
                    <p className='userdetails-head'>City</p>
                    <p className='userdetail-value'>{restObj.city ? restObj.city : "--"}</p>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </section>
      </div>
    </main>
  );
}

export default SuggestedStoreDetails;
