import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";

type props = {
  customers: any,
  first: any,
  sortField: any,
  sortOrder: any,
  totalRecords: any,
  onPage: any,
  onSort: any,
  onFilter: any,
  filters: any,
  loading: any,
  actionBodyTemplate: any
};

// Function to calculate the number of rows based on screen width
const rowsNo = () => {
  const width = window.screen.width;
  console.log("width" + width);
  if (width >= 1920) {
    return 13;
  } else if (width > 1420 && width <= 1440) {
    return 9;
  } else {
    return 10;
  }
};

// Function to calculate equal width for columns
const calculateColumnWidth = (numberOfColumns: number) => `${100 / numberOfColumns}%`;

const ApprovedDietitiansTable: React.FC<props> = ({
  customers,
  first,
  sortField,
  sortOrder,
  totalRecords,
  onPage,
  onSort,
  onFilter,
  filters,
  loading,
  actionBodyTemplate,
}) => {
  const numberOfColumns = 4; // Adjust this based on the actual number of columns
  const columnWidth = calculateColumnWidth(numberOfColumns); // Calculate width for each column

  return (
    <DataTable
      value={customers}
      lazy
      filterDisplay="row"
      responsiveLayout="scroll"
      paginator={totalRecords >= 10}
      first={first}
      rows={10}
      totalRecords={totalRecords}
      onPage={onPage}
      onSort={onSort}
      sortField={sortField}
      sortOrder={sortOrder}
      onFilter={onFilter}
      filters={filters}
      loading={loading}
    >
      <Column
        field="dietitianname"
        header="Dietitian Name"
        sortable
        style={{ width: columnWidth }} // Apply equal width
      />
      <Column
        field="city"
        header="City"
        sortable
        style={{ width: columnWidth }} // Apply equal width
      />
      <Column
        field="profession"
        header="Profession"
        sortable
        body={(rowData) => {
          // Check if the profession is an array
          if (Array.isArray(rowData.profession) && rowData.profession.length > 1) {
            // Join the profession values with a slash
            return rowData.profession.join(' / ');
          }
          // Return the single profession value
          return rowData.profession;
        }}
        style={{ width: columnWidth }} // Apply equal width
      />
      <Column
        header="Action"
        className="action_btn"
        body={actionBodyTemplate}
        exportable={false}
        style={{ minWidth: '6rem', width: columnWidth }} // Apply equal width
      />
    </DataTable>
  );
}

export default ApprovedDietitiansTable;
