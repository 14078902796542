import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
// import "./addOrganicStore.component.css";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { AutoComplete } from "primereact/autocomplete";
import DropFileInput from "../../pages/restaurants/drop-file-input/drop-file-input";
import { Toast } from "primereact/toast";
import { getRefreshedToken, isTokenExpired } from "../../api/auth.api";
import { SubmitOrganicStore } from "../../api/addRestarant.api";
import Loader from "../ui/loader/loader.components";
import { EditStoreDetails } from "../../api/editOrganicstore.api";
import { GetStorebyID } from "../../api/pendingTable.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { sas } from "../../config/constants";
interface orgaicStepper {
    steps: string[];
    images: string[];
    stepdesc: string[];
}

type resObj = {
    // lat: any;
    storename: string;
    address: string;
    // address2: string;
    city: string;
    state: string;
    latitude: string;
    longitude: string;
    locality: string;
    country: string;
    pincode: string;
    website: string;
    email: string;
    phoneno: string;
    servicetype: string[];
    serviceoptions: string;
    timings: any[];
    district: string;
    thumbnails: any[];
    storeimages: any[];
    storesize: any[];
    storetype: string[];
    location: {
        coordinates: number[];
    };
    _id: string;
    facebookurl: string;
    instaurl: string;
    youtubeurl: string;
    twitterurl: string;
};
const initialState = {
    _id: "",
    storename: "",
    address: "",
    city: "",
    state: "",
    latitude: "",
    longitude: "",
    locality: "",
    country: "",
    pincode: "",
    website: "",
    email: "",
    phoneno: "",
    servicetype: [],
    serviceoptions: "",
    timings: [],
    district: "",
    storeimages: [],
    thumbnails: [],
    storesize: [],
    storetype: [],
    location: {
        coordinates: [0, 0],
    },
    facebookurl: "",
    instaurl: "",
    youtubeurl: "",
    twitterurl: "",
};

const EditStoreStepper: React.FC<orgaicStepper> = ({
    steps,
    images,
    stepdesc,
}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [servicestype, setServicestype] = useState<string[]>([]);
    const [completedSteps, setCompletedSteps] = useState(Array(steps.length).fill(false));
    const [totimes, settotimes] = useState();
    const [fromtimes, setfromtimes] = useState();
    const [storeSize, setStoreSize] = useState(null);
    const [storeThumbnailImages, setStoreThumbnailImages] = useState<any[]>([]);
    const [storeImages, setStoreImages] = useState<any[]>([]);
    const [stepColors, setStepColors] = useState(Array(steps.length).fill(""));
    const [isAdding, setIsadding] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { pathname } = location;

    const toast = useRef<Toast>(null);
    const show = (message: any, severity: any, summary: any) => {
        if (toast.current) {
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };

    const Timings = [
        "00:00am",
        "00:30am",
        "01:00am",
        "01:30am",
        "02:00am",
        "02:30am",
        "03:00am",
        "03:30am",
        "04:00am",
        "04:30am",
        "05:00am",
        "05:30am",
        "06:00am",
        "06:30am",
        "07:00am",
        "07:30am",
        "08:00am",
        "08:30am",
        "09:00am",
        "09:30am",
        "10:00am",
        "10:30am",
        "11:00am",
        "11:30am",
        "12:00pm",
        "12:30pm",
        "01:00pm",
        "01:30pm",
        "02:00pm",
        "02:30pm",
        "03:00pm",
        "03:30pm",
        "04:00pm",
        "04:30pm",
        "05:00pm",
        "05:30pm",
        "06:00pm",
        "06:30pm",
        "07:00pm",
        "07:30pm",
        "08:00pm",
        "08:30pm",
        "09:00pm",
        "09:30pm",
        "10:00pm",
        "10:30pm",
        "11:00pm",
        "11:30pm",
        "11:59pm",
        "12:00am",
    ];
    const daysOfWeek: string[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    const defaultValues = {
        restaurantname: "",
        latitude: "",
        longitude: "",
        address: "",
        city: "",
        district: "",
        state: "",
        country: "",
        pincode: "",
        specialitem: "",
        average: "",
        eaterytype: "",
        foodtype: "",
        ambience: "",
        aboutrestaurant: "",
        whyyeldam: "",
    };
    const {
        control,
        formState: { errors },
        handleSubmit,
        // getValues,
        reset,
    } = useForm({ defaultValues });

    const storesizes = [
        { name: "Small", code: "SM" },
        { name: "Medium", code: "MD" },
        { name: "Large", code: "LG" },
    ];

    const loadLazyData = () => {
        // imitate delay of a backend call
        const loadLazyTimeout = setTimeout(async () => {
            try {
                setIsadding(true);

                const storeData = await GetStorebyID(id as string);
                console.log("storeData", storeData.data.data);

                if (storeData.data.data) {
                    const storeDetails = storeData.data.data;

                    setStoretObj({
                        ...storeDetails,
                        latitude: storeDetails.location?.coordinates[1]?.toString() || "", // latitude from coordinates
                        longitude: storeDetails.location?.coordinates[0]?.toString() || "" // longitude from coordinates
                    });
                }

                setIsadding(false);
            } catch (error) {
                // Handle any potential errors from API calls
                console.error("Error loading data:", error);
                setIsadding(false);
            }
        },);
    };

    useEffect(() => {
        setIsadding(true);
        loadLazyData();
    }, []);

    const [storeObj, setStoretObj] = useState<resObj>(initialState);

    //for timing
    const initialSelectedTimings = {};
    const [selectedTimings, setSelectedTimings] = useState<
        Record<string, string[]>
    >(initialSelectedTimings);
    
    const handleAddTiming = () => {
        if (fromtimes && totimes) {
            const selectedDays = Object.keys(checkedDays).filter(
                (day) => checkedDays[day]
            );

            setSelectedTimings((prevSelectedTimings) => {
                const updatedTimings = { ...prevSelectedTimings };

                selectedDays.forEach((dayKey) => {
                    const existingTimings = updatedTimings[dayKey] || [];
                    const newTiming = `${fromtimes} to ${totimes}`;
                    updatedTimings[dayKey] = [...existingTimings, newTiming];
                });

                console.log("selectedTimings", updatedTimings);
                // setResObj({ ...resObj, timings: updatedTimings });
                return updatedTimings;
            });
        }
    };

    const [checkedDays, setCheckedDays] = useState(() => {
        const initialCheckedState: Record<string, boolean> = {};
        daysOfWeek.forEach((day) => {
            initialCheckedState[day] = false;
        });
        return initialCheckedState;
    });

    const handleCheckboxChange = (day: string) => {
        setCheckedDays((prevState) => ({
            ...prevState,
            [day]: !prevState[day],
        }));
    };

    const onFromTimeChange = (e: any) => {
        setfromtimes(e.value);
    };

    const onToTimeChange = (e: any) => {
        settotimes(e.value);
    };

    const Servicestyps = (e: any) => {
        let _servicestypes = [...servicestype];

        if (e.checked) _servicestypes.push(e.value);
        else _servicestypes.splice(_servicestypes.indexOf(e.value), 1);

        setServicestype(_servicestypes);
        setStoretObj({ ...storeObj, servicetype: _servicestypes });
    };

    const onStoreSizeChange = (e: any) => {
        setStoreSize(e.value);
        setStoretObj({ ...storeObj, storesize: e.value.name });
    };

    const [storetype, setStoretype] = useState<string[]>([]);
    const Storetypes = (e: any) => {
        let _Storetypes = [...storetype];

        if (e.checked) _Storetypes.push(e.value);
        else _Storetypes.splice(_Storetypes.indexOf(e.value), 1);

        setStoretype(_Storetypes);
        setStoretObj({ ...storeObj, storetype: _Storetypes });
    };

    const getFormErrorMessage = (name: string) => {
        const errorObject = errors[name as keyof typeof defaultValues];

        return errorObject ? (
            <small className="p-error">{errorObject.message}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };

    const [stepData, setStepData] = useState([
        { completed: false },
        { completed: false },
        { completed: false },
        { completed: false },
        { completed: false }
    ]);

    const validateFields = (): boolean => {
        const show = (message: any, severity: any, summary: any) => {
            if (toast.current) {
                toast.current.show({
                    severity: severity,
                    summary: summary,
                    detail: message,
                });
            }
        };

        let updatedStepData = [...stepData];
        if (activeStep === 0) {
            if (
                storeObj.storename.length === 0 ||
                storeObj.latitude.length === 0 ||
                storeObj.longitude.length === 0 ||
                storeObj.address.length === 0 ||
                storeObj.locality.length === 0 ||
                storeObj.city.length === 0 ||
                storeObj.district.length === 0 ||
                storeObj.state.length === 0 ||
                storeObj.country.length === 0 ||
                storeObj.pincode.length === 0

            ) {
                show("Please check all Mandatory fields", "warn", "Warn");
                return false;
            }
            updatedStepData[activeStep].completed = true;
        }
        else if (activeStep === 1) {
            if (
                storeObj.storetype.length === 0 ||
                storeObj.servicetype.length === 0
            ) {
                show("Please check all Mandatory fields", "warn", "Warn");
                return false;
            }
            updatedStepData[activeStep].completed = true;

        }
        else if (activeStep === 3) {
            if (
                storeObj.thumbnails.length === 0 ||
                storeObj.storeimages.length === 0
            ) {
                show("Please check all Mandatory fields", "warn", "Warn");
                return false;
            }
            updatedStepData[activeStep].completed = true;
        }

        setStepData(updatedStepData);
        return true;
    };

    const handleNext = () => {
        
        console.log("clicked next");
        if (!validateFields()) {
            return;
        }
        const updatedStepData = [...stepData];

        updatedStepData[activeStep] = {
            ...updatedStepData[activeStep],
            completed: true,
        };

        const updatedCompletedSteps = [...completedSteps];
        updatedCompletedSteps[activeStep] = true;

        setStepData(updatedStepData);
        setCompletedSteps(updatedCompletedSteps);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        updateStepColors();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        updateStepColors();
    };
    const updateStepColors = () => {
        const newStepColors = [...stepColors];
        newStepColors[activeStep] = "lightblue"; // Change this to the desired color
        setStepColors(newStepColors);
    };

    const ResetForm = () => {
        console.log("resetting the form");
        setIsadding(true);
        window.location.reload();
        setStoretObj(initialState);
    };

    const handleImageClick = (index: any) => {
        setActiveStep(index);
        validateFields(); // Trigger validation when a step is clicked
    };
    console.log("restObj", storeObj);




    const fetchAddress = async (lat: any, long: any) => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
          );
          const data = await response.json();
          console.log(data);
      
          let address = '';
          let district = '';
          let city = '';
          let state = '';
          let pincode = '';
          let countryName = '';
          let locality = '';  
      
          data.results.forEach((result: any) => {
            result.address_components.forEach((component: any) => {
              if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
                address += component.long_name + ', ';
              } else if (component.types.includes('locality')) {
                city = component.long_name;
              } else if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name;
              } else if (component.types.includes('administrative_area_level_3')) {
                district = component.long_name;
              } else if (component.types.includes('postal_code')) {
                pincode = component.long_name;
              } else if (component.types.includes('country')) {
                countryName = component.long_name;
              } 
    
              else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('neighborhood')) {
                locality = component.long_name;
              }
            });
          });
      
    
          locality = locality || address.slice(0, -2); 
      
          setStoretObj({
            ...storeObj,
            locality: locality,  
            district: district,
            city: city,
            state: state,
            pincode: pincode,
            country: countryName,
          });
      
        } catch (error) {
          console.error("Error fetching city:", error);
        }
      };

    const handleEditStore = async () => {
        setIsadding(true)

        if (storeThumbnailImages.length > 5) {
            show(
              "You can upload up to 5 thumbnail images only.",
              "error",
              "Limit Exceeded"
            );
            setIsadding(false)

            return; 
          }
      
          if (storeImages.length > 25) {
            show("You can upload up to 25 organic store images only.",
              "error",
              "Limit Exceeded"
            );
            return;
          }


        if (storeObj) {

            const updatedStoreObj = {
                ...storeObj,
                location: {
                    coordinates: [
                        parseFloat(storeObj.longitude) || 0,  // Updated longitude
                        parseFloat(storeObj.latitude) || 0,   // Updated latitude
                    ],
                },
            };
            const formData = new FormData();
            // storetype
            updatedStoreObj.storetype.forEach((store: any, index: number) => {
                formData.append(`storetype[${index}]`, store);
            });
            // servicetype
            updatedStoreObj.servicetype.forEach((service: any, index: number) => {
                formData.append(`servicetype[${index}]`, service);
            });
            // store size 
            updatedStoreObj.storesize.forEach((size: any, index: number) => {
                formData.append(`storesize [${index}]`, size);
            })

            // basic details
            formData.append("storename", updatedStoreObj.storename);
            formData.append("phoneno", updatedStoreObj.phoneno);
            formData.append("email", updatedStoreObj.email);
            formData.append("website", updatedStoreObj.website);
            formData.append("facebookurl", updatedStoreObj.facebookurl);
            formData.append("instaurl", updatedStoreObj.instaurl);
            formData.append("youtubeurl",updatedStoreObj.youtubeurl)
            formData.append("twitterurl",updatedStoreObj.twitterurl);
            formData.append("address", updatedStoreObj.address);
            formData.append("longitude", updatedStoreObj.longitude);
            formData.append("latitude", updatedStoreObj.latitude);
            formData.append("locality", updatedStoreObj.locality);
            formData.append("city", updatedStoreObj.city);
            formData.append("district", updatedStoreObj.district);
            formData.append("state", updatedStoreObj.state);
            formData.append("country", updatedStoreObj.country);
            formData.append("pincode", updatedStoreObj.pincode);

            updatedStoreObj.storeimages.forEach((storeimages: File | string) => {
                if (typeof storeimages === "string") {
                    formData.append("existingImageURLs", storeimages);
                } else {
                    formData.append("storeimages", storeimages);
                }
            });

            // Images (files or URLs)
            updatedStoreObj.thumbnails.forEach((thumbnails: File | string) => {
                if (typeof thumbnails === "string") {
                    formData.append("existingThumbnailURLs", thumbnails);
                } else {
                    formData.append("thumbnails", thumbnails);
                }
            });

            // timings
            if (JSON.stringify(selectedTimings) !== JSON.stringify(initialSelectedTimings)) {
                formData.append("timings", JSON.stringify(selectedTimings));
            }
            console.log("Updated Store Object:", updatedStoreObj);
            try {
                const response = await EditStoreDetails(updatedStoreObj._id, formData);
                console.log("API Response:", response); // Add this to log the response
                const currentPath = window.location.pathname;
                const productId = updatedStoreObj._id;

                // Check if the response contains the message or the ID to determine success
                if (response.data && response.data.message === "Organic store succesfully updated.") {
                    show("Organic Store Updated Successfully", "success", "Success");

                    let targetUrl = '';
                    setTimeout(async () => {
                        if (currentPath.includes("/pendingstore")) {
                            targetUrl = `/organicstores/pendingstore/details/${productId}`;
                        } else if (currentPath.includes("/approvedstore")) {
                            targetUrl = `/organicstores/approvedstore/details/${productId}`;
                        } else if (currentPath.includes("/rejectedstore")) {
                            targetUrl = `/organicstores/rejectedstore/details/${productId}`;
                        }

                        if (targetUrl) {
                            navigate(targetUrl);
                            setIsadding(false);

                        }
                    }, 2000);

                } else {
                    setIsadding(false);
                    show("Invalid Access", "error", "Error");
                    console.log("Unexpected response structure:", response);
                }
            } catch (error) {
                console.error("Error updating store:", error);
            }

        }

    };



    const handleDeleteClick = (index: any, purpose: any) => {
        const updatedRestObj = { ...storeObj };
        console.log("updatedRestObj", updatedRestObj);
        if (purpose === "ThumbnailImages") {
            const updatedThumbnails = [...storeObj.thumbnails];
            updatedThumbnails.splice(index, 1);
            updatedRestObj.thumbnails = updatedThumbnails;
        } else if (purpose === "Storeimages") {
            const updatedImages = [...updatedRestObj.storeimages];
            updatedImages.splice(index, 1);
            updatedRestObj.storeimages = updatedImages;
        }

        setStoretObj(updatedRestObj);
    };

    return (
        <>
            {!isAdding ? "" : <Loader />}
            <div className="d-flex justify-content-center align-items-center">
                <div className="col d-flex justify-content-center align-items-center">
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "85vh" }}
                    >
                        <ol className="c-stepper">
                            {steps.map((label, index) => (
                                <li key={label} className={`c-stepper__item`}>
                                    <img
                                        className="c-stepper__item__img"
                                        src={images[index]}
                                        style={{ fill: "#00ff00" }}
                                        onClick={() => handleImageClick(index)}
                                    />
                                    <div className="c-stepper__content">
                                        <h3 className="c-stepper__title">Step {index + 1}</h3>
                                        <p className="c-stepper__disc">{stepdesc[index]}</p>
                                        <p
                                            className="fs-12"
                                            style={{
                                                color: index === activeStep ? "#8856BB" : "green",
                                            }}
                                        >
                                            {index === activeStep ? "In progress" : "Completed"}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
                <div className="col">
                    <div
                        className="card details-card"
                        style={{
                            width: "calc(100vw - 60vw)",
                            height: "92vh",
                            borderRadius: "12px",
                        }}
                    >
                        <div className="next-card card-body">
                            <Toast ref={toast}></Toast>
                            {activeStep === 0 && (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="child-left" id="OrganicstoreDetails">
                                                <h4>Edit Organic Store Details</h4>
                                                <hr></hr>
                                                <div className="">
                                                    <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>
                                                        <div className="row">
                                                            <Controller
                                                                name="restaurantname"
                                                                control={control}
                                                                rules={{
                                                                    required: "Organicstore name  is required.",
                                                                }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <label className="ym-input-label">
                                                                            Organic Store Name{" "}
                                                                            <b style={{ color: "red" }}>*</b>
                                                                        </label>
                                                                        <div className="flex flex-column">
                                                                            <InputText
                                                                                placeholder="Enter Organic Store Name"
                                                                                id={field.name}
                                                                                value={storeObj.storename}
                                                                                className={classNames({
                                                                                    "p-invalid": fieldState.error,
                                                                                })}
                                                                                onChange={(e) => {
                                                                                    field.onChange(e);
                                                                                    setStoretObj({
                                                                                        ...storeObj,
                                                                                        storename: e.target.value,
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {getFormErrorMessage(field.name)}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="row">
                                                            <label className="ym-input-label">
                                                                Phone Number
                                                            </label>
                                                            <div className="flex flex-column">
                                                                <InputText
                                                                    id="phoneno"
                                                                    className="no-spinner"
                                                                    maxLength={14}
                                                                    value={storeObj.phoneno}
                                                                    onChange={(e: any) => {
                                                                        setStoretObj({
                                                                            ...storeObj,
                                                                            phoneno: e.target.value,
                                                                        });
                                                                    }}
                                                                    placeholder="Enter Phone Number"
                                                                    aria-describedby="username-help"
                                                                    keyfilter={/^[0-9+\-\s]*$/}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br></br>

                                                        <div className="row">
                                                            <div className="d-flex align-items-center gap-1 mb-1">
                                                                <label className="ym-input-label mb-0">
                                                                    Email Address
                                                                </label>
                                                                <i
                                                                    className="bi bi-info-circle"
                                                                    title="Enter the valid email"
                                                                    id="icon"
                                                                ></i>
                                                            </div>

                                                            <div className="flex flex-column gap-2">
                                                                <InputText
                                                                    id="email"
                                                                    value={storeObj.email}
                                                                    placeholder="Enter Email Address"
                                                                    aria-describedby="username-help"
                                                                    onChange={(e: any) => {
                                                                        setStoretObj({
                                                                            ...storeObj,
                                                                            email: e.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <br></br>
                                                        <div className="row">
                                                            <div className="d-flex align-items-center gap-1 mb-1">
                                                                <label className="ym-input-label mb-0">
                                                                    Website URL{" "}
                                                                </label>
                                                                <i
                                                                    className="bi bi-info-circle"
                                                                    title="Enter the valid Website URL"
                                                                    id="icon"
                                                                ></i>
                                                            </div>
                                                            <div className="flex flex-column gap-2">
                                                                <InputText
                                                                    id="website"
                                                                    value={storeObj.website}
                                                                    placeholder="Enter Website URL"
                                                                    aria-describedby="username-help"
                                                                    onChange={(e: any) => {
                                                                        setStoretObj({
                                                                            ...storeObj,
                                                                            website: e.target.value,
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                                                            <div className="col-6 p-0">
                                                                <div className="flex flex-column">
                                                                    <label className="ym-input-label">
                                                                        Facebook
                                                                    </label>
                                                                    <div className="input-dropdown-container">
                                                                        <InputText
                                                                            placeholder="Enter Facebook URL"
                                                                            value={storeObj.facebookurl}
                                                                            className="col-12"
                                                                            onChange={(e) => {
                                                                                let value = e.target.value.trimStart(); // Trim leading spaces
                                                                                // Update the state only if the value is not just spaces
                                                                                setStoretObj({
                                                                                    ...storeObj,
                                                                                    facebookurl: value.length > 0 ? value : '',
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 p-0">
                                                                <div className="flex flex-column">
                                                                    <label className="ym-input-label">
                                                                        Instagram
                                                                    </label>
                                                                    <div className="input-dropdown-container">
                                                                        <InputText
                                                                            placeholder="Enter Instagram URL"
                                                                            value={storeObj.instaurl}
                                                                            className="col-12"
                                                                            onChange={(e) => {
                                                                                let value = e.target.value.trimStart(); // Trim leading spaces
                                                                                // Update the state only if the value is not just spaces
                                                                                setStoretObj({
                                                                                    ...storeObj,
                                                                                    instaurl: value.length > 0 ? value : '',
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                                                            <div className="col-6 p-0">
                                                                <div className="flex flex-column">
                                                                    <label className="ym-input-label">
                                                                        Youtube
                                                                    </label>
                                                                    <div className="input-dropdown-container">
                                                                        <InputText
                                                                            placeholder="Enter Youtube URL"
                                                                            className="col-12"
                                                                            value={storeObj.youtubeurl}
                                                                            onChange={(e) => {
                                                                                let value = e.target.value.trimStart(); // Trim leading spaces
                                                                                // Update the state only if the value is not just spaces
                                                                                setStoretObj({
                                                                                    ...storeObj,
                                                                                    youtubeurl: value.length > 0 ? value : '',
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-6 p-0">
                                                                <div className="flex flex-column">
                                                                    <label className="ym-input-label">
                                                                        X
                                                                    </label>
                                                                    <div className="input-dropdown-container">
                                                                        <InputText
                                                                            placeholder="Enter X URL"
                                                                            className="col-12"
                                                                            value={storeObj.twitterurl}
                                                                            onChange={(e) => {
                                                                                let value = e.target.value.trimStart(); // Trim leading spaces
                                                                                // Update the state only if the value is not just spaces
                                                                                setStoretObj({
                                                                                    ...storeObj,
                                                                                    twitterurl: value.length > 0 ? value : '',
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className="row mt-3">
                                                            <div className="col">
                                                                <div className="">
                                                                    <Controller
                                                                        name="latitude"
                                                                        control={control}
                                                                        rules={{
                                                                            required: "Latitude is required.",
                                                                            pattern: {
                                                                                value: /^[+-]?([0-9]*[.]?[0-9]+)$/, // Allowing negative and positive values with decimals
                                                                                message: "Enter a valid latitude with only numbers and dots.",
                                                                            },
                                                                        }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <div className="d-flex align-items-baseline">
                                                                                    <label className="ym-input-label">
                                                                                        Latitude
                                                                                        <b style={{ color: "red" }}>*</b>
                                                                                    </label>
                                                                                    <i
                                                                                        className="bi bi-info-circle ml-1"
                                                                                        title="Enter the valid latitude"
                                                                                        id="icon"
                                                                                    ></i>
                                                                                </div>
                                                                                <div className="flex flex-column">
                                                                                    <InputText
                                                                                        maxLength={30}
                                                                                        placeholder="Enter a valid latitude"
                                                                                        id={field.name}
                                                                                        value={storeObj.latitude}
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                        onChange={(e) => {
                                                                                            const value = e.target.value.trim();
                                                                                            const latitude = value === "" ? null : parseFloat(value); // Set latitude to null if empty
                                                                                            field.onChange(e);
                                                                                            setStoretObj({
                                                                                                ...storeObj,
                                                                                                latitude: value, // update latitude string in resObj
                                                                                                location: {
                                                                                                    ...storeObj.location,
                                                                                                    coordinates: [
                                                                                                        storeObj.location.coordinates[0], // keep longitude unchanged
                                                                                                        latitude !== null ? latitude : 0, // set default or actual latitude
                                                                                                    ],
                                                                                                },
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="">
                                                                    <Controller
                                                                        name="longitude"
                                                                        control={control}
                                                                        rules={{
                                                                            required: "Longitude is required.",
                                                                            pattern: {
                                                                                value: /^[+-]?([0-9]*[.]?[0-9]+)$/, // Allowing negative and positive values with decimals
                                                                                message: "Enter a valid longitude with only numbers and dots.",
                                                                            },
                                                                        }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <div className="d-flex align-items-baseline">
                                                                                    <label className="ym-input-label">
                                                                                        Longitude
                                                                                        <b style={{ color: "red" }}>*</b>
                                                                                    </label>
                                                                                    <i
                                                                                        className="bi bi-info-circle ml-1"
                                                                                        title="Enter the valid longitude"
                                                                                        id="icon"
                                                                                    ></i>
                                                                                </div>
                                                                                <div className="flex flex-column">
                                                                                    <InputText
                                                                                        maxLength={30}
                                                                                        placeholder="Enter a valid longitude"
                                                                                        id={field.name}
                                                                                        value={storeObj.longitude}
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                        onChange={(e) => {
                                                                                            const value = e.target.value.trim();
                                                                                            const longitude = value === "" ? null : parseFloat(value); // Set longitude to null if empty
                                                                                            field.onChange(e);
                                                                                            setStoretObj({
                                                                                                ...storeObj,
                                                                                                longitude: value, // update longitude string in resObj
                                                                                                location: {
                                                                                                    ...storeObj.location,
                                                                                                    coordinates: [
                                                                                                        longitude !== null ? longitude : 0, // set default or actual longitude
                                                                                                        storeObj.location.coordinates[1], // keep latitude unchanged
                                                                                                    ],
                                                                                                },
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col d-flex justify-content-center align-items-center mb-1">
                                                            <Button className="get-address" label="Get Address" severity="success" outlined aria-label="Search"
                                                                onClick={() => fetchAddress(storeObj.latitude, storeObj.longitude)}
                                                            />
                                                        </div>

                                                        <div className="row mt-3">
                                                            <Controller
                                                                name="address"
                                                                control={control}
                                                                rules={{ required: "Address  is required." }}
                                                                render={({ field, fieldState }) => (
                                                                    <>
                                                                        <label className="ym-input-label">
                                                                            Address <b style={{ color: "red" }}>*</b>
                                                                        </label>
                                                                        <div className="flex flex-column">
                                                                            <InputTextarea
                                                                                maxLength={130}
                                                                                id={field.name}
                                                                                {...field}
                                                                                placeholder="Enter Address Line"
                                                                                value={storeObj.address}
                                                                                // onChange={(e) => setAddress(e.target.value)}
                                                                                onChange={(e) => {
                                                                                    const rawValue = e.target.value;
                                                                                    const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                    if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                        field.onChange(e);
                                                                                        setStoretObj({
                                                                                            ...storeObj,
                                                                                            address: trimmedValue,
                                                                                        });
                                                                                    } else {
                                                                                        setStoretObj({
                                                                                            ...storeObj,
                                                                                            address: '', // Set to empty if invalid
                                                                                        });
                                                                                    }
                                                                                }}
                                                                                rows={5}
                                                                                cols={30}
                                                                                className={classNames({
                                                                                    "p-invalid": fieldState.error,
                                                                                })}
                                                                            />
                                                                        </div>
                                                                        {getFormErrorMessage(field.name)}
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="row ">

                                                            <div className="col m-0">
                                                                <div className="flex flex-column">
                                                                    <label className="ym-input-label">
                                                                        Locality <b style={{ color: 'red' }}>*</b>
                                                                    </label>
                                                                    <div className="input-dropdown-container">
                                                                        <InputText
                                                                            placeholder="Enter Locality"
                                                                            value={storeObj.locality}
                                                                            className="col-12"
                                                                            onChange={(e) => {
                                                                                const rawValue = e.target.value;
                                                                                const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                    setStoretObj({
                                                                                        ...storeObj,
                                                                                        locality: trimmedValue,
                                                                                    });
                                                                                } else {
                                                                                    setStoretObj({
                                                                                        ...storeObj,
                                                                                        locality: '', // Set to empty if invalid
                                                                                    });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col m-0">
                                                                <div className="">
                                                                    <Controller
                                                                        name="city"
                                                                        control={control}
                                                                        rules={{ required: "city is required." }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <label className="ym-input-label ">
                                                                                    City<b style={{ color: "red" }}>*</b>
                                                                                </label>

                                                                                <div className="flex flex-column">
                                                                                    <InputText
                                                                                        maxLength={30}
                                                                                        id={field.name}
                                                                                        value={storeObj.city}
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                        placeholder="Enter City"
                                                                                        onChange={(e) => {
                                                                                            const rawValue = e.target.value;
                                                                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                            if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    city: trimmedValue,
                                                                                                });
                                                                                            } else {
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    city: '', // Set to empty if invalid
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row">

                                                            <div className="col">
                                                                <div className="">
                                                                    <Controller
                                                                        name="district"
                                                                        control={control}
                                                                        rules={{ required: "district is required." }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <label className="ym-input-label">
                                                                                    District
                                                                                    <b style={{ color: "red" }}>*</b>
                                                                                </label>

                                                                                <div className="flex flex-column">
                                                                                    <InputText
                                                                                        maxLength={30}
                                                                                        placeholder="Enter District"
                                                                                        id={field.name}
                                                                                        value={storeObj.district}
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                        onChange={(e) => {
                                                                                            const rawValue = e.target.value;
                                                                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                            if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    district: trimmedValue,
                                                                                                });
                                                                                            } else {
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    district: '', // Set to empty if invalid
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col ">
                                                                <div className="">
                                                                    <Controller
                                                                        name="state"
                                                                        control={control}
                                                                        rules={{ required: "state is required." }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <label className="ym-input-label ">
                                                                                    State<b style={{ color: "red" }}>*</b>
                                                                                </label>

                                                                                <div className="flex flex-column">
                                                                                    <InputText
                                                                                        maxLength={30}
                                                                                        id={field.name}
                                                                                        value={storeObj.state}
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                        placeholder="Enter State"
                                                                                        onChange={(e) => {
                                                                                            const rawValue = e.target.value;
                                                                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                            if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    state: trimmedValue,
                                                                                                });
                                                                                            } else {
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    state: '', // Set to empty if invalid
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col m-0">
                                                                <div className="">
                                                                    <Controller
                                                                        name="country"
                                                                        control={control}
                                                                        rules={{ required: "country is required." }}
                                                                        render={({ field, fieldState }) => (
                                                                            <>
                                                                                <label className="ym-input-label ">
                                                                                    Country<b style={{ color: "red" }}>*</b>
                                                                                </label>

                                                                                <div className="flex flex-column">
                                                                                    <InputText
                                                                                        maxLength={30}
                                                                                        id={field.name}
                                                                                        value={storeObj.country}
                                                                                        className={classNames({
                                                                                            "p-invalid": fieldState.error,
                                                                                        })}
                                                                                        placeholder="Entry Country"
                                                                                        onChange={(e) => {
                                                                                            const rawValue = e.target.value;
                                                                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                            if (trimmedValue) { // Check if the trimmed value is not just spaces
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    country: trimmedValue,
                                                                                                });
                                                                                            } else {
                                                                                                setStoretObj({
                                                                                                    ...storeObj,
                                                                                                    country: '', // Set to empty if invalid
                                                                                                });
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {getFormErrorMessage(field.name)}
                                                                            </>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col m-0">
                                                                <Controller
                                                                    name="pincode"
                                                                    control={control}
                                                                    rules={{ required: "pincode  is required." }}
                                                                    render={({ field, fieldState }) => (
                                                                        <>
                                                                            <label className="ym-input-label">
                                                                                Pincode<b style={{ color: "red" }}>*</b>
                                                                            </label>
                                                                            <div className="flex flex-column">
                                                                                <InputText
                                                                                    maxLength={6}
                                                                                    placeholder="Enter Pincode"
                                                                                    id={field.name}
                                                                                    value={storeObj.pincode}
                                                                                    className={classNames({
                                                                                        "p-invalid": fieldState.error,
                                                                                    })}
                                                                                    onChange={(e) => {
                                                                                        const rawValue = e.target.value;
                                                                                        // Ensure only numbers and up to 6 digits are allowed for pincode
                                                                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                                                                        if (/^\d{0,6}$/.test(trimmedValue)) { // Validate pincode format
                                                                                            setStoretObj({
                                                                                                ...storeObj,
                                                                                                pincode: trimmedValue,
                                                                                            });
                                                                                        } else {
                                                                                            setStoretObj({
                                                                                                ...storeObj,
                                                                                                pincode: '', // Set to empty if invalid
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    keyfilter={/^[0-9]*$/}

                                                                                />
                                                                            </div>
                                                                            {getFormErrorMessage(field.name)}
                                                                        </>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </ScrollPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeStep === 1 && (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="child-left" id="FoodPerferences">
                                                <h4>Service Options & Preferences</h4>
                                                <hr></hr>
                                                <div className="">
                                                    <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                                                        <div className="row">
                                                            <label className="ym-input-label mt-2">
                                                                Store Type<b style={{ color: "red" }}>*</b>

                                                            </label>
                                                            <div className="row">
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="storetype1"
                                                                            name="storetype"
                                                                            value="Groceries"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Groceries")}
                                                                        />
                                                                        <label htmlFor="preference1" className="">
                                                                            Groceries
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="storetype2"
                                                                            name="storetype"
                                                                            value="Vegetables"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Vegetables")}
                                                                        />
                                                                        <label htmlFor="preference2" className="ml-2">
                                                                            Vegetables
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-3">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="storetype3"
                                                                            name="storetype"
                                                                            value="Fruits"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Fruits")}
                                                                        />
                                                                        <label htmlFor="storetype3" className="ml-2">
                                                                            Fruits
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="storetype3"
                                                                            name="storetype"
                                                                            value="Dairy"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Dairy")}
                                                                        />
                                                                        <label htmlFor="storetype4" className="ml-2">
                                                                            Dairy
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="storetype5"
                                                                            name="storetype"
                                                                            value="Nuts"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Nuts")}
                                                                        />
                                                                        <label htmlFor="storetype5" className="ml-2">
                                                                            Nuts
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="storetype5"
                                                                            name="storetype"
                                                                            value="Eggs"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Eggs")}
                                                                        />
                                                                        <label htmlFor="storetype5" className="ml-2">
                                                                        Eggs
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="storetype5"
                                                                            name="storetype"
                                                                            value="Oils"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Oils")}
                                                                        />
                                                                        <label htmlFor="storetype5" className="ml-2">
                                                                            Oils
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="storetype5"
                                                                            name="storetype"
                                                                            value="Millets"
                                                                            onChange={Storetypes}
                                                                            checked={(storeObj.storetype as string[]).includes("Millets")}
                                                                        />
                                                                        <label htmlFor="storetype5" className="ml-2">
                                                                            Millets
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="ym-input-label mt-2">
                                                                Service Type<b style={{ color: "red" }}>*</b>
                                                            </label>
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <div className="flex align-items-center gap-2">
                                                                        <Checkbox
                                                                            inputId="service1"
                                                                            name="servicestype"
                                                                            value="Delivery"
                                                                            onChange={Servicestyps}
                                                                            checked={(storeObj.servicetype as string[]).includes("Delivery")}
                                                                        />
                                                                        <label htmlFor="service1" className="">
                                                                            Delivery
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="service2"
                                                                            name="servicestype"
                                                                            value="In-store Shopping"
                                                                            onChange={Servicestyps}
                                                                            checked={(storeObj.servicetype as string[]).includes("In-store Shopping")}
                                                                        />
                                                                        <label htmlFor="service2" className="ml-2">
                                                                            In-store Shopping
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox
                                                                            inputId="service3"
                                                                            name="servicestype"
                                                                            value="In-store Pickup"
                                                                            onChange={Servicestyps}
                                                                            checked={servicestype.includes("In-store Pickup")}
                                                                        />
                                                                        <label htmlFor="service3" className="ml-2">
                                                                            In-store Pickup
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="row mt-2">
                                                            <div className="col">
                                                                <div className="">
                                                                    <label className="ym-input-label">
                                                                        Services Options
                                                                    </label>
                                                                </div>

                                                                <div className="flex flex-column">
                                                                    <InputText
                                                                        value={storeObj.serviceoptions}
                                                                        placeholder="Enter sevice options"
                                                                        onChange={(e: any) => {
                                                                            setStoretObj({
                                                                                ...storeObj,
                                                                                serviceoptions: e.target.value,
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="">
                                                                    <label className="ym-input-label">
                                                                        Store Size
                                                                    </label>
                                                                </div>
                                                                <div className="flex flex-column">
                                                                    <Dropdown
                                                                        value={storeSize}
                                                                        onChange={onStoreSizeChange}
                                                                        options={storesizes}
                                                                        optionLabel="name"
                                                                        placeholder="Select"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}


                                                        <br></br>
                                                    </ScrollPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeStep === 2 && (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="child-left" id="Timings">
                                                <h4>Timings</h4>
                                                <hr></hr>
                                                <div className="">
                                                    <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                                                        <div className="row">
                                                            <label className="ym-input-label">Timings</label>
                                                            <div className="row">
                                                                {daysOfWeek.map((day) => (
                                                                    <div className="col-3" key={day}>
                                                                        <div className="d-flex align-items-center">
                                                                            <Checkbox
                                                                                inputId={`day${day}`}
                                                                                name="day"
                                                                                value={day}
                                                                                checked={checkedDays[day]}
                                                                                onChange={() => handleCheckboxChange(day)}
                                                                            />
                                                                            <label
                                                                                htmlFor={`day${day}`}
                                                                                className="ml-1"
                                                                            >
                                                                                {day}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                                                <div className="from d-flex flex-column col-3">
                                                                    <span className="restaurant-name">From</span>
                                                                    <Dropdown
                                                                        style={{
                                                                            width: "100%",
                                                                            backgroundColor: "white",
                                                                        }}
                                                                        id="eater"
                                                                        placeholder="Select"
                                                                        value={fromtimes}
                                                                        options={Timings}
                                                                        onChange={onFromTimeChange}
                                                                    />
                                                                </div>
                                                                <div className="from d-flex flex-column col-3">
                                                                    <span className="restaurant-name">To</span>
                                                                    <Dropdown
                                                                        style={{
                                                                            width: "100%",
                                                                            backgroundColor: "white",
                                                                        }}
                                                                        id="eater"
                                                                        placeholder="Select"
                                                                        value={totimes}
                                                                        options={Timings}
                                                                        onChange={onToTimeChange}
                                                                    />
                                                                </div>
                                                                <div className="mt-3">
                                                                    <button
                                                                        className="add-button"
                                                                        onClick={handleAddTiming}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="row d-flex justify-content-start align-items-center mt-3">
                                                                <div className="col-8">
                                                                    {Object.entries(selectedTimings).map(
                                                                        ([day, timing]) => (
                                                                            <div
                                                                                className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                                                                key={day}
                                                                            >
                                                                                <span className="day">{day}</span>
                                                                                <div className="row">
                                                                                    <div className="col gap-1">
                                                                                        {timing.map((time, index) => (
                                                                                            <div className="col p-0 mt-1 gap-1">
                                                                                                <span
                                                                                                    className="time"
                                                                                                    key={index}
                                                                                                >
                                                                                                    {time}
                                                                                                </span>

                                                                                                <i
                                                                                                    className="bi bi-trash-fill"
                                                                                                    style={{
                                                                                                        color: "#818181",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        const updatedTimings = {
                                                                                                            ...selectedTimings,
                                                                                                        };
                                                                                                        updatedTimings[day] =
                                                                                                            updatedTimings[day].filter(
                                                                                                                (t) => t !== time
                                                                                                            );

                                                                                                        // Check if there are no times left, delete the entire day
                                                                                                        if (
                                                                                                            updatedTimings[day]
                                                                                                                .length === 0
                                                                                                        ) {
                                                                                                            delete updatedTimings[day];
                                                                                                        }

                                                                                                        setSelectedTimings(
                                                                                                            updatedTimings
                                                                                                        );
                                                                                                    }}
                                                                                                ></i>
                                                                                                <br />
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ScrollPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeStep === 3 && (
                                <>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="child-left" id="uploadimages">
                                                <h4>Upload Images</h4>
                                                <hr></hr>
                                                <div className="">
                                                    <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                                                        <div className="">
                                                            <label className="ym-input-label">
                                                            Thumbnail Images  <b style={{ color: "red" }}>*</b> <span style={{fontSize:"12px"}}>(Add a min 1 or a max 5 Thumbnail Images)</span>

                                                            </label>
                                                            <DropFileInput
                                                                uploadedFiles={storeThumbnailImages}
                                                                setUploadedFiles={setStoreThumbnailImages}
                                                                resObj={storeObj}
                                                                setResObj={setStoretObj}
                                                                purpose="storeThumbnailImages"
                                                            />
                                                        </div>
                                                        <div className="grid mt-2 ml-1 gap-3">
                                                            {Array.isArray(storeObj.thumbnails) &&
                                                                storeObj.thumbnails.every(
                                                                    (item: any) => typeof item === "string"
                                                                ) &&
                                                                storeObj.thumbnails.map((item: any, index: any) => {
                                                                    return (
                                                                        <div
                                                                            id="Imagecrd"
                                                                            style={{ position: "relative" }}
                                                                            key={index}
                                                                        >
                                                                            <img
                                                                                className="uploadimage fefe"
                                                                                key={item}
                                                                                src={item + "?" + sas}
                                                                                alt=""
                                                                            />

                                                                            <i
                                                                                className="pi pi-trash icontrash"
                                                                                onClick={() =>
                                                                                    handleDeleteClick(index, "ThumbnailImages")
                                                                                }
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    top: "0",
                                                                                    right: "0",
                                                                                    margin: "8px",
                                                                                }}
                                                                            ></i>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                        <div className="">
                                                            <label className="ym-input-label">
                                                                Organic Store Images
                                                                <b style={{ color: "red" }}>*</b>
                                                            </label>
                                                            <DropFileInput
                                                                uploadedFiles={storeImages}
                                                                setUploadedFiles={setStoreImages}
                                                                resObj={storeObj}
                                                                setResObj={setStoretObj}
                                                                purpose="Storeimages"
                                                            />
                                                        </div>
                                                        <div className="grid mt-2 ml-1 gap-3">
                                                            {Array.isArray(storeObj.storeimages) &&
                                                                storeObj.storeimages.every(
                                                                    (item: any) => typeof item === "string"
                                                                ) &&
                                                                storeObj.storeimages.map((item: any, index: any) => {
                                                                    return (
                                                                        <div
                                                                            id="Imagecrd"
                                                                            style={{ position: "relative" }}
                                                                            key={index}
                                                                        >
                                                                            <img
                                                                                className="uploadimage fefe"
                                                                                key={item}
                                                                                src={item + "?" + sas}
                                                                                alt=""
                                                                            />

                                                                            <i
                                                                                className="pi pi-trash icontrash"
                                                                                onClick={() =>
                                                                                    handleDeleteClick(index, "Storeimages")
                                                                                }
                                                                                style={{
                                                                                    position: "absolute",
                                                                                    top: "0",
                                                                                    right: "0",
                                                                                    margin: "8px",
                                                                                }}
                                                                            ></i>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </ScrollPanel>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>

                        <div className="card-footer button-container">
                            {activeStep === 0 ? (
                                <>
                                    <div className="d-flex justify-content-end">
                                        <div className="row">
                                            <Button
                                                className="next"
                                                label="Next"
                                                type="submit"
                                                // icon="pi pi-check"
                                                onClick={
                                                    handleNext
                                                }

                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="ym-add-button-previous"
                                        label="Back"
                                        onClick={handleBack}
                                    ></Button>

                                    {activeStep === 3 ? (
                                        <Button
                                            className="p-button-success ym-add-button"
                                            label="Save Changes"
                                            onClick={handleEditStore}
                                        // disabled={currentIndex === 3}
                                        ></Button>
                                    ) : (

                                        <Button
                                            className="next"
                                            label="Next"
                                            type="submit"
                                            // icon="pi pi-check"
                                            onClick={
                                                handleNext
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditStoreStepper;
