import React from 'react';
import { Image } from "primereact/image";
import { sas } from "../../config/constants"

interface props {
    degrees: {
        degree: string;
        college: string;
        yearOfCompletion: string;
    }[];
    certification: string;
    membership: string[];
    awardsandrecognitions: string[];
    certificationimages: string[];
}
const EducationalInfo: React.FC<props> = ({ degrees, certification, membership, awardsandrecognitions, certificationimages }) => {
    return (
        <>
            <div>
            {degrees.map((edu, index) => (
                <div key={index} className="d-flex">
                    <div className="col-4">
                        <h6 className="mb-1 sub-detail-head">Degree</h6>
                        <p className="sub-detail-data">{edu.degree ? edu.degree : "--"}</p>
                    </div>
                    <div className="col-4">
                        <h6 className="mb-1 sub-detail-head">College</h6>
                        <p className="sub-detail-data">{edu.college ? edu.college : "--"}</p>
                    </div>
                    <div className="col-3">
                        <h6 className="mb-1 sub-detail-head">Year of Completion</h6>
                        <p className="sub-detail-data">{edu.yearOfCompletion ? edu.yearOfCompletion : "--"}</p>
                    </div>
                </div>
            ))}

                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Certification</h6>
                        <p className='sub-detail-data'>{certification ? certification : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Memberships</h6>
                        <p className='sub-detail-data'>{membership.length > 0 ? membership.join(", ") : "--"}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Awards & Recognitions</h6>
                        <p className='sub-detail-data'>{awardsandrecognitions.length > 0 ? awardsandrecognitions.join(", ") : "--"}</p>
                    </div>
                </div>

                <div className="col-5">
                    <h6 className="mb-2 sub-detail-head">Medical Registration Proof</h6>
                    <Image className='medical-proof-image' src={certificationimages[0] + "?" + sas} alt="Image" />
                </div>

            </div>
        </>
    );
}

export default EducationalInfo;
