import React, { useRef, useState } from "react";
import "../LoginScreen/loginScreen.page.css";
import { Toast } from "primereact/toast";
import LoginImage from "../../assets/images/LoginImages.png";
import {
  IResolveParams,
  LoginSocialGoogle,
  LoginSocialMicrosoft,
} from "reactjs-social-login";
import image from "../../assets/images/Blobimages/Blobimages";
import OtpInput from "react-otp-input";
import { signIn, verifyOtp } from "../../api/auth.api";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/ui/loader/loader.components";
import { sas, yeldamwebimagessas } from "../../config/constants";

const LoginScreen = () => {
  const toast = useRef<Toast>(null);
  const [isAdding, setIsadding] = useState(false);
  const [code, setCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [otpVisible, setOtpVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const handleOnClick = async () => {
    try {
      if (code.length === 6) {
        const responseobj = await verifyOtp({ otp: parseInt(code) });
        console.log("responseobj", responseobj);

        if (responseobj === 200) {
          setTimeout(() => {
            const isAuthenticated = !!localStorage.getItem("accessToken");
            setIsadding(true);
            console.log("responseobj obj", responseobj);

            if (isAuthenticated) {
              console.log("isAuthenticated obj", responseobj);
              window.location.reload();
              navigate("/dashboard");
              setIsadding(false);
            }
          }, 100);
        } else {
          show("Invalid OTP. Please try again.", "error", "OTP Verification Failed");
          setCode("");
        }
      } else {
        throw new Error("Invalid OTP format");
      }
    } catch (error) {
      console.log("error obj", error);
      setIsadding(false);
      show("Invalid Access", "error", "Error");
      window.location.reload();
    }
  };

  const handleChange = async (code: any) => {
    const regex = /^[0-9]*$/;
    if (regex.test(code)) {
      setCode(code);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <Toast ref={toast}></Toast>

      {!isAdding ? "" : <Loader />}
      <div className="container-fluid vh-100">
        <div className="row main-login d-flex justify-content-center vh-100">
          <div className="col-6 d-flex justify-content-end align-items-center">
            <img
              src={LoginImage + "?" + yeldamwebimagessas}
              style={{ height: "382px", width: "550px" }}
            ></img>
          </div>
          <div className="col-6 d-flex justify-content-center ">
            <div className="d-flex flex-column justify-content-evenly content-details">
              <div className="image-section">
                <img
                  src={image.mainlogo + "?" + yeldamwebimagessas}
                  style={{ height: "50px", width: "150px" }}
                ></img>
              </div>



              {/* for fliping the card for login button and otp filler */}
              {otpVisible ?

                <div className="d-flex align-items-center">

                  <div className="col box p-2 d-flex flex-column justify-content-center align-items-center">
                    <div className="m-0 d-flex justify-content-center align-items-center flex-column ">
                      <div className="Otp-title">
                        Enter OTP
                      </div>
                      <div className="otp-head">
                        Please enter the One-Time Password to verify your account
                      </div>
                    </div>
                    <div className="m-2 d-flex justify-content-center otp-subhead">
                      <p className="otp-head">
                        A One-Time Password has been sent to <b className="mail">{userEmail}</b>
                      </p>
                    </div>
                    <div className="mt-4 d-flex justify-content-center" onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleOnClick();
                      }
                    }}>

                      <OtpInput
                        value={code}
                        onChange={(e: any) => {
                          handleChange(e);
                        }}
                        numInputs={6}
                        shouldAutoFocus={false}
                        inputStyle={{
                          border: "2px solid #c3c3c3",
                          width: "44px",
                          borderRadius: "4px",
                          marginRight: "7px",
                          height: "44px",
                          fontSize: "12px",
                          color: "#000",
                          fontWeight: "400",
                        }}


                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-center">
                      <button className="Val-btn" onClick={() => handleOnClick()}>
                        Validate
                      </button>
                    </div>
                  </div>
                </div>
                :
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <div className="agent">Agent Login</div>
                    <div className="content">
                      Please choose any of the service to Sign in
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-3">
                    <div className="col box">
                      <LoginSocialMicrosoft
                        client_id={"7fc0abdb-b68c-4604-82b9-9f1d225ca151"}
                        redirect_uri={window.location.origin}
                        onResolve={async ({ provider, data }: IResolveParams) => {
                          if (provider === "microsoft") {
                            setIsadding(true);

                            if (data) {
                              const statusCode = await signIn(provider, data);
                              //console.log("statusCode", statusCode);
                              if (statusCode === 200) {
                                setIsadding(false);
                                setUserEmail(data.userPrincipalName);
                                setOtpVisible(true);
                              } else {
                                console.log("invaild accesss")
                                setIsadding(false);
                                show("Invalid Access", "error", "Error");
                              }
                            }
                          }
                        }}
                        onReject={(err: any) => {
                          return err;
                        }}
                        scope="profile openid email User.Read"
                      >
                        <button type="button" className="login-btn-microsoftbutton">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                              >
                                <title>MS-SymbolLockup</title>
                                <rect
                                  x="1"
                                  y="1"
                                  width="9"
                                  height="9"
                                  fill="#f25022"
                                />
                                <rect
                                  x="1"
                                  y="11"
                                  width="9"
                                  height="9"
                                  fill="#00a4ef"
                                />
                                <rect
                                  x="11"
                                  y="1"
                                  width="9"
                                  height="9"
                                  fill="#7fba00"
                                />
                                <rect
                                  x="11"
                                  y="11"
                                  width="9"
                                  height="9"
                                  fill="#ffb900"
                                />
                              </svg>
                            </div>
                            <div className="col-8 login-text-microsoft text-left">
                              <span onClick={() => setVisible(true)}>
                                {" "}
                                Sign in with Microsoft
                              </span>
                            </div>
                          </div>
                        </button>
                      </LoginSocialMicrosoft>
                    </div>
                    <div className="col box">
                      <LoginSocialGoogle
                        client_id={
                          "1099419468908-pr1impl1km9otk1hc117kc7nuhhncfgj.apps.googleusercontent.com"
                        }
                        scope="profile email"
                        onResolve={({ provider, data }: IResolveParams) => {
                          if (provider === "google") {
                            setIsadding(true);
                            if (data) {
                              signIn(provider, data)
                                .then((response) => {
                                  if (response === 200) {
                                    setIsadding(false);
                                    setUserEmail(data.email);
                                    setOtpVisible(true);
                                  } else {
                                    console.log("invaild accesss")
                                    setIsadding(false);
                                    show("Invalid Access", "error", "Error");

                                  }
                                })
                                .catch((error) => {
                                  show(error, "error", "Error");
                                });
                            }
                          }
                        }}
                        onReject={(err) => {
                          console.log("LoginSocialGoogle error", err);
                        }}
                      >
                        <button type="button" className="login-with-google-btn">
                          <div className="row align-items-center">
                            <div className="col-2">


                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="21"
                                height="21"
                                viewBox="0 0 48 48"
                              >
                                <g>
                                  <path
                                    fill="#EA4335"
                                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                  ></path>
                                  <path
                                    fill="#4285F4"
                                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                  ></path>
                                  <path
                                    fill="#FBBC05"
                                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                  ></path>
                                  <path
                                    fill="#34A853"
                                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                  ></path>
                                  <path fill="none" d="M0 0h48v48H0z"></path>
                                </g>
                              </svg>
                            </div>
                            <div className="col-8 login-text-google text-left">
                              <span onClick={() => setVisible(true)}>
                                Sign in with Google
                              </span>
                            </div>
                          </div>
                        </button>
                      </LoginSocialGoogle>
                    </div>
                  </div>

                </div>
              }





            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
