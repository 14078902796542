import React, { useRef, useState, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';
import { sas } from "../../config/constants";
import nomenu from "../../assets/images/nomenu.png";
import "./RestaurantMenu.component.css";

type Props = {
  menuimages: string[];
};

const Restaurantmenu: React.FC<Props> = ({ menuimages }) => {
  const galleria = useRef<Galleria>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // Handle keyboard navigation for the menu images
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        // Move to the next image
        setActiveIndex((prevIndex) => (prevIndex + 1) % menuimages.length);
      } else if (e.key === 'ArrowLeft') {
        // Move to the previous image
        setActiveIndex((prevIndex) => (prevIndex - 1 + menuimages.length) % menuimages.length);
      }
    };

    // Add event listener for keyboard events
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [menuimages.length]);

  const itemTemplate = (item: any) => {
    return <img src={item + "?" + sas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
  };

  const thumbnailTemplate = (item: any) => {
    return <img src={item + "?" + sas} alt={item.alt} style={{ display: 'block' }} />;
  };

  return (
    <div>
      <div className="d-flex p-3">
        {menuimages?.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <img src={nomenu} alt="No Menu" className="nomenu-image" style={{ width: "200px", height: "200px" }} />
            <p className="span-data">No Menu Available</p>
          </div>
        ) : (
          <div className="pt-2">
            <Galleria
              ref={galleria}
              value={menuimages}
              numVisible={7}
              style={{ maxWidth: '850px' }}
              activeIndex={activeIndex}
              onItemChange={(e) => setActiveIndex(e.index)}
              fullScreen
              showItemNavigators
              showThumbnails={false}
              item={itemTemplate}
              thumbnail={thumbnailTemplate}
            />
            <div className="image_gallery">
              {menuimages && menuimages.map((image: any, index: any) => {
                let imgEl = <img
                  src={image + "?" + sas}
                  style={{ cursor: 'pointer' }}
                  onClick={() => { setActiveIndex(index); galleria.current?.show(); }}
                />;
                return (
                  <div className="rest-img" key={index}>
                    {imgEl}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Restaurantmenu;
