import { map } from 'leaflet';
import React from 'react';

type props = {
    phoneno: string;
    email: string;
    website: string;
    instagram: string;
    facebook: string;
    twitter: string;
    youtube: string;
    latitude: number;
    longitude: number;
    maplink: string;
}

const RestaurantInfo: React.FC<props> = ({
    phoneno, email, website, facebook, instagram, twitter, youtube, latitude, longitude, maplink

}) => {
    return (
        <div className="Main">
            <div className='d-flex'>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Phone Number</h6>
                    <p className='sub-detail-data'>{phoneno ? phoneno : "--"}</p>
                </div>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Email</h6>
                    <p className='sub-detail-data'>{email ? email : "--"}</p>
                </div>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Website</h6>
                    <p className='sub-detail-data'>{website ? website : "--"}</p>
                </div>
            </div>
            <div className='d-flex'>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Facebook</h6>
                    <p className='sub-detail-data'>{facebook ? facebook : "--"}</p>
                </div>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Instagram</h6>
                    <p className='sub-detail-data'>{instagram ? instagram : "--"}</p>
                </div>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">X</h6>
                    <p className='sub-detail-data'>{twitter ? twitter : "--"}</p>
                </div>
            </div>
            <div className='d-flex'>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Youtube</h6>
                    <p className='sub-detail-data'>{youtube ? youtube : "--"}</p>
                </div>
                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Latitude & Longitude</h6>
                    <p className='sub-detail-data'>{latitude ? latitude : "--"} & {longitude ? longitude : "--"}</p>
                </div>

                <div className='col-lg-4'>
                    <h6 className="mb-1 sub-detail-head">Map Link</h6>
                    <p className='sub-detail-data'>{maplink ? maplink : "--"}</p>
                </div>
            </div>
        </div>
    );
}

export default RestaurantInfo;
