import React, { useState } from "react";
import "./RestaurantDetailsScreen.css";
import { Card } from "primereact/card";
import Pillsdetails from "../detail-pills/detail-pills.components";
import { Tooltip } from "primereact/tooltip";
import Zomatoicon from "../../assets/icons/zomatoicon.svg";
import Youtubeicon from "../../assets/icons/youtubeicon.png";
import Googleicon from "../../assets/icons/googleicon.svg";
import Swiggyicon from "../../assets/icons/swiggyicon.svg";
import { Dialog } from "primereact/dialog";
import YouTube, { YouTubeProps } from "react-youtube";
import Dineout from "../../assets/images/Dineout.svg";
import { Skeleton } from "primereact/skeleton";

type props = {
  cuisine: any[];
  preference: string[];
  foodtype: any[];
  eaterytype: any[];
  ambience: string;
  values: any[];
  serving: string[];
  serviceoptions: string[];
  mealoftheday: string[];
  specialitems: string[];
  averagecost: string;
  diettype: any[];
};
const Restaurantdetails: React.FC<props> = ({
  cuisine,
  specialitems,
  preference,
  foodtype,
  eaterytype,
  ambience,
  values,
  serving,
  serviceoptions,
  mealoftheday,
  averagecost,
  diettype,
}) => {
  const [lvisible, setLvisible] = useState(false);

  console.log("specialitems", specialitems);

  return (
    <>
      <div className="Main">
        <div className="row">
          <div className="column1 d-flex">
            {/* preferences */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="preference mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Preference</h6>
                    <div className="d-flex">
                      {preference.length === 0 ? (
                        <span>--</span>
                      ) : (
                        preference.map((tag, i) => (
                          <span className="span-data" key={"Preference" + i}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* average cost */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="about mb-4">
                {averagecost === "NaN" ? (
                  "--"
                ) : (
                  <>
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-0">Average Cost (per 2 persons)</h6>
                        <p className="span-data mb-0">₹{averagecost}</p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Ambience */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="ambience mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <div className="gap-2 d-flex align-items-start">
                      <div className="ambience">
                        <h6 className="mb-0">Ambience</h6>

                        <span className="span-data">
                          {ambience ? ambience : "--"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column2 d-flex">
            {/* serving */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="serving mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Servings</h6>
                    <div className="d-flex">
                      {serving.length === 0 ? (
                        <span>--</span>
                      ) : (
                        serving.map((tag, i) => (
                          <span className="span-data" key={"serving" + i}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>

                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* meal of the day */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="meal mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Meal Of The Day</h6>
                    <div className="d-flex">
                      {mealoftheday.length === 0 ? (
                        <span>--</span>
                      ) : (
                        mealoftheday.map((tag, i) => (

                          <span className="span-data" key={"mealoftheday"}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>

                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* food type */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="foodtype mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Food Type</h6>
                    <div className="d-flex">
                      {foodtype.length === 0 ? (
                        <span>--</span>
                      ) : (
                        foodtype.map((a, i) => (
                          <span className="span-data" key={"foodtype" + i}>
                            {i > 0 && ", "}
                            {a.name}
                          </span>

                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column3 d-flex">
            {/* diet type */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="eaterytype mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Diet Type</h6>
                    <div className="d-flex flex-wrap gap-1">
                      {diettype.length === 0 ? (
                        <span>--</span>
                      ) : (
                        diettype.map((tag, i) => (

                          <span className="span-data" key={"diettype" + i}>
                            {tag.name}
                            {i < diettype.length - 1 && ", "}
                          </span>

                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Values */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              {values[0] === "None" ? (
                ""
              ) : (
                <>
                  <div className="values mb-4">
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-0">Values</h6>

                        <p className="mb-0">
                          {values.length === 0 ? (
                            <span>--</span>
                          ) : (
                            values.map((tag, i) => (
                              <span key={i} className="span-data">
                                {i > 0 && ", "} {tag.name.trim()}
                              </span>
                            ))
                          )}
                        </p>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            {/* Service Options */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="preference mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Service Options</h6>
                    <div className="d-flex">

                      {serviceoptions.length === 0 ? (
                        <span>--</span>
                      ) : (
                        serviceoptions.map((tag, i) => (
                          <span className="span-data" key={"serviceoptions" + i}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column4 d-flex">
            {/* special items */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="meal mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Special Items</h6>
                    <p>
                      {specialitems.length === 0 ? (
                        <span>--</span>
                      ) : (
                        specialitems.map((tag, i) => (
                          <span key={i} className="span-data">
                            {i > 0 && ", "} {tag.trim()}
                          </span>
                        ))
                      )}
                    </p>
                  </>
                )}
              </div>
            </div>

            {/* eatery type */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="eaterytype mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Eatery Type</h6>
                    <div className="d-flex flex-wrap gap-1">
                      {eaterytype.length === 0 ? (
                        <span>--</span>
                      ) : (
                        eaterytype.map((tag, i) => (

                          <span className="span-data" key={"eaterytype" + i}>
                            {tag.name}
                            {i < eaterytype.length - 1 && ", "}
                          </span>
                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Cuisines */}
            <div className="gap-2 d-flex align-items-start col-lg-4">
              <div className="eaterytype mb-4">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-0">Cuisines</h6>
                    <span className="d-flex flex-wrap gap-1">
                      {cuisine.length === 0 ? (
                        <span>--</span>
                      ) : (
                        cuisine.map((tag, i) => (
                          <div className="" key={"cuisine" + i}>
                            <span className="span-data">
                              {tag.name}
                              {i < cuisine.length - 1 && ", "}
                            </span>
                          </div>
                        ))
                      )}
                    </span>

                  </>
                )}
              </div>
            </div>


          </div>


        </div>
      </div>
    </>
  );
};

export default Restaurantdetails;
