import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import "./dietitianCustomStepper.component.css";
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useForm } from 'react-hook-form';
import DropFileInput from '../../pages/restaurants/drop-file-input/drop-file-input';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { SubmitDietitian } from "../../api/dietitians.api";
import { getRefreshedToken, isTokenExpired } from "../../api/auth.api";
import { Toast } from 'primereact/toast';
import Loader from '../ui/loader/loader.components';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { Certificate } from 'crypto';

interface dietitianStepper {
  steps: string[];
  images: string[];
  stepdesc: string[];
}

type resObj = {
  dietitiantitle: string;
  dietitianname: string;
  gender: string;
  speciality: any[];
  profession: string[];
  degrees: { degree: string; college: string; yearOfCompletion: string }[];
  certification: string;
  experience: string;
  languages: string[];
  about: string[];
  services: string[];
  awards: string[];
  membership: string[];
  clinicname: string;
  latitude: string;
  longitude: string;
  clinicaddress: string;
  locality: string;
  city: string;
  district: string;
  state: string;
  country: string;
  location: string;
  pincode: string;
  phoneno: string;
  mode: string[];
  registereddietitian: string;
  idamembershipnumber: string;
  email: string;
  fees: string;
  timings: any[];
  dietitianwebsite: string;
  dietitianfacebookurl: string;
  dietitianinstaurl: string;
  dietitianyoutubeurl: string;
  dietitiantwitterurl: string;
  clinicemail: string;
  clinicphoneno: string;
  clinicwebsite: string;
  clinicfacebookurl: string;
  clinicinstaurl: string;
  clinicyoutubeurl: string;
  clinictwitterurl: string;
  socailmedia: string;
  dietitianimages: string[];
  clinicimages: string[];
  certificationimages: string[];

}

const initialState = {
  dietitiantitle: "",
  dietitianname: "",
  gender: "",
  speciality: [],
  profession: [],
  degrees: [{ degree: "", college: "", yearOfCompletion: "" }],
  certification: "",
  experience: "",
  languages: [],
  about: [],
  services: [],
  awards: [],
  membership: [],
  clinicname: "",
  clinicaddress: "",
  latitude: "",
  longitude: "",
  locality: "",
  city: "",
  district: "",
  state: "",
  country: "",
  location: "",
  pincode: "",
  phoneno: "",
  mode: [],
  registereddietitian: "",
  idamembershipnumber:"",
  email: "",
  fees: "",
  timings: [],
  dietitianwebsite: "",
  dietitianfacebookurl: "",
  dietitianinstaurl: "",
  dietitianyoutubeurl: "",
  dietitiantwitterurl: "",
  clinicemail: "",
  clinicphoneno:"",
  clinicwebsite: "",
  clinicfacebookurl: "",
  clinicinstaurl: "",
  clinicyoutubeurl: "",
  clinictwitterurl: "",
  socailmedia: "",
  dietitianimages: [],
  clinicimages: [],
  certificationimages: [],

}

// interface StepDataBase {
//   completed: boolean;
//   data: string | number | boolean | object | any[];
// }

// interface StepData {
//   [key: string]: StepDataBase;
// }

interface StepStatus {
  completed: boolean;
}

interface StepData {
  [key: string]: StepStatus;
}

const DietitianCustomStepper: React.FC<dietitianStepper> = ({
  steps, images, stepdesc
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(Array(steps.length).fill(false));
  const [stepColors, setStepColors] = useState(Array(steps.length).fill(""));
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();
  const [resObj, setResObj] = useState<resObj>(initialState);
  const [isAdding, setIsadding] = useState(false);
  const toast = useRef<Toast>(null);
  const [medicalregistrationproof, setMedicalregistrationproof] = useState<any[]>([]);
  const [dietitianimage, setDietitianimage] = useState<any[]>([]);
  const [clinicimages, setClinicimages] = useState<any[]>([]);
  const [title, setTitle] = useState('');
  const [Gender, setGender] = useState('');
  const [profession, setProfession] = useState<any[]>([]);
  const [moc, setMoc] = useState<string[]>([]);
  const [aboutdietitian, setaboutdietitian] = useState<any>(null);
  const [imagesUploaded, setImagesUploaded] = useState(false);
  const [localLanguages, setLocalLanguages] = useState<string>("");
  const [localServices, setLocalServices] = useState<string>("");
  const [registeredCheck, setRegisteredCheck] = useState('');

  const show = (message: any, severity: any, summary: any) => {
    console.log("Outside of toast");
    if (toast.current) {
      console.log("going in toast");
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("Toast not available");
    }
  };


  const defaultValues = {
    dietitianname: "",
    experience: "",
    fees: "",
    clinicname: "",
    latitude: "",
    longitude: "",
    address: "",

  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    // getValues,
    reset,
  } = useForm({ defaultValues });
  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];

  const fetchAddress = async (lat: any, long: any) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
      );
      const data = await response.json();
      console.log(data);
  
      let address = '';
      let district = '';
      let city = '';
      let state = '';
      let pincode = '';
      let countryName = '';
      let locality = '';  
  
      data.results.forEach((result: any) => {
        result.address_components.forEach((component: any) => {
          if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
            address += component.long_name + ', ';
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (component.types.includes('administrative_area_level_3')) {
            district = component.long_name;
          } else if (component.types.includes('postal_code')) {
            pincode = component.long_name;
          } else if (component.types.includes('country')) {
            countryName = component.long_name;
          } 

          else if (component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('neighborhood')) {
            locality = component.long_name;
          }
        });
      });
  

      locality = locality || address.slice(0, -2); 
  
      setResObj({
        ...resObj,
        locality: locality,  
        district: district,
        city: city,
        state: state,
        pincode: pincode,
        country: countryName,
      });
  
    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };


  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);

  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter((day) => checkedDays[day]);

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);

        // Directly set the updatedTimings without wrapping it in an array
        //setResObj({ ...resObj, timings: updatedTimings });

        return updatedTimings;
      });
    }
  };




  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };

  const splitaray = (val: string): string[] => {
    return val.split(',').map(item => item.trim()).filter(item => item.length > 0);
  };

  const handleNext = () => {

    if (dietitianimage.length > 5) {
      show(
        "You can upload up to 5 dietitian images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (clinicimages.length > 25){
      show(
        "You can upload up to 25 clinic images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (medicalregistrationproof.length > 5) {
      show(
        "You can upload up to 5 certificate images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }

    if (activeStep === 0) {
      setResObj({
        ...resObj,
        languages: splitaray(localLanguages),
        services: splitaray(localServices),
      });
    }

    // Validate the fields for the current step
    if (!validateFields()) {
      return; // If validation fails, do not proceed
    }

    // Create a copy of the current step data array
    const updatedStepData = [...stepData];

    // Update the current step status to "completed"
    updatedStepData[activeStep] = {
      ...updatedStepData[activeStep],
      completed: true,
    };

    // Create a copy of completedSteps and mark the current step as completed
    const updatedCompletedSteps = [...completedSteps];
    updatedCompletedSteps[activeStep] = true;

    // Update state with the new step data and completed steps
    setStepData(updatedStepData);
    setCompletedSteps(updatedCompletedSteps);

    // Move to the next step
    setActiveStep(prevStep => prevStep + 1);
  };




  const validateFields = (): boolean => {
    const show = (message: any, severity: any, summary: any) => {
      if (toast.current) {
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      }
    };

    let updatedStepData = [...stepData];

    if (activeStep === 0) {
      if (
        resObj.dietitianname.length === 0 ||
        resObj.dietitiantitle.length === 0 ||
        resObj.gender.length === 0 ||
        resObj.experience.length === 0 ||
        resObj.speciality.length === 0 ||
        resObj.profession.length === 0 ||
        resObj.languages.length === 0 ||
        resObj.services.length === 0 ||
        resObj.fees.length === 0 ||
        resObj.mode.length === 0 ||
        (!resObj.about || resObj.about.length === 0 || resObj.about[0].trim() === '') ||
        resObj.phoneno.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    } else if (activeStep === 1) {
      if (resObj.degrees.length === 0) {
        show("Please add at least one degree", "warn", "Warn");
        return false;
      }

      for (let i = 0; i < resObj.degrees.length; i++) {
        const degree = resObj.degrees[i];
        if (
          degree.degree.length === 0 

        ) {
          show("Please add at least one degree", "warn", "Warn");
          return false;
        }
      }
      updatedStepData[activeStep].completed = true;
    } else if (activeStep === 2) {
      if (
        resObj.clinicname.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.clinicaddress.length === 0 ||
        resObj.locality.length === 0 ||
        resObj.city.length === 0 ||
        resObj.district.length === 0 ||
        resObj.state.length === 0 ||
        resObj.country.length === 0 ||
        resObj.pincode.length === 0 
        
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    } else if (activeStep === 4) {
      if (
        resObj.dietitianimages.length === 0 ||
        resObj.clinicimages.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        return false;
      }
      updatedStepData[activeStep].completed = true;
    }
    setStepData(updatedStepData);
    return true;
  };



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    updateStepColors();
  };
  const updateStepColors = () => {
    const newStepColors = [...stepColors];
    newStepColors[activeStep] = "lightblue"; // Change this to the desired color
    setStepColors(newStepColors);
  };

  const titles = [
    { label: 'Dr.', value: 'Dr.' },
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Dt.', value: 'Dt.' }
  ];
  const gender = ["Male", "Female"];
  const registercheck = ["Yes", "No"];

  const onTitleChange = (e: any) => {
    setTitle(e.value);
    setResObj({ ...resObj, dietitiantitle: e.value });
  };

  const genderselection = (e: any) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
    setResObj({ ...resObj, gender: selectedGender });
  }

  const redistereddoctor = (e: any) => {
    const selectedregister = e.target.value;
    setResObj({ ...resObj, registereddietitian: selectedregister });
    setRegisteredCheck(selectedregister);
  }

  const handleProfession = (e: any) => {
    let _prof = [...profession];

    if (e.checked) _prof.push(e.value);
    else _prof.splice(_prof.indexOf(e.value), 1);

    setProfession(_prof);
    setResObj({ ...resObj, profession: _prof });
  };




  const modofconsult = (e: any) => {
    let _modeof = [...moc];
    if (e.checked) _modeof.push(e.value);
    else _modeof.splice(_modeof.indexOf(e.value), 1);
    setMoc(_modeof);
    setResObj({ ...resObj, mode: _modeof });
  };

  const About = (e: any) => {
    let value = e.target.value;

    // Trim leading spaces
    value = value.replace(/^\s+/, "");

    if (value.length > 0) {
      setResObj({
        ...resObj,
        about: [value],
      });
    } else {
      setResObj({
        ...resObj,
        about: [],
      });
    }
  };



  const handleservicechange = (e: any) => {
    const value = e.target.value.trimStart();
    setLocalServices(value);
    setResObj({
      ...resObj,
      services: splitaray(value)
    })
  }

  const handleLanguageChange = (e: any) => {
    const value = e.target.value.trimStart();
    setLocalLanguages(value);
    setResObj({
      ...resObj,
      languages: splitaray(value)
    });
  };


  const handlemembership = (e: any) => {
    const enteredMemberships = e.target.value.split(', ');
    const normalizedMemberships = enteredMemberships;
    setResObj({ ...resObj, membership: normalizedMemberships });
  };



  const awardsandrecog = (e: any) => {
    const enteredAwards = e.target.value.split(', ');
    const normalizedawards = enteredAwards;
    setResObj({ ...resObj, awards: normalizedawards });
  };





  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });

  const ResetForm = () => {
    console.log("resetting the form");
    setIsadding(true);
    window.location.reload();
    setResObj(initialState);
  };

  const SubmitDietitianObj = () => {
    if (dietitianimage.length > 5) {
      show(
        "You can upload up to 5 dietitian images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (clinicimages.length > 25){
      show(
        "You can upload up to 25 clinic images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }
    if (medicalregistrationproof.length > 5) {
      show(
        "You can upload up to 5 certificate images only.",
        "error",
        "Limit Exceeded"
      );
      return;
    }

    setIsadding(true);
    console.log("ResObj ", resObj);

    setTimeout(async () => {
      // Validate required fields
      if (
        !resObj.dietitianname ||
        !resObj.dietitiantitle ||
        !resObj.gender ||
        !resObj.experience ||
        !resObj.profession ||
        !resObj.languages ||
        !resObj.mode ||
        !resObj.speciality ||
        !resObj.services ||
        !resObj.fees ||
        (!resObj.about || resObj.about.length === 0 || resObj.about[0].trim() === '') ||
        !resObj.phoneno ||
        resObj.degrees.length === 0 ||
        !resObj.clinicname ||
        !resObj.latitude ||
        !resObj.longitude ||
        !resObj.clinicaddress ||
        !resObj.locality ||
        !resObj.city ||
        !resObj.state ||
        !resObj.district ||
        !resObj.pincode ||
        !resObj.country ||
        resObj.dietitianimages.length === 0 ||
        resObj.clinicimages.length === 0
      ) {
        show("Please check all Mandatory fields", "warn", "Warn");
        setIsadding(false);
        return;
      }

      const formData = new FormData();

      console.log("FormData before appending", formData);
      Object.entries(resObj).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (key === "degrees") {
            formData.append(key, JSON.stringify(value));
          } else {
            value.forEach((item) => {
              formData.append(key, item);
            });
          }
        } else {
          formData.append(key, String(value));
        }
      });

      const timingsJSON = JSON.stringify(selectedTimings);
      formData.append("timings", timingsJSON);
      if (isTokenExpired()) {
        await getRefreshedToken();
      } else {
        console.log("formData===========>", formData);
        await SubmitDietitian(formData)
          .then((response: any) => {
            if (response.status === 201) {
              setIsadding(true);
              console.log("Submission successful");
              show("Dietitian Added Successfully", "info", "Info");

              setTimeout(() => {
                ResetForm();
              }, 3000);
            } else {
              setIsadding(false);
              console.log("Invalid submission");
              show("Invalid Access", "error", "Error");
            }
          })
          .catch((error: any) => {
            console.log("Error: ", error.response.status);
            setIsadding(false);
            if (error.response.status === 403) {
              console.log("Token expired");
              show("Token Expired", "warn", "Warn");
            }
          });
      }
    }, 1000);
  };


  const [Specialties, setIsSpecialties] = useState<any[]>([]);
  const specialtieslist = [
    { name: "Clinical Dietitian" },
    { name: "Sports Dietitian" },
    { name: "Pediatric Dietitian" },
    { name: "Gerontological Dietitian" },
    { name: "General Dietitian" }
  ];

  const handleSpecialities = (e: any) => {
    let _specialties = [...Specialties];
  
    if (e.checked) {
      _specialties.push(e.value);
    } else {
      _specialties = _specialties.filter((item) => item !== e.value);
    }
  
    setIsSpecialties(_specialties);
    setResObj({ ...resObj, speciality: _specialties });
  };
  

  // Handles input changes for degree, college, and yearOfCompletion
  const handleInputChange = (index: number, field: string, value: string) => {
    // Create a shallow copy of degrees array to avoid direct mutation
    const updatedDegrees = resObj.degrees.map((degree, i) =>
      i === index ? { ...degree, [field]: value } : degree
    );

    // Set updated degrees array into resObj
    setResObj((prevResObj) => ({
      ...prevResObj,
      degrees: updatedDegrees,
    }));
  };

  // Adds a new degree entry
  const addDegree = () => {
    setResObj((prevResObj) => ({
      ...prevResObj,
      degrees: [...prevResObj.degrees, { degree: '', college: '', yearOfCompletion: '' }],
    }));
  };

  // Deletes a degree entry based on index
  const deleteDegree = (index: number) => {
    setResObj((prevResObj) => ({
      ...prevResObj,
      degrees: prevResObj.degrees.filter((_, i) => i !== index), // Filter out the degree by index
    }));
  };


  console.log("res obj check", resObj);


  const [stepData, setStepData] = useState([
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false },
    { completed: false }
  ]);

  const handleImageClick = (index: any) => {
    setActiveStep(index);
    validateFields(); // Trigger validation when a step is clicked
  };







  useEffect(() => {
    console.log("Updated stepData:", stepData);
    console.log("Updated Completed Steps:", completedSteps);
  }, [stepData, completedSteps]);



  return (
    <>
      {!isAdding ? "" : <Loader />}
      <div className="d-flex justify-content-center align-items-center">


        <div className="col d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center" style={{ height: "85vh" }}>
            <ol className="c-stepper">
              {steps.map((label, index) => (
                <li key={label} className={`c-stepper__item`}>
                  <img
                    className="c-stepper__item__img"
                    src={images[index]}
                    style={{ fill: "#00ff00" }}
                    // onClick={() => handleImageClick(index)}
                  />
                  <div className="c-stepper__content">
                    <h3 className="c-stepper__title">Step {index + 1}</h3>
                    <p className="c-stepper__disc">{stepdesc[index]}</p>
                    <p className={`fs-12 ${index === activeStep ? "in-progress" : stepData[index]?.completed ? "completed" : ""}`}>
                      {index === activeStep ? "In progress" :
                        stepData[index]?.completed ? "Completed" : " "}
                    </p>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>


        <div className="col">
          <div className="card details-card" style={{ width: "calc(100vw - 60vw)", height: "92vh", borderRadius: "12px", }} >
            <div className="next-card card-body">
              <Toast ref={toast}></Toast>

              {activeStep === 0 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Dietitian Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className="row">
                              <div className="flex flex-column">
                                <Controller
                                  name="dietitianname"
                                  control={control}
                                  rules={{
                                    required: "Dietitian name is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Dietitian Name <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container col-12 p-0">
                                        <InputText
                                          placeholder="Enter Dietitian Name"
                                          value={resObj.dietitianname}
                                          className={`col-10 name-area ${fieldState.error ? "p-invalid" : ""}`}
                                          style={{ borderRight: "none" }}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            value = value.trimStart();
                                            if (value.length > 0) {
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                dietitianname: value,
                                              });
                                            } else {
                                              setResObj({
                                                ...resObj,
                                                dietitianname: '',
                                              });
                                            }
                                          }}
                                        />
                                        <Dropdown
                                          optionLabel="label"
                                          optionValue="value"
                                          value={title}
                                          options={titles}
                                          onChange={onTitleChange}
                                          placeholder="Title"
                                          className="tilte-area col-2 p-0"
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Gender <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className='d-flex gap-3'>
                                  {gender.map((e: any, index: any) => (
                                    <div key={index} className="submenu-item">
                                      <label className="d-flex gap-2">
                                        <input
                                          className="check-wrapper ml-1"
                                          type="radio"
                                          id={`custom-checkbox-${index}`}
                                          name="gender"
                                          onChange={genderselection}
                                          value={e}
                                        />
                                        <span className="gender-text-style">{e}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="experience"
                                  control={control}
                                  rules={{
                                    required: "Experience is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Experience <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Years of Experience"
                                          className={`col-12 ${fieldState.error ? "p-invalid" : ""}`}
                                          keyfilter={/^[0-9+\-\s]*$/}
                                          maxLength={2}
                                          value={resObj.experience}
                                          onChange={(e) => {
                                            const value = e.target.value.trim();
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              experience: value ? value : '',
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>


                            <div className="row mt-4">
                              <label className="ym-input-label mt-2">
                                Profession <b style={{ color: 'red' }}>*</b>
                              </label>
                              <div className="row">
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="profession1"
                                      name="professions"
                                      value="Dietitian"
                                      onChange={handleProfession}
                                      checked={profession.includes("Dietitian")}
                                    />
                                    <label htmlFor="preference1" className="pref-label">
                                      Dietitian
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="profession2"
                                      name="professions"
                                      value="Nutritionist"
                                      onChange={handleProfession}
                                      checked={profession.includes("Nutritionist")}
                                    />
                                    <label htmlFor="preference2" className="pref-label">
                                      Nutritionist
                                    </label>
                                  </div>
                                </div>

                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Languages <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Add Languages (comma separated)"
                                    className="col-12"
                                    value={localLanguages}
                                    onChange={handleLanguageChange}
                                  />
                                </div>
                              </div>
                            </div>



                            <div className="row mt-4">
                              <label className="ym-input-label mt-2">
                                Mode of Consultation <b style={{ color: 'red' }}>*</b>
                              </label>
                              <div className="row">
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="consult1"
                                      name="consultation"
                                      value="Online"
                                      onChange={modofconsult}
                                      checked={moc.includes("Online")}
                                    />
                                    <label htmlFor="consult1" className="pref-label">
                                      Online
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="consult2"
                                      name="consultation"
                                      value="Offline"
                                      onChange={modofconsult}
                                      checked={moc.includes("Offline")}
                                    />
                                    <label htmlFor="consult2" className="pref-label">
                                      Offline
                                    </label>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className='row mt-4'>
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Specialities <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="row">
                                  {specialtieslist.map((specialty, index) => (
                                    <div className="col-4" key={index}>
                                      <div className="flex align-items-center gap-2">
                                        <Checkbox
                                          inputId={`specialty${index}`}
                                          name="specialties"
                                          value={specialty.name}
                                          onChange={handleSpecialities}
                                          checked={Specialties.includes(specialty.name)}
                                        />
                                        <label htmlFor={`specialty${index}`} className="pref-label">
                                          {specialty.name}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Services <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter any 5 Services (comma separated)"
                                    className="col-12"
                                    value={localServices}
                                    onChange={handleservicechange}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Are you a Registered Dietitian(RD)
                                </label>
                                <div className='d-flex gap-3'>
                                  {registercheck.map((e: any, index: any) => (
                                    <div key={index} className="filter-item">
                                      <label className="d-flex gap-2">
                                        <input
                                          className="check-wrapper ml-1"
                                          type="radio"
                                          id={`custom-checkbox-${index}`}
                                          name="registeredcheck"
                                          onChange={redistereddoctor}
                                          value={e}
                                        />
                                        <span className="gender-text-style">{e}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {registeredCheck === "Yes" && (
                              <div className="row mt-4">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">IDA Membership Number</label>
                                  <InputText
                                    className="col-12"
                                    placeholder="Add Membership Id"
                                    aria-describedby="membership-help"
                                  value={resObj.idamembershipnumber}
                                  onChange={(e) => {
                                    let value = e.target.value.trimStart();
                                    setResObj({
                                      ...resObj,
                                      idamembershipnumber: value.length > 0 ? value : '',
                                    });
                                  }}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="fees"
                                  control={control}
                                  rules={{
                                    required: "Fees amount is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Fees <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Fees"
                                          className={`col-12 ${fieldState.error ? "p-invalid" : ""}`}
                                          keyfilter={/^[0-9+\-\s]*$/}
                                          value={resObj.fees}
                                          onChange={(e) => {
                                            const value = e.target.value.trim(); // Trim spaces from the input
                                            if (value) {
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                fees: value,
                                              });
                                            } else {
                                              setResObj({
                                                ...resObj,
                                                fees: '',
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  About Dietitian <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputTextarea
                                    placeholder="Enter Dietitian Description"
                                    rows={3}
                                    value={resObj.about.join('')}
                                    className='w-full'
                                    onChange={(e) => { About(e); }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Phone Number <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Phone Number"
                                      keyfilter={/^[0-9+\-\s]*$/}
                                      maxLength={14}
                                      value={resObj.phoneno}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            phoneno: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            phoneno: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Email
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Email"
                                      className="col-12"
                                      value={resObj.email}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            email: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            email: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Website
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Website URL"
                                    className="col-12"
                                    value={resObj.dietitianwebsite}
                                    onChange={(e) => {
                                      const value = e.target.value.trim();

                                      if (value.length > 0) {
                                        setResObj({
                                          ...resObj,
                                          dietitianwebsite: value,
                                        });
                                      } else {
                                        setResObj({
                                          ...resObj,
                                          dietitianwebsite: '',
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Facebook
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Facebook URL"
                                      value={resObj.dietitianfacebookurl}
                                      className="col-12"
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          dietitianfacebookurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Instagram
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Instagram URL"
                                      value={resObj.dietitianinstaurl}
                                      className="col-12"
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          dietitianinstaurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Youtube
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Youtube URL"
                                      className="col-12"
                                      value={resObj.dietitianyoutubeurl}
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          dietitianyoutubeurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    X
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter X URL"
                                      className="col-12"
                                      value={resObj.dietitiantwitterurl}
                                      onChange={(e) => {
                                        let value = e.target.value.trimStart(); // Trim leading spaces
                                        // Update the state only if the value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          dietitiantwitterurl: value.length > 0 ? value : '',
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Education Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className='d-flex flex-column gap-3'>
                              {resObj.degrees.map((degree, index) => (
                                <div key={index} className="degree-entry">
                                  <div className="row">
                                    <div className="flex flex-column">
                                      <label className="ym-input-label">
                                        Degree <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Degree"
                                          className="col-12"
                                          value={degree.degree}
                                          onChange={(e) => {
                                            // Get the input value and trim leading spaces
                                            const rawValue = e.target.value;
                                            const trimmedValue = rawValue.trimStart();

                                            // Only update if the trimmed value is not empty
                                            if (trimmedValue.length > 0) {
                                              handleInputChange(index, 'degree', trimmedValue);
                                            } else {
                                              handleInputChange(index, 'degree', ''); // Set to empty string if value is only spaces
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-3">
                                    <div className="flex flex-column">
                                      <label className="ym-input-label">
                                        College/Institute 
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter College/Institute"
                                          className="col-12"
                                          value={degree.college}
                                          onChange={(e) => {
                                            // Get the input value and trim leading spaces
                                            const rawValue = e.target.value;
                                            const trimmedValue = rawValue.trimStart();

                                            // Only update if the trimmed value is not empty
                                            if (trimmedValue.length > 0) {
                                              handleInputChange(index, 'college', trimmedValue);
                                            } else {
                                              handleInputChange(index, 'college', ''); // Set to empty string if value is only spaces
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>


                                  <div className="row mt-3">
                                    <div className="flex flex-column">
                                      <label className="ym-input-label">
                                        Year of Completion 
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Year of Completion"
                                          keyfilter={/^[0-9+\-\s]*$/}
                                          className="col-12"
                                          value={degree.yearOfCompletion}
                                          onChange={(e) => {
                                            // Get the input value and trim leading spaces
                                            const rawValue = e.target.value;
                                            const trimmedValue = rawValue.trimStart();

                                            // Only update if the trimmed value is not empty
                                            if (trimmedValue.length > 0) {
                                              handleInputChange(index, 'yearOfCompletion', trimmedValue);
                                            } else {
                                              handleInputChange(index, 'yearOfCompletion', ''); // Set to empty string if value is only spaces
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {
                                    index == 0 && <div className="p-0 d-flex justify-content-start align-items-center mt-3">
                                      <Button className="Adddegree_btn" onClick={addDegree}>
                                        <i className="pi pi-plus-circle mr-1" style={{ fontSize: '12px' }}></i> Add Degree
                                      </Button>
                                    </div>
                                  }

                                  {index > 0 && resObj.degrees.length > 1 && (
                                    <Button className="DeleteDegree_btn mt-3" onClick={() => deleteDegree(index)}>
                                      <i className="pi pi-trash mr-1" style={{ fontSize: '12px' }}></i> Delete
                                    </Button>
                                  )}
                                </div>
                              ))}


                            </div>


                            <div className="row mt-3">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Certification
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Certification"
                                    className="col-12"
                                    value={resObj.certification}
                                    onChange={(e) => {
                                      // Get the input value and trim leading spaces
                                      const rawValue = e.target.value;
                                      const trimmedValue = rawValue.trimStart();

                                      // Update state with trimmed value
                                      setResObj({
                                        ...resObj,
                                        certification: trimmedValue || '', // Set to empty string if value is only spaces
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="row mt-3">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Memberships
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Memberships"
                                    value={resObj.membership.join(', ')}
                                    className="col-12"
                                    onChange={(e) => {
                                      const value = e.target.value.trim();
                                      if (value) {
                                        handlemembership(e);
                                      } else {
                                        handlemembership({ ...e, target: { ...e.target, value: '' } });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Awards & Recognitions
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Awards & Recognitions"
                                    value={resObj.awards.join(', ')}
                                    className="col-12"
                                    onChange={(e) => {
                                      const value = e.target.value.trim();
                                      if (value) {
                                        awardsandrecog(e);
                                      } else {
                                        awardsandrecog({ ...e, target: { ...e.target, value: '' } });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>



                            <div className="row mt-3">
                              <div className="">
                                <div className="child-left" id="uploadimages">
                                  <label className="ym-input-label">
                                    Medical Registration Proof
                                  </label>
                                  <DropFileInput
                                    uploadedFiles={medicalregistrationproof}
                                    setUploadedFiles={setMedicalregistrationproof}
                                    resObj={resObj}
                                    setResObj={setResObj}

                                    purpose="medicalregistrationprooffile"
                                  />
                                </div>
                              </div>
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Clinic Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className="row">
                              <div className="flex flex-column">
                                <Controller
                                  name="clinicname"
                                  control={control}
                                  rules={{
                                    required: "Clinic name is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Clinic Name <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Clinic Name"
                                          className={`col-12 ${fieldState.error ? "p-invalid" : ""}`}
                                          value={resObj.clinicname}
                                          onChange={(e) => {
                                            const rawValue = e.target.value;
                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                            if (trimmedValue && !/^\s*$/.test(trimmedValue)) { // Ensure value is not only spaces
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                clinicname: trimmedValue,
                                              });
                                            } else {
                                              setResObj({
                                                ...resObj,
                                                clinicname: '', // Set to empty if value is only spaces
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="latitude"
                                    control={control}
                                    rules={{
                                      required: "Latitude is required.",
                                      pattern: {
                                        value: /^[+-]?([0-9]*[.])?[0-9]+$/,
                                        message: "Enter a valid latitude with only numbers and dots.",
                                      }
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label">
                                            Latitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter a valid latitude"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a valid latitude"
                                            id={field.name}
                                            value={resObj.latitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart();

                                              if (trimmedValue === "" || /^[+-]?([0-9]*[.]?[0-9]*)$/.test(trimmedValue)) {
                                                field.onChange(e);
                                                setResObj({
                                                  ...resObj,
                                                  latitude: trimmedValue,
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="longitude"
                                    control={control}
                                    rules={{
                                      required: "Longitude is required.",
                                      pattern: {
                                        value: /^[+-]?([0-9]*[.])?[0-9]+$/,
                                        message: "Enter a valid longitude with only numbers and dots.",
                                      }
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label">
                                            Longitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter a valid longitude"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a valid longitude"
                                            id={field.name}
                                            value={resObj.longitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              const rawValue = e.target.value;
                                              const trimmedValue = rawValue.trimStart();

                                              // Allow the user to type a dot, even if the full input isn't yet valid
                                              if (trimmedValue === "" || /^[+-]?([0-9]*[.]?[0-9]*)$/.test(trimmedValue)) {
                                                field.onChange(e);
                                                setResObj({
                                                  ...resObj,
                                                  longitude: trimmedValue,
                                                });
                                              }
                                            }}

                                          />
                                        </div>
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="d-flex justify-content-center align-items-center mt-4">
                              <Button className="get-address" label="Get Address" severity="success" outlined aria-label="Search"
                                onClick={() => fetchAddress(resObj.latitude, resObj.longitude)}
                              />
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="address"
                                  control={control}
                                  rules={{ required: "Address is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Address <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputTextarea
                                          id={field.name}
                                          {...field}
                                          placeholder="Enter Address Line"
                                          value={resObj.clinicaddress}
                                          rows={3}
                                          style={{ width: "100%" }}
                                          onChange={(e) => {
                                            const rawValue = e.target.value;
                                            const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                            if (trimmedValue) { // Check if the trimmed value is not just spaces
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                clinicaddress: trimmedValue,
                                              });
                                            } else {
                                              setResObj({
                                                ...resObj,
                                                clinicaddress: '', // Set to empty if invalid
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                      {/* {getFormErrorMessage(field.name)} */}
                                    </>
                                  )}
                                />
                              </div>
                            </div>


                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Locality <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Locality"
                                      value={resObj.locality}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            locality: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            locality: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    City <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter City"
                                      value={resObj.city}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            city: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            city: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    District <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter District"
                                      value={resObj.district}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            district: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            district: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    State <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter State"
                                      value={resObj.state}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            state: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            state: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Country <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Country"
                                      value={resObj.country}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            country: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            country: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Pincode <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Pincode"
                                      value={resObj.pincode}
                                      maxLength={6}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        // Ensure only numbers and up to 6 digits are allowed for pincode
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (/^\d{0,6}$/.test(trimmedValue)) { // Validate pincode format
                                          setResObj({
                                            ...resObj,
                                            pincode: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            pincode: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                  Clinic Phone Number
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Clinic Phone Number"
                                      keyfilter={/^[0-9+\-\s]*$/}
                                      maxLength={14}
                                      value={resObj.clinicphoneno}
                                      className="col-12"
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinicphoneno: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinicphoneno: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                  Clinic Email
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Clinic Email"
                                      className="col-12"
                                      value={resObj.clinicemail}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinicemail: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinicemail: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Website
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Website URL"
                                    className="col-12"
                                    value={resObj.clinicwebsite}
                                    onChange={(e) => {
                                      const rawValue = e.target.value;
                                      const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                      if (trimmedValue) { // Check if the trimmed value is not just spaces
                                        setResObj({
                                          ...resObj,
                                          clinicwebsite: trimmedValue,
                                        });
                                      } else {
                                        setResObj({
                                          ...resObj,
                                          clinicwebsite: '', // Set to empty if invalid
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Facebook
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Facebook URL"
                                      className="col-12"
                                      value={resObj.clinicfacebookurl}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinicfacebookurl: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinicfacebookurl: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Instagram
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Instagram URL"
                                      className="col-12"
                                      value={resObj.clinicinstaurl}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinicinstaurl: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinicinstaurl: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Youtube
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Youtube URL"
                                      className="col-12"
                                      value={resObj.clinicyoutubeurl}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinicyoutubeurl: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinicyoutubeurl: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Twitter
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Twitter URL"
                                      className="col-12"
                                      value={resObj.clinictwitterurl}
                                      onChange={(e) => {
                                        const rawValue = e.target.value;
                                        const trimmedValue = rawValue.trimStart(); // Remove leading spaces
                                        if (trimmedValue) { // Check if the trimmed value is not just spaces
                                          setResObj({
                                            ...resObj,
                                            clinictwitterurl: trimmedValue,
                                          });
                                        } else {
                                          setResObj({
                                            ...resObj,
                                            clinictwitterurl: '', // Set to empty if invalid
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <div className="row m-0">
                    <div className="col-12">
                      <div className="child-lef t" id="Timings">
                        <h4 className="rest-detail-head">Timings</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                            <div className="row">
                              <label className="ym-input-label">Timings</label>
                              <div className="row">
                                {daysOfWeek.map((day) => (
                                  <div className="col-3" key={day}>
                                    <div className="d-flex align-items-center gap-2">
                                      <Checkbox
                                        inputId={`day${day}`}
                                        name="day"
                                        value={day}
                                        checked={checkedDays[day]}
                                        onChange={() => handleCheckboxChange(day)}
                                      />
                                      <label
                                        htmlFor={`day${day}`}
                                        className="pref-label"
                                      >
                                        {day}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">From</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={fromtimes}
                                    options={Timings}
                                    onChange={onFromTimeChange}
                                  />
                                </div>
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">To</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={totimes}
                                    options={Timings}
                                    onChange={onToTimeChange}
                                  />
                                </div>
                                <div className="mt-3">
                                  <button
                                    className="add-button"
                                    onClick={handleAddTiming}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-start align-items-center mt-3">
                                <div className="col-8">
                                  {Object.entries(selectedTimings).map(
                                    ([day, timing]) => (
                                      <div
                                        className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                        key={day}
                                      >
                                        <span className="day">{day}</span>
                                        <div className="row">
                                          <div className="col gap-1">
                                            {timing.map((time, index) => (
                                              <div className="col p-0 mt-1 gap-1">
                                                <span
                                                  className="time"
                                                  key={index}
                                                >
                                                  {time}
                                                </span>

                                                <i
                                                  className="bi bi-trash-fill"
                                                  style={{
                                                    color: "#818181",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    const updatedTimings = {
                                                      ...selectedTimings,
                                                    };
                                                    updatedTimings[day] =
                                                      updatedTimings[day].filter((t) => t !== time);

                                                    // Check if there are no times left, delete the entire day
                                                    if (
                                                      updatedTimings[day]
                                                        .length === 0
                                                    ) {
                                                      delete updatedTimings[day];
                                                    }

                                                    setSelectedTimings(
                                                      updatedTimings
                                                    );
                                                  }}
                                                ></i>
                                                <br />
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 4 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="uploadimages">
                        <h4>Upload Images</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                            <div className="">
                              <label className="ym-input-label">
                                Dietitian Images <b style={{ color: "red" }}>*</b> <span style={{fontSize:"12px"}}>(Add a min 1 or a max 5 Dietitian Images)</span>
                                
                              </label>
                              <DropFileInput
                                uploadedFiles={dietitianimage}
                                setUploadedFiles={setDietitianimage}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="DietitianImage"
                              />
                            </div>
                            <div className="">
                              <label className="ym-input-label">
                                Clinic Images
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <DropFileInput
                                uploadedFiles={clinicimages}
                                setUploadedFiles={setClinicimages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="clinicimages"
                              />
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            </div>
            <div className="card-footer button-container">
              {activeStep === 0 ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="row">
                      <Button
                        className="next"
                        label="Next"
                        type="submit"
                        // icon="pi pi-check"
                        onClick={
                          handleNext
                        }

                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <Button
                    className="ym-add-button-previous"
                    label="Back"
                    onClick={handleBack}
                  ></Button>

                  {activeStep === 4 ? (
                    <Button
                      className="p-button-success ym-add-button"
                      label="Add Dietitian"
                      onClick={() => {
                        SubmitDietitianObj();
                      }}
                    // disabled={currentIndex === 3}
                    ></Button>
                  ) : (

                    <Button
                      className="next"
                      label="Next"
                      type="submit"
                      // icon="pi pi-check"
                      onClick={
                        handleNext
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default DietitianCustomStepper;