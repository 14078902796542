import React, { FunctionComponent } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "../pending-restaurant/pending-table.css";
import { Tooltip } from "primereact/tooltip";

type PendingTableProps = {
  customers: any;
  first: any;
  sortField: any;
  sortOrder: any;
  totalRecords: any;
  onPage: any;
  onSort: any;
  onFilter: any;
  filters: any;
  loading: any;
  countryBodyTemplate: any;
  representativeBodyTemplate: any;
  actionBodyTemplate: any;
};

var width = window.screen.width;
var c_rows = 10;
if (width >= 1920) {
  c_rows = 15;
} else if (width >= 1400 || width <= 1600) {
  c_rows = 10;
}

// Function to calculate the width of each column based on the number of columns
const calculateColumnWidth = (numberOfColumns: number) => `${100 / numberOfColumns}%`;

export const PendingTable: FunctionComponent<PendingTableProps> = ({
  customers,
  first,
  sortField,
  sortOrder,
  totalRecords,
  onPage,
  onSort,
  onFilter,
  filters,
  loading,
  countryBodyTemplate,
  representativeBodyTemplate,
  actionBodyTemplate,
}) => {
  const numberOfColumns = 4; 
  const columnWidth = calculateColumnWidth(numberOfColumns); 

  return (
    <>
      <DataTable
        value={customers}
        lazy
        filterDisplay="row"
        responsiveLayout="scroll"
        paginator={totalRecords >= 10}
        first={first}
        rows={10}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        onFilter={onFilter}
        filters={filters}
        loading={loading}
      >
        <Column
          field="name"
          header="Restaurant Name"
          sortable
          filter
          filterPlaceholder="Search by Name"
          style={{ width: columnWidth }} 
        />
        <Column
          field="city"
          sortable
          header="City"
          filter
          filterPlaceholder="Search by City"
          style={{ width: columnWidth }}
        />
        <Column
          field="eaterytype"
          header="Eatery Type"
          style={{ fontFamily: 'roboto', width: columnWidth }} 
          body={(rowData) => {
            const eateryTypes = rowData.eaterytype || [];
            const typeNames = eateryTypes.map((type: any) => type.name);
            return typeNames.join(', ');
          }}
        />
        <Column
          header="Action"
          className="action_btn"
          body={actionBodyTemplate}
          exportable={false}
          style={{ minWidth: "8rem", fontFamily: "roboto", width: columnWidth }} 
        ></Column>
      </DataTable>
    </>
  );
};
