import React, { FunctionComponent } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";
import "../approve-restaurant/approve-table.css";

type ApproveTableProps = {
    customers: any,
    first: any,
    sortField: any,
    sortOrder: any,
    totalRecords: any,
    onPage: any,
    onSort: any,
    onFilter: any,
    filters: any,
    loading: any,
    countryBodyTemplate: any,
    representativeBodyTemplate: any,
    actionBodyTemplate: any
};

const width = window.screen.width;
let c_rows = 10;
if (width >= 1920) {
    c_rows = 15;
} else if (width >= 1400 || width <= 1600) {
    c_rows = 10;
}

// Function to calculate the width of each column
const calculateColumnWidth = (numberOfColumns: number) => `${100 / numberOfColumns}%`;

export const ApproveTable: FunctionComponent<ApproveTableProps> = ({
    customers,
    first,
    sortField,
    sortOrder,
    totalRecords,
    onPage,
    onSort,
    onFilter,
    filters,
    loading,
    countryBodyTemplate,
    representativeBodyTemplate,
    actionBodyTemplate
}) => {
    const numberOfColumns = 4; // Adjust this based on the columns you want (manually set or dynamically calculated)

    // Calculate dynamic column width
    const columnWidth = calculateColumnWidth(numberOfColumns);

    return (
        <>
            <DataTable
                value={customers}
                lazy
                filterDisplay="row"
                responsiveLayout="scroll"
                paginator={totalRecords >= 10}
                first={first}
                rows={10}
                totalRecords={totalRecords}
                onPage={onPage}
                onSort={onSort}
                sortField={sortField}
                sortOrder={sortOrder}
                onFilter={onFilter}
                filters={filters}
                loading={loading}
            >
                <Column
                    field="name"
                    header="Restaurant Name"
                    style={{ fontFamily: 'roboto', width: columnWidth }} // Apply dynamic width
                    sortable
                    filter
                    filterPlaceholder="Search by Name"
                />
                <Column
                    field="city"
                    sortable
                    header="City"
                    style={{ fontFamily: 'roboto', width: columnWidth }} 
                    filterField="city"
                    filter
                    filterPlaceholder="Search by City"
                />
                <Column
                    field="eaterytype"
                    header="Eatery Type"
                    style={{ fontFamily: 'roboto', width: columnWidth }} 
                    body={(rowData) => {
                        const eateryTypes = rowData.eaterytype || [];
                        const typeNames = eateryTypes.map((type: any) => type.name);
                        return typeNames.join(', ');
                    }}
                />
                <Column
                    header="Action"
                    className='action_btn'
                    body={actionBodyTemplate}
                    style={{ fontFamily: 'roboto', minWidth: '8rem', width: columnWidth }} 
                    exportable={false}
                />
            </DataTable>
        </>
    );
};
