import React, { useRef, useState } from 'react';
import { Galleria } from 'primereact/galleria';
import './storeImages.component.css';
import { sas } from '../../config/constants';

type Props = {
  storeimages: string[];
  thumbnail: string[];
};

const StoreImages: React.FC<Props> = ({ storeimages, thumbnail }) => {
  const galleriaThumbnail = useRef<Galleria>(null);
  const galleriaStore = useRef<Galleria>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);


  const itemTemplate = (item: any) => {
    return <img src={item + '?' + sas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
  };

 
  const thumbnailTemplate = (item: any) => {
    return <img src={item + '?' + sas} alt={item.alt} style={{ display: 'block' }} />;
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowRight') {

      setActiveIndex((prevIndex) => (prevIndex + 1) % storeimages.length);
    } else if (e.key === 'ArrowLeft') {
    
      setActiveIndex((prevIndex) => (prevIndex - 1 + storeimages.length) % storeimages.length);
    }
  };

  return (
    <div
      className="d-flex flex-column gap-4 p-3"
      onKeyDown={handleKeyDown}
      tabIndex={0} 
      style={{ outline: 'none' }} 
    >
      <div className="d-flex flex-column gap-2">
        <div className="picheads-store">
          Thumbnail Photos ({thumbnail.length})
        </div>
        <div className="d-flex flex-wrap pt-2 rest-img row">
          <Galleria
            ref={galleriaThumbnail}
            value={thumbnail}
            numVisible={7}
            style={{ maxWidth: '850px' }}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          />
          <div className="d-flex">
            {thumbnail &&
              thumbnail.map((image: any, index: any) => {
                return (
                  <div key={index}>
                    <img
                      src={image + '?' + sas}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setActiveIndex(index);
                        galleriaThumbnail.current?.show();
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column gap-2">
        <div className="picheads-store">
          Organic Store Photos ({storeimages.length})
        </div>
        <div className="pt-2 ">
          <Galleria
            ref={galleriaStore}
            value={storeimages}
            numVisible={7}
            style={{ maxWidth: '850px' }}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          />
          <div className="image_gallery">
            {storeimages &&
              storeimages.map((image: any, index: any) => {
                return (
                  <div className='rest-img' key={index}>
                    <img
                      src={image + '?' + sas}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setActiveIndex(index);
                        galleriaStore.current?.show();
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreImages;
