import React, { useEffect, useRef, useState } from "react";
import "./profile.components.css";
import "react-alice-carousel/lib/alice-carousel.css";
import { useLocation } from "react-router-dom";
import Loader from "../ui/loader/loader.components";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import axios from "axios";
import HttpInstance from "../../api/http.api";
import { UploadImage } from "../../api/dashboard.api";
import { GetAgentById } from "../../api/auth.api";
import Indiaflag from '../../assets/images/indiaflag.png';
import { Chip } from 'primereact/chip';

type props = {};
const Profiledetails: React.FC<props> = ({ }) => {
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const [agentData, setAgentdata] = useState<any>({});
  const [agentImage, setAgentimage] = useState(String);

  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };
  const loadLazyData = async () => {
    //imitate delay of a backend call
    setIsadding(true);
    await GetAgentById().then((res) => {
      // then print response status
      console.warn(res.data.data);
      setAgentdata(res.data.data);
      setAgentimage(res.data.data.image);
    }).catch((err) => {
      console.log('err in get agent by id', err)
    });
    setIsadding(false);
  };
  const onUpload = () => {
    show(" Uploaded", "info", "Info");
  };

  useEffect(() => {
    setIsadding(true);
    loadLazyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [gradientColors, setGradientColors] = useState<any>([]);
  useEffect(() => {
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };
    const color1 = getRandomColor();
    const color2 = getRandomColor();
    setGradientColors([color1, color2])
  }, []);
  const gradientStyle = {
    background: `linear-gradient(135deg, ${gradientColors[0]}, ${gradientColors[1]})`,
    height: '200px',
  };
  const handleInputChange = (event: any) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = async (e: any) => {
      const formData = { image: e.target.result };
      await UploadImage(formData)
        .then((res) => {
          // then print response status
          if (res.data.status === 200) {
            show(" Image Uploaded ", "info", "Info");
            loadLazyData();
          } else {
            show(" Error while Uploading ", "error", "Error");
          }
        })
        .catch((err) => {
          if (err.response.status === 413) {
            show(" Please upload image less than 1 MB ", "error", "Error");
          }
        });
    };
  };

  const capitalizeFirstLetter = (str: string): string => {
    // console.log(str)
    if (str !== undefined) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return "";
  };
  return (
    <main>
      <Toast ref={toast}></Toast>
      {/* {!isAdding ? "" : <Loader />} */}
      <div>
        <div className="d-flex">
          <div className="p-2"></div>
          <div className="ml-auto "></div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-8">
            <div className="card mb-3 ym-chart-card p-0">
              <div className="card-body">
                <div style={gradientStyle}></div>
                <div className="profilepic">
                  {!agentImage || agentImage.length < 1 ? (
                    <img
                      src="https://www.gravatar.com/avatar/05dfd4b41340d09cae045235eb0893c3?d=mp"
                      alt="Stacie Hall"
                      className="img-fluid rounded-circle mb-2"
                      width="128"
                      height="128"
                    />
                  ) : (
                    <img
                      className="profilepic__image"
                      src={agentImage}
                      width="150"
                      height="150"
                      alt="Profibild"
                    />
                  )}
                  <div className="profilepic__content">
                    <span className="profilepic__icon">
                      <div className="image-upload">
                        <label htmlFor="file-input">
                          <i
                            className="pi pi-camera"
                            style={{ fontSize: "3rem" }}
                          ></i>
                        </label>
                        <input
                          id="file-input"
                          type="file"
                          className="form-control"
                          name="upload_file"
                          onChange={handleInputChange}
                        />
                      </div>
                      {/* <input type="file" className="form-control" name="upload_file" onChange={handleInputChange} /> */}
                    </span>
                    <span className="profilepic__text">Edit Profile</span>
                  </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <h5 className="card-title mb-2">
                  {capitalizeFirstLetter(agentData.name)}
                </h5>

                <span className="block text-500 font-medium mb-2"> <img src={Indiaflag} alt="india" style={{ width: "50px", fontFamily: "Rubik" }} /> Hyderabad, India</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-8">
                <div className="card mb-3 ym-chart-card">
                  <div className="card-body">
                    <h1 className="h6 card-title">About</h1>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-1"> Email Id &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{agentData.email}</li>
                      <li className="mb-1"> Mobile &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{agentData.mobile}</li>
                      <li className="mb-1"> City &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{agentData.city}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  );
};

export default Profiledetails;
