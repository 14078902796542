import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";

type props = {
  customers: any,
  first: any,
  sortField: any,
  sortOrder: any,
  totalRecords: any,
  onPage: any,
  onSort: any,
  onFilter: any,
  filters: any,
  loading: any,
  actionBodyTemplate: any
}

const PendingEventsTable: React.FC<props> = ({
  customers,
  first,
  sortField, sortOrder,
  totalRecords,
  onPage,
  onSort,
  onFilter,
  filters,
  loading,
  actionBodyTemplate
}) => {



  const rowsNo = () => {
    var width = window.screen.width;
    console.log("width" + width)
    if (width >= 1920) {
      return 13
    } else if (width > 1420 && width <= 1440) {
      return 9
    }
    else {
      return 10
    }
  }

  return (
    <DataTable
      value={customers}
      lazy
      filterDisplay="row"
      responsiveLayout="scroll"
      paginator={totalRecords >= 10}
      first={first}
      rows={10}
      totalRecords={totalRecords}
      onPage={onPage}
      onSort={onSort}
      sortField={sortField}
      sortOrder={sortOrder}
      onFilter={onFilter}
      filters={filters}
      loading={loading}

    >
      <Column field="eventstartdate" header="Date" sortable />
      <Column field="eventtitle" header="EventTitle" sortable />
      <Column field="city" sortable header="City" />
      <Column header="Action" className="action_btn" body={actionBodyTemplate} exportable={false} style={{ minWidth: '6rem' }}></Column>
    </DataTable>
  );
}

export default PendingEventsTable;
