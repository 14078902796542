import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from '../config/constants'


export const GetTotalRestbyAgentID = () => {
   const RespData = HttpInstance.get("/agentdashboard/restaurantcount")
   return RespData
}

const AgentHttpInstance = axios.create({
   baseURL: apiUrl,
   headers: {
      'Accept': '*/*',
      'Authorization': "Bearer " + localStorage.getItem('accessToken')
   }
})
export const GetEntriesByDay = () => {

   const RespData = axios.get(apiUrl + "/agentdashboard/agenttimedata", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken')
      }
   }
   );
   return RespData

}
export const GetRestaurantData = () => {

   const RespData = axios.get(apiUrl + "/agentdashboard/agentapdata", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken')
      }
   }
   );
   return RespData

}
export const GetOrganicStoreData = () => {
   return axios.get(apiUrl + "/agentdashboard/agentosdata", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken'),
      },
   });
};

export const GetDietitianData = () => {
   return axios.get(apiUrl + "/agentdashboard/agentdiedata", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken'),
      },
   });
};

export const GetFoodEventData = () => {
   return axios.get(apiUrl + "/agentdashboard/agentfedata", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken'),
      },
   });
};


export const UploadImage = (resObj: any) => {
   const respData = HttpInstance.post("/agent/uploadimage", resObj)
   return respData
}

export const GetCuisineCount = () => {

   const RespData = axios.get(apiUrl + "/agentdashboard/cuisinecount", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken')
      }
   }
   );
   return RespData

}
export const GetCategoryCount = () => {

   const RespData = axios.get(apiUrl + "/agentdashboard/categorycount", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken')
      }
   }
   );
   return RespData

}
export const GetRecentlyAdded = () => {

   const RespData = axios.get(apiUrl + "/agentdashboard/recentlyadded", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken')
      }
   }
   );
   return RespData

}

export const GetRecentTableData = (params: any) => {
   const RespData = HttpInstance.get("/agentdashboard/recentlyadded", { ...params })
   return RespData;
}
export const GetTopArea = () => {

   const RespData = axios.get(apiUrl + "/agentdashboard/topareas", {
      headers: {
         'Accept': '*/*',
         'Authorization': "Bearer " + localStorage.getItem('accessToken')
      }
   }
   );
   return RespData

}

export const TopAresCovered = () => {
   const RespData = axios.get(apiUrl + "/agentdashboard/topareas",
      {
         headers: {
            'Accept': '*/*',
            'Authorization': "Bearer " + localStorage.getItem('accessToken')
         }
      }
   );
   return RespData
}
//API for Top performing Restaurant 
export const TopPerformingRest = () => {
   const RespData = axios.get(apiUrl + "/agentdashboard/topperformingrest",
      {
         headers: {
            'Accept': '*/*',
            'Authorization': "Bearer " + localStorage.getItem('accessToken')
         }
      }
   );
   return RespData
}

export const StoreAndEventCount = () => {
   const RespData = axios.get(apiUrl + "/agentdashboard/storeeventcount",
      {
         headers: {
            'Accept': '*/*',
            'Authorization': "Bearer " + localStorage.getItem('accessToken')
         }
      }
   );
   return RespData
}

export const DietitianCount = () => {
   const RespData = axios.get(apiUrl + "/agentdashboard/dietitiancount",
      {
         headers: {
            'Accept': '*/*',
            'Authorization': "Bearer " + localStorage.getItem('accessToken')
         }
      }
   );
   return RespData
}
export const GetCityDataCount = () => {
   const RespData = axios.get(apiUrl + "/agentdashboard/getcitycount",
      {
         headers: {
            'Accept': '*/*',
            'Authorization': "Bearer " + localStorage.getItem('accessToken')
         }
      }
   );
   return RespData
}
