import React, { useEffect, useState } from 'react';
import "./dietitianDetails.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetDietitiandetailsbyID } from '../../../api/dietitians.api';
import { Skeleton } from 'primereact/skeleton';
import { Image } from "primereact/image";
import { sas } from "../../../config/constants"
import DietitianInfo from '../../../components/DietitianInfo/dietitianInfo';
import EducationalInfo from '../../../components/EducationalInfo/educationalInfo';
import ClinicInfo from '../../../components/ClinicInfo/clinicInfo';
import DietitianTimings from '../../../components/DietitianTimings/dietitianTimings';
import ClinicAndDietitianImages from '../../../components//ClinicAndDietitianImages/clinicAndDietitianImages';

const DietitianDetails = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    console.log(splitLocation);
    const { id } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState<number>(0);
    const [visible, setVisible] = useState(false);
    const [lvisible, setLvisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [isAdding, setIsadding] = useState(false);
    const [commentObj, setCommentObj] = useState({
        comment: "",
        approvalstatus: "",
    });

    const loadlazydata = () => {
        const loadLazyTimeout = setTimeout(async () => {
            setIsadding(true);
            const storeData = await GetDietitiandetailsbyID(id as string);

            console.log("storeData", storeData.data.data);

            setDietObj(storeData.data.data);
            setLvisible(false);
            setIsadding(false);
        }, 100);
    };
    useEffect(() => {
        setIsadding(true);
        loadlazydata();
        setLvisible(true);
    }, []);

    const [dietObj, setDietObj] = useState({
        about: '',
        agentname: "",
        approvalstatus: "",
        awards: [],
        certification: "",
        certificationimages: [],
        city: "",
        clinicaddress: "",
        clinicfacebookurl: "",
        clinicimages: [],
        clinicinstaurl: "",
        clinicname: "",
        clinicemail: "",
        clinicphoneno: "",
        clinictwitterurl: "",
        clinicwebsite: "",
        clinicyoutubeurl: "",
        comment: "",
        country: "",
        college: "",
        createdAt: "",
        dateadded: "",
        dietitianfacebookurl: "",
        dietitianimages: [],
        dietitianinstaurl: "",
        dietitianname: "",
        dietitiantitle: "",
        dietitiantwitterurl: "",
        dietitianwebsite: "",
        dietitianyoutubeurl: "",
        district: "",
        degree: "",
        degrees: [
            {
                degree: '',
                college: '',
                yearOfCompletion: ''
            }
        ],
        email: "",
        experience: "",
        fees: "",
        gender: "",
        languages: [],
        locality: "",
        location: { coordinates: [0.0, 0.0] },
        mode: [],
        membership: [],
        phoneno: "",
        pincode: "",
        registereddietitian: "",
        idamembershipnumber: "",
        services: [],
        speciality: [],
        profession: [],
        timings: [],
        yearofcompletion: "",
        state: "",
        _id: ""
    });


    console.log("diettttt", dietObj);

    const isRejectedDietitian = location.pathname.includes("/rejected");
    const isApprovedDietitian = location.pathname.includes("/approved");

    return (
        <main>
            <div>
                <Navbar />
                <section className="page-content">
                    <div className="ym-content-table mt-0">
                        <div className="page_title d-flex justify-content-between">
                            <div className="p-2 d-flex">
                                &nbsp;
                                <span
                                    className="back_rest_btn d-flex gap-2 align-items-center"
                                    onClick={() => {
                                        const currentPath = window.location.pathname;
                                        if (currentPath.includes("/pending")) {
                                            navigate(`/dietitian/pendingdietitian`);
                                        } else if (currentPath.includes("/approved")) {
                                            navigate(`/dietitian/approveddietitian`);
                                        } else if (currentPath.includes("/rejected")) {
                                            navigate(`/dietitian/rejecteddietitian`);
                                        }
                                    }}
                                >
                                    <i className="pi pi-arrow-left"></i>
                                    Dietitian Details
                                </span>
                            </div>

                            <div className="">
                                {!isApprovedDietitian && (
                                    <Button
                                        label="Edit Dietitian"
                                        icon="pi pi-pencil"
                                        className="p-button-rounded p-button-edit "
                                        onClick={() => {
                                            const currentPath = window.location.pathname;
                                            let dietitianType = 'pendingdietitian';
                                            if (currentPath.includes("/rejected")) {
                                                dietitianType = 'rejecteddietitian';
                                            }

                                            navigate(`/dietitians/${dietitianType}/editdietitian/${id}`);
                                        }}
                                        autoFocus
                                    />
                                )}
                            </div>

                        </div>
                        <hr />
                        <div className='ym-content-table d-flex'>
                            <div className="col-6 p-0">
                                <div className=" gap-3 d-flex align-items-center">
                                    <div className="row mb-1 view-gallery-cols mr-3" style={{ height: "160px", width: "160px" }}>
                                        <div className="pt-0 pl-0">

                                            <Image className='main-details-profile' src={dietObj.dietitianimages[0] + "?" + sas} alt="Image" preview />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-3">

                                        <div className="page-title">
                                            {!lvisible ? (
                                                <h1 className="ym-portal-heading">
                                                    {dietObj.dietitiantitle} {dietObj.dietitianname}
                                                </h1>
                                            ) : (
                                                <Skeleton width="10rem" className="mb-2"></Skeleton>
                                            )}
                                        </div>


                                        <div className="">
                                            {!lvisible ? (
                                                <div>
                                                    <p className="area-data mb-0">
                                                        {dietObj.gender}
                                                    </p>
                                                </div>
                                            ) : (
                                                <Skeleton width="5rem" className="mb-2"></Skeleton>
                                            )}
                                        </div>

                                        <div className="">
                                            {!lvisible ? (
                                                <div>
                                                    <p className="area-data mb-0">
                                                        {dietObj.profession.join('/')}
                                                    </p>

                                                </div>
                                            ) : (
                                                <Skeleton width="5rem" className="mb-2"></Skeleton>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {isRejectedDietitian && (
                                <div className='col-6 p-0'>
                                    <div className="">
                                        <div className="ambience">
                                            <h6 className="mb-2 rr-head">Rejection Reason</h6>
                                            <div className='reason-disc-box'>
                                                <span className="span-data">
                                                    {dietObj.comment ? dietObj.comment : "--"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>

                    <div className="row">
                        <TabView>
                            <TabPanel header="Dietitian Details">
                                <DietitianInfo
                                    experience={dietObj.experience}
                                    mode={dietObj.mode}
                                    fee={dietObj.fees}
                                    phone={dietObj.phoneno}
                                    email={dietObj.email}
                                    registereddietitian={dietObj.registereddietitian}
                                    languages={dietObj.languages}
                                    services={dietObj.services}
                                    specialities={dietObj.speciality}
                                    idamembershipnumber={dietObj.idamembershipnumber}
                                    about={dietObj.about}
                                    dietitianinstagram={dietObj.dietitianinstaurl}
                                    dietitiantwitter={dietObj.dietitiantwitterurl}
                                    dietitianyoutube={dietObj.dietitianyoutubeurl}
                                    dietitianfacebook={dietObj.dietitianfacebookurl}
                                    dietitianwebsite={dietObj.dietitianwebsite}
                                />
                            </TabPanel>
                            <TabPanel header="Education Details">
                                <EducationalInfo
                                    degrees={dietObj.degrees}
                                    certification={dietObj.certification}
                                    membership={dietObj.membership}
                                    awardsandrecognitions={dietObj.awards}
                                    certificationimages={dietObj.certificationimages}
                                />
                            </TabPanel>
                            <TabPanel header="Clinic Details">
                                <ClinicInfo
                                    clinicname={dietObj.clinicname}
                                    phone={dietObj.clinicphoneno}
                                    email={dietObj.clinicemail}
                                    address={dietObj.clinicaddress}
                                    locality={dietObj.locality}
                                    city={dietObj.city}
                                    state={dietObj.state}
                                    pincode={dietObj.pincode}
                                    latitude={dietObj.location.coordinates[1]}
                                    longitude={dietObj.location.coordinates[0]}
                                    clinicwebsite={dietObj.clinicwebsite}
                                    clinicinstagram={dietObj.clinicinstaurl}
                                    clinictwitter={dietObj.clinictwitterurl}
                                    clinicyoutube={dietObj.clinicyoutubeurl}
                                    clinicfacebook={dietObj.clinicfacebookurl}
                                />
                            </TabPanel>
                            <TabPanel header="Timings">
                                <DietitianTimings timings={dietObj.timings} />
                            </TabPanel>
                            <TabPanel header={`Photos (${(dietObj.clinicimages?.length || 0) + (dietObj.dietitianimages?.length || 0)})`}>
                                <ClinicAndDietitianImages
                                    clinicimages={dietObj.clinicimages}
                                    dietitianimages={dietObj.dietitianimages}
                                />
                            </TabPanel>
                        </TabView>
                    </div>
                </section>

            </div>
        </main>
    );
}

export default DietitianDetails;
