import React, { useEffect, useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
// import Logo from "../../assets/images/Layer1.svg";
import { apiUrl } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import "../dashboard/dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// import Cookies from 'js-cookie'
import Barchart from "../../components/ui/charts/bar-chart/bar-chart.components";
import Linechart from "../../components/ui/charts/line-chart/line-chart.components";
import Loader from "../../components/ui/loader/loader.components";
import restpic from "../../assets/images/restpic.png"
import orgpic from "../../assets/images/orgpic.png"
import dietpic from "../../assets/images/dietpic.png"
import calenderpic from "../../assets/images/calendarpic.png"
import personpic from "../../assets/images/personpic.png"
import {
  GetCategoryCount,
  GetCuisineCount,
  GetRecentTableData,

  GetTopArea,
  TopAresCovered,
  TopPerformingRest,
  StoreAndEventCount,
  DietitianCount,
  GetCityDataCount,
  GetRestaurantData,
  GetFoodEventData,
  GetDietitianData,
  GetOrganicStoreData,
} from "../../api/dashboard.api";
import { getRefreshedToken, isTokenExpired, logout } from "../../api/auth.api";
import axios from "axios";
import Doughnutchart from "../../components/ui/charts/doughnut-chart/doughnut-chart.components";
import Mapview from "../../components/ui/map/Mapview.component";
import { RecentTable } from "../../components/dashboard/recent.components";
import { DataTable } from "primereact/datatable";
import { Dropdown } from 'primereact/dropdown';
import { Column } from "primereact/column";
const Dashboard = () => {
  const [isAdding, setIsadding] = useState(false);
  const [reststate, setReststate] = useState<{ name: string }>({ name: 'Total' });
  const [storestate, setStorestate] = useState<{ name: string }>({ name: 'Total' });
  const [dietitianstate, setDietitianstate] = useState<{ name: string }>({ name: 'Total' });
  const [foodeventstate, setFoodeventstate] = useState<{ name: string }>({ name: 'Total' });
  const [usersuggeststate, setUsersuggeststate] = useState<{ name: string }>({ name: 'Total' });
  const [cityData, setCityData] = useState<any>([]);
  const [totalrest, settotalrest] = useState<any>(0);
  const [approverest, setapproverest] = useState<any>(0);
  const [pendingrest, setpendingrest] = useState<any>(0);
  const [rejectrest, setrejectrest] = useState<any>(0);
  const [totalstores, settotalstores] = useState<any>(0);
  const [approvestores, setapprovestores] = useState<any>(0);
  const [pendingstores, setpendingstores] = useState<any>(0);
  const [rejectstores, setrejectstores] = useState<any>(0);
  const [totaldietitians, setTotaldietitians] = useState<any>(0);
  const [approvedietitian, setApprovedietitian] = useState<any>(0);
  const [pendingdietitian, setPendingdietitian] = useState<any>(0);
  const [rejectdietitian, setRejectdietitian] = useState<any>(0);
  const [totalfoodevents, settotalfoodevents] = useState<any>(0);
  const [approvefoodevents, setapprovefoodevents] = useState<any>(0);
  const [pendingfoodevents, setpendingfoodevents] = useState<any>(0);
  const [rejectfoodevents, setrejectfoodevents] = useState<any>(0);
  const [NewApproved, setNewApproved] = useState<any>();
  const [NewPending, setNewPending] = useState<any>();
  const [NewDenied, setNewDenied] = useState<any>();
  const [CurrentDayTotal, setCurrentDayTotal] = useState<any>();
  const [Growth, setGrowth] = useState(0);
  const [locationsData, setLocationsData] = useState([]);
  const [topPerforming, setTopPerforming] = useState([]);
  const [totalRecentRecords, setTotalRecentRecords] = useState(0);
  const [Recentdata, setRecentData] = useState(undefined);
  const navigate = useNavigate();
  const [restaurantBarData, setRestaurantBarData] = useState({});
  const [organicStoreBarData, setOrganicStoreBarData] = useState({});
  const [dietitianBarData, setDietitianBarData] = useState({});
  const [foodEventBarData, setFoodEventBarData] = useState({});
  const [lineData1, setLineData1] = useState<any>({});
  const [cusinechartData, setCusineChartData] = useState({});
  const [cuisineoptions, setcuisineoptions] = useState({});
  const [categorychartData, setCategoryChartData] = useState({});
  const [catoptions, setcatoptions] = useState({});
  const [topareasData, setTopareasData] = useState({});
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  var width = window.screen.width;
  var c_rows = 10;
  if (width >= 1920) {
    c_rows = 15;
  } else if (width >= 1400 || width <= 1600) {
    c_rows = 10;
  }

  const [lazyParams, setLazyParams] = useState({
    approvalstatus: 1,
    first: 0,
    rows: c_rows,
    page: 0,
    sortField: "",
    sortOrder: 0,
  });

  const agenttimedata = async () => {
    if (isTokenExpired()) {
      await getRefreshedToken();
    } else {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Accept: "*/*",
        Authorization: "Bearer " + token,
      };
      try {
        const resData = await axios.get(
          apiUrl + "/agentdashboard/agenttimedata",
          { headers }
        );

        setLineData1({
          labels: resData.data.data.WeeklyReport.map(
            (report: any) => report._id
          ),

          datasets: [
            {
              label: "Total Restaurants",
              data: resData.data.data.WeeklyReport.map(
                (report: any) => report.count
              ),
              fill: false,
              backgroundColor: documentStyle.getPropertyValue("--green-200"),
              borderColor: documentStyle.getPropertyValue("--green-200"),
              tension: 0.4,
            },
          ],
        });

        if (resData.data.data.Growth === null) {
          setGrowth(0);
        } else {
          setGrowth(resData.data.data.Growth);
        }
      } catch (err: any) {
        // console.log("error agenttimedata", err);
        if (err.code === "ERR_BAD_REQUEST") {
          logout();
          navigate("/");
        }
      }
    }
  };

  const daysOfWeekMap = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const fetchRestaurantData = async () => {
    try {
      const response = await GetRestaurantData();

      const approvedCounts = new Array(7).fill(0);
      const rejectedCounts = new Array(7).fill(0);

      response.data.Result.forEach((report: any) => {
        const dayIndex = report._id - 1;
        approvedCounts[dayIndex] = report.approvedCount || 0;
        rejectedCounts[dayIndex] = report.rejectedCount || 0;
      });

      setRestaurantBarData({
        labels: daysOfWeekMap,
        datasets: [
          {
            label: "Approved Restaurants",
            data: approvedCounts,
            backgroundColor: "#9BDFC4",
            borderColor: "#9BDFC4",
            borderRadius: 100,
            barThickness: 5,
          },
          {
            label: "Rejected Restaurants",
            data: rejectedCounts,
            backgroundColor: "#FAAFBC",
            borderColor: "#FAAFBC",
            borderRadius: 100,
            barThickness: 5,
          },
        ],
      });
    } catch (error) {
      handleApiError(error);
    }
  };


  const fetchOrganicStoreData = async () => {
    try {
      const response = await GetOrganicStoreData();

      const approvedCounts = new Array(7).fill(0);
      const rejectedCounts = new Array(7).fill(0);

      response.data.Result.forEach((report: any) => {
        const dayIndex = report._id - 1;
        approvedCounts[dayIndex] = report.approvedCount || 0;
        rejectedCounts[dayIndex] = report.rejectedCount || 0;
      });

      setOrganicStoreBarData({
        labels: daysOfWeekMap,
        datasets: [
          {
            label: "Approved Organic Stores",
            data: approvedCounts,
            backgroundColor: "#9BDFC4",
            borderColor: "#9BDFC4",
            borderRadius: 100,
            barThickness: 5,
          },
          {
            label: "Rejected Organic Stores",
            data: rejectedCounts,
            backgroundColor: "#FAAFBC",
            borderColor: "#FAAFBC",
            borderRadius: 100,
            barThickness: 5,
          },
        ],
      });
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchDietitianData = async () => {
    try {
      const response = await GetDietitianData();

      const approvedCounts = new Array(7).fill(0);
      const rejectedCounts = new Array(7).fill(0);

      response.data.Result.forEach((report: any) => {
        const dayIndex = report._id - 1;
        approvedCounts[dayIndex] = report.approvedCount || 0;
        rejectedCounts[dayIndex] = report.rejectedCount || 0;
      });

      setDietitianBarData({
        labels: daysOfWeekMap,
        datasets: [
          {
            label: "Approved Dietitians",
            data: approvedCounts,
            backgroundColor: "#9BDFC4",
            borderColor: "#9BDFC4",
            borderRadius: 100,
            barThickness: 5,
          },
          {
            label: "Rejected Dietitians",
            data: rejectedCounts,
            backgroundColor: "#FAAFBC",
            borderColor: "#FAAFBC",
            borderRadius: 100,
            barThickness: 5,
          },
        ],
      });
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchFoodEventData = async () => {
    try {
      const response = await GetFoodEventData();

      const approvedCounts = new Array(7).fill(0);
      const rejectedCounts = new Array(7).fill(0);

      response.data.Result.forEach((report: any) => {
        const dayIndex = report._id - 1;
        approvedCounts[dayIndex] = report.approvedCount || 0;
        rejectedCounts[dayIndex] = report.rejectedCount || 0;
      });

      setFoodEventBarData({
        labels: daysOfWeekMap,
        datasets: [
          {
            label: "Approved Food Events",
            data: approvedCounts,
            backgroundColor: "#9BDFC4",
            borderColor: "#9BDFC4",
            borderRadius: 100,
            barThickness: 5,
          },
          {
            label: "Rejected Food Events",
            data: rejectedCounts,
            backgroundColor: "#FAAFBC",
            borderColor: "#FAAFBC",
            borderRadius: 100,
            barThickness: 5,
          },
        ],
      });
    } catch (error) {
      handleApiError(error);
    }
  };


  const handleApiError = (error: any) => {
    if (error.code === "ERR_BAD_REQUEST") {
      logout();
      navigate("/");
    } else {
      console.error("API Error:", error);
    }
  };


  const restoptions = [
    { name: 'Total' },
    { name: 'Pending' },
    { name: 'Approved' },
    { name: 'Rejected' }
  ];
  const restaurantcount = async () => {
    if (isTokenExpired()) {
      await getRefreshedToken();
    } else {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Accept: "*/*",
        Authorization: "Bearer " + token,
      };
      try {
        const countData = await axios.get(
          apiUrl + "/agentdashboard/restaurantcount",
          { headers }
        );

        console.log("agent rest check", countData);
        setapproverest(countData.data.data.Approved);
        setpendingrest(countData.data.data.Pending);
        setrejectrest(countData.data.data.Denied);
        settotalrest(countData.data.data.Total);
        setNewApproved(countData.data.data.NewApproved);
        setNewDenied(countData.data.data.NewDenied);
        setNewPending(countData.data.data.NewPending);
        setCurrentDayTotal(countData.data.data.CurrentDayTotal);
      } catch (err: any) {
        // console.log("error agenttimedata", err);
        if (err.code === "ERR_BAD_REQUEST") {
          logout();
          navigate("/");
        }
      }
    }
  };

  const getCountValue = () => {
    switch (reststate.name) {
      case 'Approved':
        return approverest;
      case 'Pending':
        return pendingrest;
      case 'Rejected':
        return rejectrest;
      default:
        return totalrest;
    }
  };

  const StoreEventDataCount = async () => {
    try {
      const storeeventscount = await StoreAndEventCount();
      setapprovestores(storeeventscount.data.data.Approved);
      setpendingstores(storeeventscount.data.data.Pending);
      setrejectstores(storeeventscount.data.data.Denied);
      settotalstores(storeeventscount.data.data.Total);
      setapprovefoodevents(storeeventscount.data.data.ApprovedEvents);
      setpendingfoodevents(storeeventscount.data.data.PendingEvents);
      setrejectfoodevents(storeeventscount.data.data.DeniedEvents);
      settotalfoodevents(storeeventscount.data.data.TotalEvents);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const getStoreCountValue = () => {
    switch (storestate.name) {
      case 'Approved':
        return approvestores;
      case 'Pending':
        return pendingstores;
      case 'Rejected':
        return rejectstores;
      default:
        return totalstores;
    }
  };
  const getFoodeventCountValue = () => {
    switch (foodeventstate.name) {
      case 'Approved':
        return approvefoodevents;
      case 'Pending':
        return pendingfoodevents;
      case 'Rejected':
        return rejectfoodevents;
      default:
        return totalfoodevents;
    }
  };

  const DietitianDataCount = async () => {
    try {
      const dietitiancount = await DietitianCount();
      setTotaldietitians(dietitiancount.data.data.Total);
      setApprovedietitian(dietitiancount.data.data.Approved);
      setPendingdietitian(dietitiancount.data.data.Pending);
      setRejectdietitian(dietitiancount.data.data.Denied);

    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  }
  const getDietitianCountValue = () => {
    switch (dietitianstate.name) {
      case 'Approved':
        return approvedietitian;
      case 'Pending':
        return pendingdietitian;
      case 'Rejected':
        return rejectdietitian;
      default:
        return totaldietitians;
    }
  };

  const CitywiseCount = async () => {
    try {
      const citiesdatacount = await GetCityDataCount();
      console.log("cities table", citiesdatacount);
      setCityData(citiesdatacount.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  }

  const CuisineCount = async () => {
    try {
      const CuisineCount = await GetCuisineCount();
      const Cusinedata = {
        labels: CuisineCount.data.data.map((report: any) => report._id),

        // labels:['a','b','c'],
        datasets: [
          {
            // data: [20,30,40],
            data: CuisineCount.data.data.map(
              (report: any) => report.count
            ),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4",
              "#02C39A",
              "#D7B6C8",
              "#B3C491",
              "#EDDADA",
              "#9599DF",
              "#FBEBFF",
              "#D1D5EB",
              "#9ACFC4",
              "#9ACFC4"


            ],

            borderWidth: 10,
          },
        ],
      };

      const options = {
        cutout: "50%",
        plugins: {
          legend: {
            display: false, // Hide the legend
            position: "right",
          },
        },
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "250px";
          chart.canvas.parentNode.style.height = "200px";
        },
      }
      setcuisineoptions(options);
      setCusineChartData(Cusinedata);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const CategoryCount = async () => {
    try {
      const CategoryCount = await GetCategoryCount();
      const data = {
        labels: CategoryCount.data.data.map((report: any) => report._id),
        datasets: [
          {
            data: CategoryCount.data.data.map(
              (report: any) => report.count
            ),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4"
            ],
            borderWidth: 10,
          },
        ],
      };
      const options = {
        cutout: "50%",
        plugins: {
          legend: {
            display: false, // Hide the legend
            position: "right",
          },
        },
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "250px";
          chart.canvas.parentNode.style.height = "200px";
        },
      }
      setcatoptions(options);
      setCategoryChartData(data);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const RecentTableData = async () => {
    try {
      const RecentTableobj = await GetRecentTableData(lazyParams);
      setRecentData(RecentTableobj.data.data);
      setTotalRecentRecords(RecentTableobj.data.data.totalRecords);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const TopArea = async () => {
    try {
      const Areasobj = await GetTopArea();
      const transformedData = Areasobj.data.data.map((item: any) => ({
        ...item,
        coordinates: [item.latitude, item.longitude],
        latitude: undefined,
        longitude: undefined,
      }));
      setLocationsData(transformedData);
      setIsadding(false);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const TopPerformingData = async () => {
    try {
      const TopPerforming = await TopPerformingRest();
      setTopPerforming(TopPerforming?.data?.data?.Result);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const lineoptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
    onResize: (chart: any, size: any) => {
      chart.canvas.parentNode.style.width = "100%";
      chart.canvas.parentNode.style.height = "200px";
    },
  };
  const baroptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,

        },
        grid: {
          display: false
        },
      },
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        suggestedMax: 10,
        ticks: {
          color: textColorSecondary,
          stepSize: 2,
          callback: function (value: any) {
            return Number.isInteger(value) ? value : null;
          }
        },
        grid: {
          color: surfaceBorder,
          // borderDash: [5, 5],
        },
      },
    },
    onResize: (chart: any, size: any) => {
      chart.canvas.parentNode.style.width = "100%";
      chart.canvas.parentNode.style.height = "215px";
    },
  };



  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };
  const topareasdata = async () => {
    try {
      const Topareas = await TopAresCovered();
      setTopareasData(Topareas.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };


  useEffect(() => {
    let timer: NodeJS.Timeout;

    const delayedFetch = () => {
      setIsadding(true);
      // Add a delay of 500ms before making the API call
      timer = setTimeout(() => {
        agenttimedata();
        fetchRestaurantData();
        fetchOrganicStoreData();
        fetchDietitianData();
        fetchFoodEventData();
        restaurantcount();
        CuisineCount();
        CategoryCount();
        RecentTableData();
        topareasdata();
        TopArea();
        TopPerformingData();
        StoreEventDataCount();
        DietitianDataCount();
        CitywiseCount();
        setIsadding(true);
      }, 500);
    };
    delayedFetch();
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <main>
        {!isAdding ? "" : <Loader />}

        <div>
          <Navbar />
          <section
            className="page-content"
            style={{ backgroundColor: "#f8f8f8" }}
          >
            <div className="px-4 pb-4">

              <div className="row">
                <div className="Dashboard-head" >Dashboard</div>
              </div>
              <hr style={{ color: "#ececec", height: "1px", opacity: "100%" }} />
              <div className="d-flex flex-column gap-5">
                <div className="overview-details-agent d-flex justify-content-between">
                  <div className="overall-counts-div col-lg-2">
                    <div className="img-dropdown d-flex justify-content-between">
                      <div className="pic-div" style={{ backgroundColor: "#F2FCFF" }}>
                        <img className="picsize-incard" src={restpic} alt="Restaurant" />
                      </div>
                      <div className="flex justify-content-center align-items-center">
                        <Dropdown
                          value={reststate}
                          onChange={(e) => {
                            console.log("Dropdown selected value:", e.value);
                            setReststate(e.value);
                          }} options={restoptions}
                          optionLabel="name"
                          className="dropdownstyle"
                        />
                      </div>
                    </div>
                    <div className="card-title-style">Restaurants</div>
                    <div className="count-selected-status">
                      {getCountValue()}
                    </div>
                  </div>
                  <div className="overall-counts-div col-lg-2">
                    <div className="img-dropdown d-flex justify-content-between">
                      <div className="pic-div" style={{ backgroundColor: "#FFF6EF" }}>
                        <img className="picsize-incard" src={orgpic} />
                      </div>
                      <div className="flex justify-content-center align-items-center">
                        <Dropdown
                          value={storestate}
                          onChange={(e) => {
                            console.log("Dropdown selected value:", e.value);
                            setStorestate(e.value);
                          }} options={restoptions}
                          optionLabel="name"
                          className="dropdownstyle"
                        />
                      </div>
                    </div>
                    <div className="card-title-style">
                      Organic Stores
                    </div>
                    <div className="count-selected-status">
                      {getStoreCountValue()}
                    </div>
                  </div>
                  <div className="overall-counts-div col-lg-2">
                    <div className="img-dropdown d-flex justify-content-between">
                      <div className="pic-div" style={{ backgroundColor: "#E8F6E6" }}>
                        <img className="picsize-incard" src={dietpic} />
                      </div>
                      <div className="flex justify-content-center align-items-center">
                        <Dropdown
                          value={dietitianstate}
                          onChange={(e) => {
                            console.log("Dropdown selected value:", e.value);
                            setDietitianstate(e.value);
                          }} options={restoptions}
                          optionLabel="name"
                          className="dropdownstyle"
                        />
                      </div>
                    </div>
                    <div className="card-title-style">
                      Dietitians
                    </div>
                    <div className="count-selected-status">
                      {getDietitianCountValue()}
                    </div>
                  </div>
                  <div className="overall-counts-div col-lg-2">
                    <div className="img-dropdown d-flex justify-content-between">
                      <div className="pic-div" style={{ backgroundColor: "#F4F6FA" }}>
                        <img className="picsize-incard" src={calenderpic} />
                      </div>
                      <div className="flex justify-content-center align-items-center">
                        <Dropdown
                          value={foodeventstate}
                          onChange={(e) => {
                            console.log("Dropdown selected value:", e.value);
                            setFoodeventstate(e.value);
                          }} options={restoptions}
                          optionLabel="name"
                          className="dropdownstyle"
                        />
                      </div>
                    </div>
                    <div className="card-title-style">
                      Food Events
                    </div>
                    <div className="count-selected-status">
                      {getFoodeventCountValue()}
                    </div>
                  </div>
                  <div className="overall-counts-div col-lg-2">
                    <div className="img-dropdown d-flex justify-content-between">
                      <div className="pic-div" style={{ backgroundColor: "#F9F4FF" }}>
                        <img className="picsize-incard" src={personpic} />
                      </div>
                      <div className="flex justify-content-center align-items-center">
                        <Dropdown
                          value={usersuggeststate}
                          onChange={(e) => {
                            console.log("Dropdown selected value:", e.value);
                            setUsersuggeststate(e.value);
                          }} options={restoptions}
                          optionLabel="name"
                          className="dropdownstyle"
                        />
                      </div>
                    </div>
                    <div className="card-title-style">
                      User Suggested
                    </div>
                    <div className="count-selected-status">
                      16
                    </div>
                  </div>
                </div>

                <div>
                  <div className="Dashboard-head mb-3">Last 7 days status</div>
                  <div className="d-flex gap-4">
                    <div style={{ width: "50%" }}>
                    <div className="Dashboard-head-ind mb-2">Restaurants</div>
                      <div className="card ym-chart-card mt-1">
                        <div className="flex justify-content-between align-items-center mb-2 bar-charts">
                          <Barchart data={restaurantBarData} options={baroptions} />
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <i className="bi bi-square-fill mr-2" style={{ color: "#9BDFC4" }}></i>
                            <span className="text-chart-opt mr-3">Approved Restaurants</span>

                          </div>
                          <div className="d-flex  align-items-center">
                            <i className="bi bi-square-fill mr-2" style={{ color: "#FAAFBC" }}></i>
                            <span className="text-chart-opt mr-3">Rejected Restaurants</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "50%" }}>
                    <div className="Dashboard-head-ind mb-2">Organic Stores</div>
                      <div className="card ym-chart-card mt-1">
                        <div className="flex justify-content-between align-items-center mb-2 bar-charts">
                          <Barchart data={organicStoreBarData} options={baroptions} />
                        </div>
                        <div className="d-flex justify-content-center  gap-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <i className="bi bi-square-fill mr-2" style={{ color: "#9BDFC4" }}></i>
                            <span className="text-chart-opt mr-3">Approved Organic Stores</span>

                          </div>
                          <div className="d-flex  align-items-center">
                            <i className="bi bi-square-fill mr-2" style={{ color: "#FAAFBC" }}></i>
                            <span className="text-chart-opt mr-3">Rejected Organic Stores</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-4">
                  <div style={{ width: "50%" }}>
                  <div className="Dashboard-head-ind mb-2">Dietitians</div>
                    <div className="card ym-chart-card mt-1">
                      <div className="flex justify-content-between align-items-center mb-2 bar-charts">
                        <Barchart data={dietitianBarData} options={baroptions} />
                      </div>
                      <div className="d-flex justify-content-center  gap-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <i className="bi bi-square-fill mr-2" style={{ color: "#9BDFC4" }}></i>
                          <span className="text-chart-opt mr-3">Approved Dietitians</span>

                        </div>
                        <div className="d-flex  align-items-center">
                          <i className="bi bi-square-fill mr-2" style={{ color: "#FAAFBC" }}></i>
                          <span className="text-chart-opt mr-3">Rejected Dietitians</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                  <div className="Dashboard-head-ind mb-2">Food Events</div>
                    <div className="card ym-chart-card mt-1">
                      <div className="flex justify-content-between align-items-center mb-2 bar-charts">
                        <Barchart data={foodEventBarData} options={baroptions} />
                      </div>
                      <div className="d-flex justify-content-center  gap-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <i className="bi bi-square-fill mr-2" style={{ color: "#9BDFC4" }}></i>
                          <span className="text-chart-opt mr-3">Approved Food Events</span>

                        </div>
                        <div className="d-flex  align-items-center">
                          <i className="bi bi-square-fill mr-2" style={{ color: "#FAAFBC" }}></i>
                          <span className="text-chart-opt mr-3">Rejected Food Events</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="totalcount-forcities">
                  <h3 className="Dashboard-head mb-2">Total Count for Cities</h3>
                  <table className="city-data-table">
                    <thead>
                      <tr>
                        <th>City</th>
                        <th>Restaurants</th>
                        <th>Organic Stores</th>
                        <th>Dietitians</th>
                        <th>Food Events</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cityData.map((city: any, index: any) => (
                        <tr key={index}>
                          <td>{city.City}</td>
                          <td>{city.Restaurants}</td>
                          <td>{city.OrganicStores}</td>
                          <td>{city.Dietitians}</td>
                          <td>{city.FoodEvents}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Dashboard;


